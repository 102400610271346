.App { /* Base font style */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 14.1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.8500);
  background-color: #f6f6f6;
}

.App .baseFont * { /* Base font style override for UI framework components */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 14.1px;
}

.App .appBg {
  background-color: #f6f6f6;
}

.App .cardBg {
  background-color: white;
}

.App .baseTextBg { /* Base text color as background */
  background-color: rgba(0, 0, 0, 0.8500);
}

.App .secondaryTextColor {
  color: rgba(0, 0, 0, 0.5000);
}

.App .secondaryTextBg {
  background-color: rgba(0, 0, 0, 0.5000);
}

.App .primaryTextColor {
  color: #00e1cf;
}

.App .primaryBg {
  background-color: #00e1cf;
}

.App .highlightTextColor {
  color: #00e5d9;
}

.App .highlightBg {
  background-color: #00e5d9;
}

@media (display-mode: standalone) {
  .App {
    user-select: none;
  }
}

/* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
.mui-btn {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.mui-btn--primary {
    background-color: #00e1cf;
}
.mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
    background-color: #00e2d4;
}
.mui-btn--accent {
    background-color: #00e5d9;
}
.mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
    background-color: #00e5dc;
}
.mui-textfield {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-checkbox, .mui-radio {  /* Reset UI framework styling that affects layout */
    margin-top: 0;
    margin-bottom: 0;
}
.mui-select {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-btn+.mui-btn {  /* Reset UI framework styling that affects layout */
    margin-left: 0;
}
.mui-btn--fab {  /* Reset UI framework styling that breaks compositing */
    z-index: auto;
}

@keyframes fadeIn {
    0%   { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@keyframes slideIn_right {
    0%   { transform: translateX(100%); }
    100% { transform: translateX(0); }
}
@keyframes slideIn_bottom {
    0%   { transform: translateY(100%); }
    100% { transform: translateY(0); }
}

/* System font classes */

.App .systemFontRegular {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}
.App .systemFontBold {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
}
.App .systemFontItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-style: italic;
}
.App .systemFontBoldItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* App-wide text styles specified in React Studio */

.App .actionFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 14.2px;
  font-weight: 400;
}
.App .actionFont * {
  font-size: 14.2px;
}
.App .headlineFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 21.3px;
  font-weight: 600;
}
.App .headlineFont * {
  font-size: 21.3px;
}
.App .navBar {
  background-color: #00e1cf;
  color: #fcffff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  min-height: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 15px;
}

.App .navBar a {
  color: #fcffff;
}

.App .navBar .title {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 4px;
}

.App .navBar .backBtn {
  position: absolute;
  padding: 4px;
  margin-left: 14px;
  cursor: pointer;
}

.App .navBar > div {
  margin-top: 7px;
}

@media (min-width: 568px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 768px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 1024px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 320px) {
  .mui-container {
    max-width: 320px;
  }
}
@media (min-width: 568px) {
  .mui-container {
    max-width: 568px;
  }
}
@media (min-width: 768px) {
  .mui-container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .mui-container {
    max-width: 1024px;
  }
}

.AppScreen {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.App .background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App .layoutFlow {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.App .layoutFlow:before {  /* Prevent margin collapse */
  content: "";
  display: table;
  clear: both;
}
.App .layoutFlow .flowRow {
  position: relative;
  width: 100%;
}

.App .screenFgContainer {
  /* Items in the foreground group within a screen are placed with fixed positioning */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.App .foreground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App ul {
  margin: 0;
  padding: 0;
}
.App li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.App .hasNestedComps {
  position: relative;
}
.App .hasNestedComps:after {
  content: "";
  display: table;
  clear: both;
}
.App .hasNestedComps .background {
  position: absolute;
}
.App .hasNestedComps .layoutFlow {
  position: relative;
}
.App .bottomAlignedContent {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App .blockInputOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  z-index: 99999999;
}

.App .marker {
  width: 0px;
  height: 0px;
}

.App .drawer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.App .drawerOpen {
  transform: translateX(0%);
  transition: 0.3s;
}

.App .drawerClosedLeft {
  transform: translateX(-100%);
  transition: 0.3s;
}

.App .drawerClosedRight {
  transform: translateX(100%);
  transition: 0.3s;
}

.App .drawerContent {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

.App .drawerContent > * {
  height: 100%;
}

/* This component has a fixed-size layout */
  .Ot_tournee_j1 {
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }


  .Ot_tournee_j1 > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Ot_tournee_j1 > .background > .elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 1px;
  }


  .Ot_tournee_j1 > .layoutFlow > .elSite {
    overflow: hidden;
    position: relative;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 10px;
    width: calc(100.0% - 15px);
  }
  .Ot_tournee_j1 > .layoutFlow > .elSite > * { 
    width: 100%;
  }
  


  .Ot_tournee_j1 > .layoutFlow > .flowRow > .elAction {
    overflow: hidden;
    position: absolute;
    margin-top: 5px;
    width: 100%;
  }
  .Ot_tournee_j1 > .layoutFlow > .flowRow > .elAction > * { 
  margin-left: auto;
  width: 120px;
  margin-right: 5px;
  }
  
  .flowRow_Ot_tournee_j1_elAction_420447302 { 
  }
  


  .Ot_tournee_j1 > .layoutFlow > .elFlux {
    overflow: hidden;
    position: relative;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 125px;
    width: calc(100.0% - 130px);
  }
  .Ot_tournee_j1 > .layoutFlow > .elFlux > * { 
    width: 100%;
  }
  


  .Ot_tournee_j1 > .layoutFlow > .elType {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    margin-left: 5px;
    margin-right: 125px;
    width: calc(100.0% - 130px);
  }
  .Ot_tournee_j1 > .layoutFlow > .elType > * { 
    width: 100%;
  }
  


  .Ot_tournee_j1 > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Ot_tournee_j1 > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 5px;
  }
  


/* Narrowest size for this element's responsive layout */
  .AppareilPhotoScreen > .background > .elRectangle {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 33px;
    height: 52px;
  }
@media (min-width: 568px) {
  .AppareilPhotoScreen > .background > .elRectangle {
    left: 0px;
    top: 0px;
    width: 33px;
    height: 52px;
  }
}
@media (min-width: 768px) {
  .AppareilPhotoScreen > .background > .elRectangle {
    left: 0px;
    top: 0px;
    width: 33px;
    height: 52px;
  }
}
@media (min-width: 1024px) {
  .AppareilPhotoScreen > .background > .elRectangle {
    left: 0px;
    top: 0px;
    width: 33px;
    height: 52px;
  }
}


/* Narrowest size for this element's responsive layout */
  .AppareilPhotoScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .AppareilPhotoScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .AppareilPhotoScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .AppareilPhotoScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elCamera {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
@media (min-width: 568px) {
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elCamera {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
}
@media (min-width: 768px) {
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elCamera {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}
@media (min-width: 1024px) {
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elCamera {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}

.AppareilPhotoScreen .elCapture {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.AppareilPhotoScreen .elCapture:focus {
  box-shadow: 0 0 6px rgba(0, 226, 217, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elCapture {
    position: fixed;
    left: calc(50.0% + 0px - 50px);
    bottom: 6px;
    width: 100px;
    height: 36px;
  }
@media (min-width: 568px) {
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elCapture {
    left: calc(50.0% + 0px - 50px);
    bottom: 6px;
    width: 100px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elCapture {
    left: calc(50.0% + 0px - 50px);
    bottom: 6px;
    width: 99px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elCapture {
    left: calc(50.0% + 0px - 50px);
    bottom: 6px;
    width: 99px;
    height: 36px;
  }
}

.AppareilPhotoScreen .elIcon_back {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.AppareilPhotoScreen .elIcon_back:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elIcon_back {
    position: fixed;
    left: 5px;
    bottom: 5px;
    width: 41px;
    height: 40px;
  }
@media (min-width: 568px) {
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elIcon_back {
    left: 5px;
    bottom: 5px;
    width: 41px;
    height: 40px;
  }
}
@media (min-width: 768px) {
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elIcon_back {
    left: 5px;
    bottom: 5px;
    width: 41px;
    height: 40px;
  }
}
@media (min-width: 1024px) {
  .AppareilPhotoScreen > .screenFgContainer > .foreground > .elIcon_back {
    left: 5px;
    bottom: 5px;
    width: 41px;
    height: 40px;
  }
}

/* This component has a fixed-size layout */
  .Check_command {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Check_command > .background > .containerMinHeight {
    min-height: 30px;
  }

/* This component has a fixed-size layout */
  .Check_command {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Check_command > .background > .containerMinHeight {
    min-height: 30px;
  }

.Check_command .state1_elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Check_command .state1_elIconButton:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .Check_command > .foreground > .state1_elIconButton {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Check_command {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Check_command > .background > .containerMinHeight {
    min-height: 30px;
  }

.Check_command .state2_elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Check_command .state2_elIconButton:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .Check_command > .foreground > .state2_elIconButton {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Check_command {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Check_command > .background > .containerMinHeight {
    min-height: 30px;
  }

.Check_command .state3_elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Check_command .state3_elIconButton:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



  .Check_command > .foreground > .state3_elIconButton {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Check_command {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Check_command > .background > .containerMinHeight {
    min-height: 30px;
  }

.Check_command .state4_elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Check_command .state4_elIconButton:focus {
  box-shadow: 0 0 5px rgba(6, 71, 255, 0.5000);
  outline: none;
}



  .Check_command > .foreground > .state4_elIconButton {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Check_command {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Check_command > .background > .containerMinHeight {
    min-height: 30px;
  }

.Check_command .state5_elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Check_command .state5_elIconButton:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1000);
  outline: none;
}



  .Check_command > .foreground > .state5_elIconButton {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Client_diff {
    min-height: 20px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 70px */
    height: 100%;
  }
  .Client_diff > .background > .containerMinHeight {
    min-height: 20px;
  }

/* This component has a fixed-size layout */
  .Client_diff {
    min-height: 20px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 70px */
    height: 100%;
  }
  .Client_diff > .background > .containerMinHeight {
    min-height: 20px;
  }


  .Client_diff > .background > .state1_elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Client_diff > .layoutFlow > .state1_elText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Client_diff > .layoutFlow > .state1_elText > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Client_diff {
    min-height: 20px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 70px */
    height: 100%;
  }
  .Client_diff > .background > .containerMinHeight {
    min-height: 20px;
  }


  .Client_diff > .background > .state2_elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Client_diff > .layoutFlow > .state2_elText {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Client_diff > .layoutFlow > .state2_elText > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .Packmat {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Packmat > .background > .containerMinHeight {
    min-height: 30px;
  }

/* This component has a fixed-size layout */
  .Packmat {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Packmat > .background > .containerMinHeight {
    min-height: 30px;
  }

.Packmat .state1_elIconButtonCopy {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Packmat .state1_elIconButtonCopy:focus {
  box-shadow: 0 0 5px rgba(6, 71, 255, 0.5000);
  outline: none;
}



  .Packmat > .foreground > .state1_elIconButtonCopy {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Packmat {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Packmat > .background > .containerMinHeight {
    min-height: 30px;
  }

.Packmat .state2_elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Packmat .state2_elIconButton:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .Packmat > .foreground > .state2_elIconButton {
    position: absolute;
    right: -2px;
    top: 7px;
    width: 17px;
    height: 17px;
  }

.Packmat .state2_elIconButtonCopy {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Packmat .state2_elIconButtonCopy:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .Packmat > .foreground > .state2_elIconButtonCopy {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Packmat {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Packmat > .background > .containerMinHeight {
    min-height: 30px;
  }

.Packmat .state3_elIconButton819729806 {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Packmat .state3_elIconButton819729806:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1000);
  outline: none;
}



  .Packmat > .foreground > .state3_elIconButton819729806 {
    position: absolute;
    left: 13px;
    right: 0px;
    top: 13px;
    width: calc(100.0% - 13px - 0px);
    height: calc(100% + 0.0% + -13.0px);
  }

.Packmat .state3_elIconButtonCopy {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Packmat .state3_elIconButtonCopy:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .Packmat > .foreground > .state3_elIconButtonCopy {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Packmat {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Packmat > .background > .containerMinHeight {
    min-height: 30px;
  }

.Packmat .state4_elIconButton819729806 {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Packmat .state4_elIconButton819729806:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1000);
  outline: none;
}



  .Packmat > .foreground > .state4_elIconButton819729806 {
    position: absolute;
    left: 13px;
    right: 0px;
    top: 13px;
    width: calc(100.0% - 13px - 0px);
    height: calc(100% + 0.0% + -13.0px);
  }

.Packmat .state4_elIconButtonCopy {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Packmat .state4_elIconButtonCopy:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .Packmat > .foreground > .state4_elIconButtonCopy {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Packmat {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Packmat > .background > .containerMinHeight {
    min-height: 30px;
  }

.Packmat .state5_elIconButton819729806 {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Packmat .state5_elIconButton819729806:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1000);
  outline: none;
}



  .Packmat > .foreground > .state5_elIconButton819729806 {
    position: absolute;
    left: 13px;
    right: 0px;
    top: 13px;
    width: calc(100.0% - 13px - 0px);
    height: calc(100% + 0.0% + -13.0px);
  }

.Packmat .state5_elIconButtonCopy {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Packmat .state5_elIconButtonCopy:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



  .Packmat > .foreground > .state5_elIconButtonCopy {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Packmat {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Packmat > .background > .containerMinHeight {
    min-height: 30px;
  }


  .Packmat > .foreground > .state6_elImage {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Picker_specifiques {
    min-height: 159px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 159px */
    height: 100%;
  }
  .Picker_specifiques > .background > .containerMinHeight {
    min-height: 159px;
  }


  .Picker_specifiques > .layoutFlow > .flowRow > .elN200 {
    position: absolute;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN200 > * { 
    width: 100%;
  height: 0px;
  }
  
  .flowRow_Picker_specifiques_elN200_1862196716 { 
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN201 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN201 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN202 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN202 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN203 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN203 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN204 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN204 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN205 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN205 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN206 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN206 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN207 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN207 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN208 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN208 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN209 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN209 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN210 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN210 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN211 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN211 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN212 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN212 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN213 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN213 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN214 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN214 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN300 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN300 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN301 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN301 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN302 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN302 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN303 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN303 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN304 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN304 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN305 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN305 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN306 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN306 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN307 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN307 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN308 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN308 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN309 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN309 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN310 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN310 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN311 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN311 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN312 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN312 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN313 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN313 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN314 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN314 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN315 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN315 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN316 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN316 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN317 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN317 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN318 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN318 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN319 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN319 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN320 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN320 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN321 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN321 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN322 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN322 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN323 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN323 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN324 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN324 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN325 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN325 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN326 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN326 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN327 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN327 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN328 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN328 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN329 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN329 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN330 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN330 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN331 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN331 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN332 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN332 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN333 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN333 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN334 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN334 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN335 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN335 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN336 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN336 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN337 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN337 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN400 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN400 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN401 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN401 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN402 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN402 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .flowRow > .elN403 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .flowRow > .elN403 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_specifiques > .layoutFlow > .elImage {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_specifiques > .layoutFlow > .elImage > * { 
    width: 100%;
  height: 91px;
  }
  


  .Picker_specifiques > .foreground > .elFlux {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 130px;
    width: 100.0%;
    height: 16px;
  }


  .Picker_specifiques > .foreground > .elState {
    position: absolute;
    left: calc(50.0% + 0px - 40px);
    top: 95px;
    width: 79px;
    height: 28px;
  }

.Picker_specifiques .elButtonCopy {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Picker_specifiques .elButtonCopy:focus {
  box-shadow: 0 0 6px rgba(0, 226, 217, 0.2500);
  outline: none;
}



  .Picker_specifiques > .foreground > .elButtonCopy {
    position: absolute;
    left: 0px;
    top: 95px;
    width: 34px;
    height: 34px;
  }

.Picker_specifiques .elButton {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Picker_specifiques .elButton:focus {
  box-shadow: 0 0 6px rgba(0, 226, 217, 0.2500);
  outline: none;
}



  .Picker_specifiques > .foreground > .elButton {
    position: absolute;
    right: 0px;
    top: 95px;
    width: 34px;
    height: 34px;
  }

/* This component has a fixed-size layout */
  .Image_fill {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Image_fill > .background > .containerMinHeight {
    min-height: 30px;
  }


  .Image_fill > .foreground > .elImage_back {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Image_fill > .foreground > .elFill {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Image_fill > .foreground > .elImage_front {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Shedding {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Shedding > .background > .containerMinHeight {
    min-height: 30px;
  }

/* This component has a fixed-size layout */
  .Shedding {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Shedding > .background > .containerMinHeight {
    min-height: 30px;
  }

.Shedding .state1_elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Shedding .state1_elIconButton:focus {
  box-shadow: 0 0 5px rgba(6, 71, 255, 0.5000);
  outline: none;
}



  .Shedding > .foreground > .state1_elIconButton {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Prevent_interraction {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Prevent_interraction > .background > .containerMinHeight {
    min-height: 30px;
  }

.Prevent_interraction .state0_elHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .Prevent_interraction > .foreground > .state0_elHotspot {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Prevent_interraction {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Prevent_interraction > .background > .containerMinHeight {
    min-height: 30px;
  }


  .Prevent_interraction > .foreground > .state1_elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Item_immatriculation_apercu {
    min-height: 40px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 182px */
    height: 100%;
  }
  .Item_immatriculation_apercu > .background > .containerMinHeight {
    min-height: 40px;
  }


  .Item_immatriculation_apercu > .layoutFlow > .flowRow > .elTextCopy {
    position: absolute;
    margin-top: 10px;
    width: 70px;
    margin-left: 0px;
  }
  .Item_immatriculation_apercu > .layoutFlow > .flowRow > .elTextCopy > * { 
  width: 70px;
  }
  
  .flowRow_Item_immatriculation_apercu_elTextCopy_1816293517 { 
  }
  


  .Item_immatriculation_apercu > .layoutFlow > .elSite {
    position: relative;
    margin-top: 10px;
    margin-left: 75px;
    margin-right: 0px;
    width: calc(100.0% - 75px);
  }
  .Item_immatriculation_apercu > .layoutFlow > .elSite > * { 
    width: 100%;
  }
  


  .Item_immatriculation_apercu > .layoutFlow > .elAnomalies {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Item_immatriculation_apercu > .layoutFlow > .elAnomalies > * { 
    width: 100%;
  }
  


  .Item_immatriculation_apercu > .layoutFlow > .elType {
    position: relative;
    margin-top: 5px;
    margin-left: 40.0%;
    margin-right: 0px;
    width: calc(60.0% - 0px);
  }
  .Item_immatriculation_apercu > .layoutFlow > .elType > * { 
    width: 100%;
  }
  


  .Item_immatriculation_apercu > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Item_immatriculation_apercu > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  

/* This component has a fixed-size layout */
  .Ot_order_add {
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }


  .Ot_order_add > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Ot_order_add > .background > .elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 1px;
  }


  .Ot_order_add > .layoutFlow > .elSite {
    overflow: hidden;
    position: relative;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    width: calc(100.0% - 10px);
  }
  .Ot_order_add > .layoutFlow > .elSite > * { 
    width: 100%;
  }
  


  .Ot_order_add > .layoutFlow > .flowRow > .elAction {
    overflow: hidden;
    position: absolute;
    margin-top: 5px;
    width: 100%;
  }
  .Ot_order_add > .layoutFlow > .flowRow > .elAction > * { 
  margin-left: auto;
  width: 120px;
  margin-right: 5px;
  }
  
  .flowRow_Ot_order_add_elAction_1296690769 { 
  }
  


  .Ot_order_add > .layoutFlow > .elFlux {
    overflow: hidden;
    position: relative;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 125px;
    width: calc(100.0% - 130px);
  }
  .Ot_order_add > .layoutFlow > .elFlux > * { 
    width: 100%;
  }
  


  .Ot_order_add > .layoutFlow > .elType {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    margin-left: 5px;
    margin-right: 125px;
    width: calc(100.0% - 130px);
  }
  .Ot_order_add > .layoutFlow > .elType > * { 
    width: 100%;
  }
  


  .Ot_order_add > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Ot_order_add > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 5px;
  }
  

.Ot_order_add .elHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .Ot_order_add > .foreground > .elHotspot {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Item_immatriculation_anomalie {
    min-height: 40px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 182px */
    height: 100%;
  }
  .Item_immatriculation_anomalie > .background > .containerMinHeight {
    min-height: 40px;
  }


  .Item_immatriculation_anomalie > .foreground > .elText {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Tail_picker_plein {
    min-height: 159px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 159px; /* This component has a fixed size */
  }
  .Tail_picker_plein > .background > .containerMinHeight {
    min-height: 159px;
  }

.Tail_picker_plein .elButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Tail_picker_plein .elButton:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



  .Tail_picker_plein > .foreground > .elButton {
    line-height: 35px;
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 5px;
    width: calc(100.0% - 5px - 5px);
    height: 35px;
  }


  .Tail_picker_plein > .foreground > .elPicker_vide {
    position: absolute;
    left: 5px;
    right: 5px;
    top: 80px;
    width: calc(100.0% - 5px - 5px);
    height: 30px;
  }


  .Tail_picker_plein > .foreground > .elPicker {
    position: absolute;
    left: 5px;
    right: 5px;
    top: 80px;
    width: calc(100.0% - 5px - 5px);
    height: 30px;
  }


  .Tail_picker_plein > .foreground > .elCheckbox_vide {
    position: absolute;
    left: 85px;
    top: 40px;
    width: 69px;
    height: 27px;
  }


  .Tail_picker_plein > .foreground > .elCheckbox_plein {
    position: absolute;
    left: 5px;
    top: 45px;
    width: 75px;
    height: 27px;
  }

.Tail_picker_plein .elClose {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Tail_picker_plein .elClose:focus {
  box-shadow: 0 0 5px rgba(255, 129, 120, 0.5000);
  outline: none;
}



  .Tail_picker_plein > .foreground > .elClose {
    position: absolute;
    left: 2px;
    top: 2px;
    width: 41px;
    height: 36px;
  }

.Tail_picker_plein .elAdd {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Tail_picker_plein .elAdd:focus {
  box-shadow: 0 0 5px rgba(213, 213, 213, 0.5000);
  outline: none;
}



  .Tail_picker_plein > .foreground > .elAdd {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

.Tail_picker_plein .elPrevent_interraction {
  overflow: hidden;
  pointer-events: auto;
}

.Tail_picker_plein .elPrevent_interraction.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Tail_picker_plein > .foreground > .elPrevent_interraction {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Done {
    min-height: 182px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 182px */
    height: 100%;
  }
  .Done > .background > .containerMinHeight {
    min-height: 182px;
  }


  .Done > .foreground > .elTs {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 120px;
    width: 100.0%;
    height: 33px;
  }

.Done .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Done .elIconButton:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



  .Done > .foreground > .elIconButton {
    position: absolute;
    left: calc(50.0% + 0px - 20px);
    top: 70px;
    width: 41px;
    height: 36px;
  }


  .Done > .foreground > .elTextCopy {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 30px;
    width: 100.0%;
    height: 43px;
  }

/* This component has a fixed-size layout */
  .Item_one_group_spe {
    width: 100%; /* This component was designed using a width of 330px */
    height: 100%;
  }


  .Item_one_group_spe > .background > .elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 1px;
  }


  .Item_one_group_spe > .layoutFlow > .elText {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Item_one_group_spe > .layoutFlow > .elText > * { 
    width: 100%;
  }
  

.Item_one_group_spe > .layoutFlow > .elList > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 5px;
  grid-column-gap: 5px;  /* old name still required by some browsers */
}

.Item_one_group_spe > .layoutFlow > .elList > div > * {
  display: inline-block;
  position: relative;
  min-width: 159px;
}

  @media (min-width: 1024px) {
    .Item_one_group_spe > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .Item_one_group_spe > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .Item_one_group_spe > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .Item_one_group_spe > .layoutFlow > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  


  .Item_one_group_spe > .layoutFlow > .elList {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Item_one_group_spe > .layoutFlow > .elList > * { 
    width: 100%;
  }
  


/* Narrowest size for this element's responsive layout */
  .List_contenantsScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .List_contenantsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .List_contenantsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .List_contenantsScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.List_contenantsScreen .elList_immatriculations {
  overflow: auto;
  pointer-events: auto;
}

.List_contenantsScreen .elList_immatriculations li {
  margin-bottom: 5px;
}



/* Narrowest size for this element's responsive layout */
  .List_contenantsScreen > .screenFgContainer > .foreground > .elList_immatriculations {
    position: absolute;
    left: calc(9.4% + 0px);
    right: calc(9.4% + 0px);
    top: 90px;
    width: 81.2%;
    height: calc(100% + 0.0% + -95.0px);
  }
@media (min-width: 568px) {
  .List_contenantsScreen > .screenFgContainer > .foreground > .elList_immatriculations {
    left: calc(27.1% + 0px);
    right: calc(27.1% + 0px);
    top: 90px;
    width: 45.8%;
    height: calc(100% + 0.0% + -95.0px);
  }
}
@media (min-width: 768px) {
  .List_contenantsScreen > .screenFgContainer > .foreground > .elList_immatriculations {
    left: calc(33.1% + 0px);
    right: calc(33.1% + 0px);
    top: 89px;
    width: 33.9%;
    height: calc(100% + 0.0% + -94.4px);
  }
}
@media (min-width: 1024px) {
  .List_contenantsScreen > .screenFgContainer > .foreground > .elList_immatriculations {
    left: calc(37.3% + 0px);
    right: calc(37.3% + 0px);
    top: 89px;
    width: 25.4%;
    height: calc(100% + 0.0% + -94.4px);
  }
}


/* Narrowest size for this element's responsive layout */
  .List_contenantsScreen > .screenFgContainer > .foreground > .elSearch {
    position: absolute;
    left: calc(9.4% + 0px);
    right: calc(9.4% + 0px);
    top: 50px;
    width: 81.2%;
    height: 34px;
  }
@media (min-width: 568px) {
  .List_contenantsScreen > .screenFgContainer > .foreground > .elSearch {
    left: calc(27.1% + 0px);
    right: calc(27.1% + 0px);
    top: 50px;
    width: 45.8%;
    height: 34px;
  }
}
@media (min-width: 768px) {
  .List_contenantsScreen > .screenFgContainer > .foreground > .elSearch {
    left: calc(33.1% + 0px);
    right: calc(33.1% + 0px);
    top: 50px;
    width: 33.9%;
    height: 34px;
  }
}
@media (min-width: 1024px) {
  .List_contenantsScreen > .screenFgContainer > .foreground > .elSearch {
    left: calc(37.3% + 0px);
    right: calc(37.3% + 0px);
    top: 50px;
    width: 25.4%;
    height: 34px;
  }
}

/* This component has a fixed-size layout */
  .Picker_niveau2 {
    min-height: 159px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 159px */
    height: 100%;
  }
  .Picker_niveau2 > .background > .containerMinHeight {
    min-height: 159px;
  }


  .Picker_niveau2 > .background > .elCard {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Picker_niveau2 > .layoutFlow > .flowRow > .elVide {
    position: absolute;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_niveau2 > .layoutFlow > .flowRow > .elVide > * { 
    width: 100%;
  height: 0px;
  }
  
  .flowRow_Picker_niveau2_elVide_1373600465 { 
  }
  


  .Picker_niveau2 > .layoutFlow > .flowRow > .elN0_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_niveau2 > .layoutFlow > .flowRow > .elN0_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_niveau2 > .layoutFlow > .flowRow > .elN0_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_niveau2 > .layoutFlow > .flowRow > .elN0_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_niveau2 > .layoutFlow > .flowRow > .elN0_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_niveau2 > .layoutFlow > .flowRow > .elN0_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_niveau2 > .foreground > .elFlux {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 134px;
    width: 100.0%;
    height: 20px;
  }


  .Picker_niveau2 > .foreground > .elPicker_flux {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 30px;
  }

.Picker_niveau2 .elImage_fill {
  overflow: hidden;
}

.Picker_niveau2 .elImage_fill.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Picker_niveau2 > .foreground > .elImage_fill {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 110px;
  }

.Picker_niveau2 .elCheck_command {
  overflow: hidden;
}

.Picker_niveau2 .elCheck_command.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Picker_niveau2 > .foreground > .elCheck_command {
    position: absolute;
    left: calc(50.0% + 40px);
    top: 105px;
    width: 30px;
    height: 30px;
  }


  .Picker_niveau2 > .foreground > .elState {
    position: absolute;
    left: calc(50.0% + 0px - 35px);
    top: 110px;
    width: 70px;
    height: 20px;
  }

.Picker_niveau2 .elPackmat_display {
  overflow: hidden;
}

.Picker_niveau2 .elPackmat_display.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Picker_niveau2 > .foreground > .elPackmat_display {
    position: absolute;
    right: calc(50.0% + 40px);
    top: 105px;
    width: 30px;
    height: 30px;
  }

.Picker_niveau2 .elPackmat_increment {
  overflow: hidden;
}

.Picker_niveau2 .elPackmat_increment.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Picker_niveau2 > .foreground > .elPackmat_increment {
    position: absolute;
    right: calc(50.0% + 40px);
    top: 105px;
    width: 30px;
    height: 30px;
  }

.Picker_niveau2 .elShedding {
  overflow: hidden;
}

.Picker_niveau2 .elShedding.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Picker_niveau2 > .foreground > .elShedding {
    position: absolute;
    right: calc(50.0% + 40px);
    top: 105px;
    width: 30px;
    height: 30px;
  }


  .Picker_niveau2 > .foreground > .elId_scan {
    position: absolute;
    left: calc(50.0% + 15px);
    right: 5px;
    top: 5px;
    width: calc(50.0% - 15px - 5px);
    height: 20px;
  }


  .Picker_niveau2 > .foreground > .elQuai {
    position: absolute;
    left: 5px;
    right: calc(50.0% + 5px);
    top: 5px;
    width: calc(50.0% - 5px - 5px);
    height: 30px;
  }

/* This component has a fixed-size layout */
  .Picker_plein2 {
    min-height: 159px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 159px */
    height: 100%;
  }
  .Picker_plein2 > .background > .containerMinHeight {
    min-height: 159px;
  }


  .Picker_plein2 > .background > .elCard {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Picker_plein2 > .layoutFlow > .flowRow > .elN0_0 {
    position: absolute;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_plein2 > .layoutFlow > .flowRow > .elN0_0 > * { 
    width: 100%;
  height: 0px;
  }
  
  .flowRow_Picker_plein2_elN0_0_1628670179 { 
  }
  


  .Picker_plein2 > .layoutFlow > .flowRow > .elN0_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_plein2 > .layoutFlow > .flowRow > .elN0_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_plein2 > .layoutFlow > .flowRow > .elN0_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_plein2 > .layoutFlow > .flowRow > .elN0_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_plein2 > .layoutFlow > .flowRow > .elN0_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_plein2 > .layoutFlow > .flowRow > .elN0_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_plein2 > .foreground > .elFlux {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 134px;
    width: 100.0%;
    height: 20px;
  }


  .Picker_plein2 > .foreground > .elState {
    position: absolute;
    left: calc(50.0% + 0px - 35px);
    top: 110px;
    width: 70px;
    height: 20px;
  }

.Picker_plein2 .elClient_diff {
  overflow: hidden;
}

.Picker_plein2 .elClient_diff.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Picker_plein2 > .foreground > .elClient_diff {
    position: absolute;
    left: calc(50.0% + 0px - 35px);
    top: 110px;
    width: 70px;
    height: 20px;
  }

.Picker_plein2 .elImage_fill {
  overflow: hidden;
}

.Picker_plein2 .elImage_fill.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Picker_plein2 > .foreground > .elImage_fill {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 110px;
  }


  .Picker_plein2 > .foreground > .elId_scan {
    position: absolute;
    left: calc(50.0% + 15px);
    right: 5px;
    top: 5px;
    width: calc(50.0% - 15px - 5px);
    height: 20px;
  }


  .Picker_plein2 > .foreground > .elQuai {
    position: absolute;
    left: 5px;
    right: calc(50.0% + 5px);
    top: 5px;
    width: calc(50.0% - 5px - 5px);
    height: 30px;
  }

.Picker_plein2 .elDelete {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Picker_plein2 .elDelete:focus {
  box-shadow: 0 0 5px rgba(255, 129, 120, 0.5000);
  outline: none;
}



  .Picker_plein2 > .foreground > .elDelete {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 40px;
    height: 40px;
  }

.Picker_plein2 .elCheck_command {
  overflow: hidden;
}

.Picker_plein2 .elCheck_command.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Picker_plein2 > .foreground > .elCheck_command {
    position: absolute;
    left: calc(50.0% + 40px);
    top: 105px;
    width: 30px;
    height: 30px;
  }

.Picker_plein2 .elPackmat_display {
  overflow: hidden;
}

.Picker_plein2 .elPackmat_display.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Picker_plein2 > .foreground > .elPackmat_display {
    position: absolute;
    right: calc(50.0% + 40px);
    top: 105px;
    width: 30px;
    height: 30px;
  }

.Picker_plein2 .elPackmat_increment {
  overflow: hidden;
}

.Picker_plein2 .elPackmat_increment.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Picker_plein2 > .foreground > .elPackmat_increment {
    position: absolute;
    right: calc(50.0% + 40px);
    top: 105px;
    width: 30px;
    height: 30px;
  }

.Picker_plein2 .elShedding {
  overflow: hidden;
}

.Picker_plein2 .elShedding.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Picker_plein2 > .foreground > .elShedding {
    position: absolute;
    right: calc(50.0% + 40px);
    top: 105px;
    width: 30px;
    height: 30px;
  }

/* This component has a fixed-size layout */
  .Other_operation {
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }


  .Other_operation > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: -50px;
  }
  .Other_operation > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  


  .Other_operation > .layoutFlow > .flowRow > .elBackground {
    position: absolute;
    margin-top: 0px;
    margin-left: calc(6.7% + -10px);
    margin-right: 5.0%;
    width: calc(88.3% - -10px);
  }
  .Other_operation > .layoutFlow > .flowRow > .elBackground > * { 
    width: 100%;
  height: 225px;
  }
  
  .flowRow_Other_operation_elBackground_1552510650 { 
  }
  


  .Other_operation > .layoutFlow > .flowRow > .elOperation_txt {
    overflow: hidden;
    position: absolute;
    top: 10px;
    margin-left: 6.7%;
    margin-right: 6.7%;
    width: calc(86.7% - 0px);
  }
  .Other_operation > .layoutFlow > .flowRow > .elOperation_txt > * { 
    width: 100%;
  }
  


  .Other_operation > .layoutFlow > .flowRow > .elOperation {
    position: absolute;
    top: 30px;
    margin-left: 6.7%;
    margin-right: 6.7%;
    width: calc(86.7% - 0px);
  }
  .Other_operation > .layoutFlow > .flowRow > .elOperation > * { 
    width: 100%;
  }
  


  .Other_operation > .layoutFlow > .flowRow > .elFlux_txt {
    overflow: hidden;
    position: absolute;
    top: 75px;
    margin-left: 6.7%;
    margin-right: 6.7%;
    width: calc(86.7% - 0px);
  }
  .Other_operation > .layoutFlow > .flowRow > .elFlux_txt > * { 
    width: 100%;
  }
  


  .Other_operation > .layoutFlow > .flowRow > .elFlux {
    position: absolute;
    top: 95px;
    margin-left: 6.7%;
    margin-right: 6.7%;
    width: calc(86.7% - 0px);
  }
  .Other_operation > .layoutFlow > .flowRow > .elFlux > * { 
    width: 100%;
  }
  


  .Other_operation > .layoutFlow > .flowRow > .elComment {
    position: absolute;
    top: 140px;
    margin-left: 6.7%;
    margin-right: 6.7%;
    width: calc(86.7% - 0px);
  }
  .Other_operation > .layoutFlow > .flowRow > .elComment > * { 
    width: 100%;
  }
  


  .Other_operation > .layoutFlow > .flowRow > .elVolume {
    position: absolute;
    top: 180px;
    margin-left: 6.7%;
    margin-right: 6.7%;
    width: calc(86.7% - 0px);
  }
  .Other_operation > .layoutFlow > .flowRow > .elVolume > * { 
    width: 100%;
  }
  


  .Other_operation > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Other_operation > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 225px;
  }
  


  .Other_operation > .layoutFlow > .flowRow > .elCard3 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(6.7% + -10px);
    margin-right: 5.0%;
    width: calc(88.3% - -10px);
  }
  .Other_operation > .layoutFlow > .flowRow > .elCard3 > * { 
    width: 100%;
  height: 60px;
  }
  
  .flowRow_Other_operation_elCard3_1055474926 { 
  }
  


  .Other_operation > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 20px;
    margin-left: 6.7%;
    margin-right: 6.7%;
    width: calc(86.7% - 0px);
  }
  .Other_operation > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 40px;
  }
  

.Other_operation .elCapture button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Other_operation .elCapture button:focus {
  box-shadow: 0 0 6px rgba(0, 226, 217, 0.2500);
  outline: none;
}



  .Other_operation > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 20px;
    width: 260px;
    margin-left: 6.7%;
  }
  .Other_operation > .layoutFlow > .flowRow > .elCapture > * { 
  width: 260px;
  height: 40px;
  }
  

.Other_operation .elErase button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Other_operation .elErase button:focus {
  box-shadow: 0 0 6px rgba(255, 129, 120, 0.2500);
  outline: none;
}



  .Other_operation > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .Other_operation > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 6.7%;
  height: 30px;
  }
  


  .Other_operation > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Other_operation > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 70px;
  }
  


  .Other_operation > .layoutFlow > .flowRow > .elCard4 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(6.7% + -10px);
    margin-right: 5.0%;
    width: calc(88.3% - -10px);
  }
  .Other_operation > .layoutFlow > .flowRow > .elCard4 > * { 
    width: 100%;
  height: 115px;
  }
  
  .flowRow_Other_operation_elCard4_2094572428 { 
  }
  


  .Other_operation > .layoutFlow > .flowRow > .elExutoire_txt {
    overflow: hidden;
    position: absolute;
    top: 20px;
    margin-left: 6.7%;
    margin-right: 6.7%;
    width: calc(86.7% - 0px);
  }
  .Other_operation > .layoutFlow > .flowRow > .elExutoire_txt > * { 
    width: 100%;
  }
  


  .Other_operation > .layoutFlow > .flowRow > .elExutoire {
    position: absolute;
    top: 40px;
    margin-left: 6.7%;
    margin-right: 6.7%;
    width: calc(86.7% - 0px);
  }
  .Other_operation > .layoutFlow > .flowRow > .elExutoire > * { 
    width: 100%;
  }
  


  .Other_operation > .layoutFlow > .flowRow > .elTonnage {
    position: absolute;
    top: 80px;
    margin-left: 6.7%;
    margin-right: 6.7%;
    width: calc(86.7% - 0px);
  }
  .Other_operation > .layoutFlow > .flowRow > .elTonnage > * { 
    width: 100%;
  }
  


  .Other_operation > .layoutFlow > .elSpacerCopy3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Other_operation > .layoutFlow > .elSpacerCopy3 > * { 
  width: 10px;
  height: 125px;
  }
  

.Other_operation .elValider button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Other_operation .elValider button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



  .Other_operation > .layoutFlow > .elValider {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .Other_operation > .layoutFlow > .elValider > * { 
  width: 145px;
  height: 35px;
  }
  


  .Other_operation > .foreground > .elBig_image {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Apercu {
    min-height: 300px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 250px */
    height: 100%;
  }
  .Apercu > .background > .containerMinHeight {
    min-height: 300px;
  }


  .Apercu > .background > .state0_elBackground1982019444 {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }

.Apercu .state0_elAllOrders {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Apercu .state0_elAllOrders:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



  .Apercu > .foreground > .state0_elAllOrders {
    line-height: 35px;
    position: absolute;
    left: calc(50.0% + 0px - 100px);
    top: 250px;
    width: 200px;
    height: 35px;
  }


  .Apercu > .foreground > .state0_elNotif {
    overflow: hidden;
    position: absolute;
    left: calc(50.0% + 85px);
    top: 245px;
    width: 18px;
    height: 21px;
  }

.Apercu .state0_elFind {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Apercu .state0_elFind:focus {
  box-shadow: 0 0 5px rgba(255, 211, 120, 0.5000);
  outline: none;
}



  .Apercu > .foreground > .state0_elFind {
    line-height: 35px;
    position: absolute;
    left: calc(50.0% + 0px - 100px);
    top: 190px;
    width: 200px;
    height: 35px;
  }

.Apercu .state0_elTomorrow {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Apercu .state0_elTomorrow:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



  .Apercu > .foreground > .state0_elTomorrow {
    line-height: 35px;
    position: absolute;
    left: calc(50.0% + 0px - 100px);
    top: 130px;
    width: 200px;
    height: 35px;
  }

.Apercu .state0_elDay {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Apercu .state0_elDay:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



  .Apercu > .foreground > .state0_elDay {
    line-height: 35px;
    position: absolute;
    left: calc(50.0% + 0px - 100px);
    top: 70px;
    width: 200px;
    height: 35px;
  }

.Apercu .state0_elFab2099238582 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Apercu .state0_elFab2099238582:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1250);
  outline: none;
}



  .Apercu > .foreground > .state0_elFab2099238582 {
    line-height: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
  }

/* This component has a fixed-size layout */
  .Apercu {
    min-height: 300px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 250px */
    height: 100%;
  }
  .Apercu > .background > .containerMinHeight {
    min-height: 300px;
  }


  .Apercu > .background > .state1_elBackground1982019444 {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }

.Apercu .state1_elList {
  overflow: auto;
  pointer-events: auto;
}



  .Apercu > .foreground > .state1_elList {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 60px;
    width: calc(100.0% - 20px - 20px);
    height: calc(100% + 0.0% + -80.0px);
  }


  .Apercu > .foreground > .state1_elText {
    overflow: hidden;
    position: absolute;
    left: 20px;
    right: 60px;
    top: 20px;
    width: calc(100.0% - 20px - 60px);
    height: 26px;
  }

.Apercu .state1_elFab2099238582 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Apercu .state1_elFab2099238582:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1250);
  outline: none;
}



  .Apercu > .foreground > .state1_elFab2099238582 {
    line-height: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
  }

/* This component has a fixed-size layout */
  .Apercu {
    min-height: 300px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 250px */
    height: 100%;
  }
  .Apercu > .background > .containerMinHeight {
    min-height: 300px;
  }


  .Apercu > .background > .state2_elBackground1982019444 {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }

.Apercu .state2_elList_tomorrow {
  overflow: auto;
  pointer-events: auto;
}



  .Apercu > .foreground > .state2_elList_tomorrow {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 60px;
    width: calc(100.0% - 20px - 20px);
    height: calc(100% + 0.0% + -80.0px);
  }


  .Apercu > .foreground > .state2_elText {
    overflow: hidden;
    position: absolute;
    left: 20px;
    right: 60px;
    top: 20px;
    width: calc(100.0% - 20px - 60px);
    height: 26px;
  }

.Apercu .state2_elFab2099238582 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Apercu .state2_elFab2099238582:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1250);
  outline: none;
}



  .Apercu > .foreground > .state2_elFab2099238582 {
    line-height: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
  }

/* This component has a fixed-size layout */
  .Apercu {
    min-height: 300px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 250px */
    height: 100%;
  }
  .Apercu > .background > .containerMinHeight {
    min-height: 300px;
  }


  .Apercu > .background > .state3_elBackground1982019444 {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }

.Apercu .state3_elList_contenants {
  overflow: auto;
  pointer-events: auto;
}



  .Apercu > .foreground > .state3_elList_contenants {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 165px;
    width: calc(100.0% - 20px - 20px);
    height: calc(100% + 0.0% + -185.0px);
  }


  .Apercu > .foreground > .state3_elRectangle {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 55px;
    width: calc(100.0% - 15px - 15px);
    height: 93px;
  }

.Apercu .state3_elReset {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Apercu .state3_elReset:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .Apercu > .foreground > .state3_elReset {
    position: absolute;
    right: 20px;
    top: 110px;
    width: 25px;
    height: 25px;
  }


  .Apercu > .foreground > .state3_elSite {
    position: absolute;
    left: 130px;
    right: 50px;
    top: 105px;
    width: calc(100.0% - 130px - 50px);
    height: 30px;
  }


  .Apercu > .foreground > .state3_elAnomalies {
    position: absolute;
    left: 20px;
    top: 110px;
    width: 110px;
    height: 27px;
  }


  .Apercu > .foreground > .state3_elSearch {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 60px;
    width: calc(100.0% - 20px - 20px);
    height: 34px;
  }


  .Apercu > .foreground > .state3_elText {
    overflow: hidden;
    position: absolute;
    left: 20px;
    right: 60px;
    top: 20px;
    width: calc(100.0% - 20px - 60px);
    height: 26px;
  }

.Apercu .state3_elFab2099238582 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Apercu .state3_elFab2099238582:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1250);
  outline: none;
}



  .Apercu > .foreground > .state3_elFab2099238582 {
    line-height: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
  }

/* This component has a fixed-size layout */
  .Apercu {
    min-height: 300px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 250px */
    height: 100%;
  }
  .Apercu > .background > .containerMinHeight {
    min-height: 300px;
  }


  .Apercu > .background > .state4_elBackground1982019444 {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -20.0px);
  }

.Apercu .state4_elAllOrdersList {
  overflow: auto;
  pointer-events: auto;
}



  .Apercu > .foreground > .state4_elAllOrdersList {
    position: absolute;
    left: 20px;
    right: 20px;
    top: 80px;
    width: calc(100.0% - 20px - 20px);
    height: calc(100% + 0.0% + -100.0px);
  }


  .Apercu > .foreground > .state4_elSub {
    overflow: hidden;
    position: absolute;
    left: 20px;
    right: 20px;
    top: 45px;
    width: calc(100.0% - 20px - 20px);
    height: 33px;
  }


  .Apercu > .foreground > .state4_elText {
    overflow: hidden;
    position: absolute;
    left: 20px;
    right: 60px;
    top: 20px;
    width: calc(100.0% - 20px - 60px);
    height: 26px;
  }

.Apercu .state4_elFab2099238582 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Apercu .state4_elFab2099238582:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1250);
  outline: none;
}



  .Apercu > .foreground > .state4_elFab2099238582 {
    line-height: 30px;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
    height: 30px;
  }


/* Narrowest size for this element's responsive layout */
  .ScannerAnomalieScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
@media (min-width: 568px) {
  .ScannerAnomalieScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
}
@media (min-width: 768px) {
  .ScannerAnomalieScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}
@media (min-width: 1024px) {
  .ScannerAnomalieScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}

.ScannerAnomalieScreen .elSearch_list {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.ScannerAnomalieScreen .elSearch_list:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ScannerAnomalieScreen > .screenFgContainer > .foreground > .elSearch_list {
    position: fixed;
    right: 5px;
    bottom: 5px;
    width: 41px;
    height: 40px;
  }
@media (min-width: 568px) {
  .ScannerAnomalieScreen > .screenFgContainer > .foreground > .elSearch_list {
    right: 5px;
    bottom: 5px;
    width: 41px;
    height: 40px;
  }
}
@media (min-width: 768px) {
  .ScannerAnomalieScreen > .screenFgContainer > .foreground > .elSearch_list {
    right: 5px;
    bottom: 5px;
    width: 41px;
    height: 40px;
  }
}
@media (min-width: 1024px) {
  .ScannerAnomalieScreen > .screenFgContainer > .foreground > .elSearch_list {
    right: 5px;
    bottom: 5px;
    width: 41px;
    height: 40px;
  }
}

.ScannerAnomalieScreen .elCapture {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.ScannerAnomalieScreen .elCapture:focus {
  box-shadow: 0 0 5px rgba(0, 223, 208, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ScannerAnomalieScreen > .screenFgContainer > .foreground > .elCapture {
    line-height: 40px;
    position: fixed;
    left: calc(50.0% + 0px - 50px);
    bottom: 5px;
    width: 100px;
    height: 40px;
  }
@media (min-width: 568px) {
  .ScannerAnomalieScreen > .screenFgContainer > .foreground > .elCapture {
    line-height: 40px;
    left: calc(50.0% + 0px - 50px);
    bottom: 5px;
    width: 100px;
    height: 40px;
  }
}
@media (min-width: 768px) {
  .ScannerAnomalieScreen > .screenFgContainer > .foreground > .elCapture {
    line-height: 40px;
    left: calc(50.0% + 0px - 50px);
    bottom: 5px;
    width: 99px;
    height: 40px;
  }
}
@media (min-width: 1024px) {
  .ScannerAnomalieScreen > .screenFgContainer > .foreground > .elCapture {
    line-height: 40px;
    left: calc(50.0% + 0px - 50px);
    bottom: 5px;
    width: 99px;
    height: 40px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ScannerAnomalieScreen > .screenFgContainer > .foreground > .elScanner {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -100.0px);
  }
@media (min-width: 568px) {
  .ScannerAnomalieScreen > .screenFgContainer > .foreground > .elScanner {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -100.0px);
  }
}
@media (min-width: 768px) {
  .ScannerAnomalieScreen > .screenFgContainer > .foreground > .elScanner {
    left: 0px;
    right: 0px;
    top: 75px;
    width: 100.0%;
    height: calc(100% + 0.0% + -124.7px);
  }
}
@media (min-width: 1024px) {
  .ScannerAnomalieScreen > .screenFgContainer > .foreground > .elScanner {
    left: 0px;
    right: 0px;
    top: 75px;
    width: 100.0%;
    height: calc(100% + 0.0% + -124.7px);
  }
}

.circularProgressIndicator {
    position: relative;
    width: 50px;
    height: 50px;
    display: none;
}
.circularProgressIndicator-active {
    animation: rotate 2s linear infinite;
    display: inline;
}
.circularProgress-animatedPath {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
    animation: circularProgress-dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
}
@keyframes rotate{
    100%{
    transform: rotate(360deg);
    }
}
@keyframes circularProgress-dash{
    0%{
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50%{
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100%{
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}



/* Narrowest size for this element's responsive layout */
  .ScannerAnomalieScreen > .screenFgContainer > .foreground > .elProgress {
    position: absolute;
    left: calc(50.0% + 0px - 24px);
    top: 200px;
    width: 48px;
    height: 48px;
  }
@media (min-width: 568px) {
  .ScannerAnomalieScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 24px);
    top: 200px;
    width: 48px;
    height: 48px;
  }
}
@media (min-width: 768px) {
  .ScannerAnomalieScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 24px);
    top: 199px;
    width: 47px;
    height: 47px;
  }
}
@media (min-width: 1024px) {
  .ScannerAnomalieScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 24px);
    top: 199px;
    width: 47px;
    height: 47px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Quai_view_dechScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .Quai_view_dechScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Quai_view_dechScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Quai_view_dechScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Quai_view_dechScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}

.Quai_view_dechScreen .elCancel {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Quai_view_dechScreen .elCancel:focus {
  box-shadow: 0 0 5px rgba(255, 130, 120, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elCancel {
    line-height: 35px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 145px;
    height: 35px;
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elCancel {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 145px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elCancel {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 144px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elCancel {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 144px;
    height: 35px;
  }
}

.Quai_view_dechScreen .elButton_spe {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Quai_view_dechScreen .elButton_spe:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elButton_spe {
    line-height: 35px;
    position: fixed;
    left: 10px;
    bottom: 10px;
    width: 145px;
    height: 35px;
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elButton_spe {
    line-height: 35px;
    left: 10px;
    bottom: 10px;
    width: 145px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elButton_spe {
    line-height: 35px;
    left: 10px;
    bottom: 10px;
    width: 144px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elButton_spe {
    line-height: 35px;
    left: 10px;
    bottom: 10px;
    width: 144px;
    height: 35px;
  }
}

.Quai_view_dechScreen .elButton_hors_quai {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Quai_view_dechScreen .elButton_hors_quai:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elButton_hors_quai {
    line-height: 35px;
    position: fixed;
    left: 10px;
    bottom: 10px;
    width: 145px;
    height: 35px;
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elButton_hors_quai {
    line-height: 35px;
    left: 10px;
    bottom: 10px;
    width: 145px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elButton_hors_quai {
    line-height: 35px;
    left: 10px;
    bottom: 10px;
    width: 144px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elButton_hors_quai {
    line-height: 35px;
    left: 10px;
    bottom: 10px;
    width: 144px;
    height: 35px;
  }
}

.Quai_view_dechScreen .elButton_quai {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Quai_view_dechScreen .elButton_quai:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elButton_quai {
    line-height: 35px;
    position: fixed;
    left: 10px;
    bottom: 10px;
    width: 145px;
    height: 35px;
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elButton_quai {
    line-height: 35px;
    left: 10px;
    bottom: 10px;
    width: 145px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elButton_quai {
    line-height: 35px;
    left: 10px;
    bottom: 10px;
    width: 144px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elButton_quai {
    line-height: 35px;
    left: 10px;
    bottom: 10px;
    width: 144px;
    height: 35px;
  }
}

.Quai_view_dechScreen .elOther_operation {
  overflow: hidden;
}

.Quai_view_dechScreen .elOther_operation.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elOther_operation {
    position: absolute;
    left: calc(9.4% + 0px);
    right: calc(9.4% + 0px);
    top: 130px;
    width: 81.2%;
    height: calc(100% + 0.0% + -180.0px);
  }
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elOther_operation > .background > .containerMinHeight {
    min-height: 368px;
  }

@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elOther_operation {
    left: calc(27.1% + 0px);
    right: calc(27.1% + 0px);
    top: 130px;
    width: 45.8%;
    height: calc(100% + 0.0% + -180.0px);
  }
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elOther_operation > .background > .containerMinHeight {
    min-height: 368px;
  }

}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elOther_operation {
    left: calc(33.1% + 0px);
    right: calc(33.1% + 0px);
    top: 129px;
    width: 33.9%;
    height: calc(100% + 0.0% + -178.9px);
  }
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elOther_operation > .background > .containerMinHeight {
    min-height: 369px;
  }

}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elOther_operation {
    left: calc(37.3% + 0px);
    right: calc(37.3% + 0px);
    top: 129px;
    width: 25.4%;
    height: calc(100% + 0.0% + -178.9px);
  }
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elOther_operation > .background > .containerMinHeight {
    min-height: 369px;
  }

}

.Quai_view_dechScreen .elList_hors_quai {
  overflow: auto;
  pointer-events: auto;
}

.Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_hors_quai > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 5px;
  grid-column-gap: 5px;  /* old name still required by some browsers */
}

.Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_hors_quai > div > * {
  display: inline-block;
  position: relative;
  min-width: 159px;
}

  @media (min-width: 1024px) {
    .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_hors_quai > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_hors_quai > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_hors_quai > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_hors_quai > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_hors_quai {
    position: absolute;
    left: calc(50.0% + 0px - 165px);
    top: 130px;
    width: 330px;
    height: calc(100% + 0.0% + -185.0px);
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_hors_quai {
    left: calc(50.0% + 0px - 165px);
    top: 130px;
    width: 330px;
    height: calc(100% + 0.0% + -185.0px);
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_hors_quai {
    left: calc(50.0% + 0px - 164px);
    top: 129px;
    width: 328px;
    height: calc(100% + 0.0% + -183.9px);
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_hors_quai {
    left: calc(50.0% + 0px - 164px);
    top: 129px;
    width: 328px;
    height: calc(100% + 0.0% + -183.9px);
  }
}

.Quai_view_dechScreen .elList_quai {
  overflow: auto;
  pointer-events: auto;
}

.Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_quai > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 5px;
  grid-column-gap: 5px;  /* old name still required by some browsers */
}

.Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_quai > div > * {
  display: inline-block;
  position: relative;
  min-width: 159px;
}

  @media (min-width: 1024px) {
    .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_quai > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_quai > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_quai > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_quai > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_quai {
    position: absolute;
    left: calc(50.0% + 0px - 165px);
    top: 130px;
    width: 330px;
    height: calc(100% + 0.0% + -185.0px);
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_quai {
    left: calc(50.0% + 0px - 165px);
    top: 130px;
    width: 330px;
    height: calc(100% + 0.0% + -185.0px);
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_quai {
    left: calc(50.0% + 0px - 164px);
    top: 129px;
    width: 328px;
    height: calc(100% + 0.0% + -183.9px);
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_quai {
    left: calc(50.0% + 0px - 164px);
    top: 129px;
    width: 328px;
    height: calc(100% + 0.0% + -183.9px);
  }
}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elDate {
    overflow: hidden;
    position: absolute;
    left: calc(50.0% + 0px);
    top: 100px;
    width: 165px;
    height: 30px;
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elDate {
    overflow: hidden;
    left: calc(50.0% + 0px);
    top: 100px;
    width: 165px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elDate {
    overflow: hidden;
    left: calc(50.0% + 0px);
    top: 99px;
    width: 164px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elDate {
    overflow: hidden;
    left: calc(50.0% + 0px);
    top: 99px;
    width: 164px;
    height: 30px;
  }
}

.Quai_view_dechScreen .elHors_quai {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Quai_view_dechScreen .elHors_quai:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2125);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elHors_quai {
    position: absolute;
    left: calc(50.0% + 0px);
    top: 97px;
    width: 30px;
    height: 25px;
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elHors_quai {
    left: calc(50.0% + 0px);
    top: 97px;
    width: 30px;
    height: 25px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elHors_quai {
    left: calc(50.0% + 0px);
    top: 96px;
    width: 30px;
    height: 25px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elHors_quai {
    left: calc(50.0% + 0px);
    top: 96px;
    width: 30px;
    height: 25px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elCheckbox_hors_quai {
    position: absolute;
    left: calc(50.0% + -80px);
    top: 100px;
    width: 100px;
    height: 27px;
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elCheckbox_hors_quai {
    left: calc(50.0% + -80px);
    top: 100px;
    width: 100px;
    height: 27px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elCheckbox_hors_quai {
    left: calc(50.0% + -80px);
    top: 99px;
    width: 99px;
    height: 27px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elCheckbox_hors_quai {
    left: calc(50.0% + -80px);
    top: 99px;
    width: 99px;
    height: 27px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elCheckbox_quai {
    position: absolute;
    left: calc(50.0% + -165px);
    top: 105px;
    width: 80px;
    height: 27px;
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elCheckbox_quai {
    left: calc(50.0% + -165px);
    top: 105px;
    width: 80px;
    height: 27px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elCheckbox_quai {
    left: calc(50.0% + -164px);
    top: 104px;
    width: 80px;
    height: 27px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elCheckbox_quai {
    left: calc(50.0% + -164px);
    top: 104px;
    width: 80px;
    height: 27px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 92px;
    width: 100.0%;
    height: 1px;
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 92px;
    width: 100.0%;
    height: 1px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 91px;
    width: 100.0%;
    height: 1px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 91px;
    width: 100.0%;
    height: 1px;
  }
}

.Quai_view_dechScreen .elList_spe {
  overflow: auto;
  pointer-events: auto;
}

.Quai_view_dechScreen .elList_spe li {
  margin-bottom: 10px;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_spe {
    position: absolute;
    left: calc(50.0% + 0px - 165px);
    top: 130px;
    width: 330px;
    height: calc(100% + 0.0% + -185.0px);
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_spe {
    left: calc(50.0% + 0px - 165px);
    top: 130px;
    width: 330px;
    height: calc(100% + 0.0% + -185.0px);
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_spe {
    left: calc(50.0% + 0px - 164px);
    top: 129px;
    width: 328px;
    height: calc(100% + 0.0% + -183.9px);
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elList_spe {
    left: calc(50.0% + 0px - 164px);
    top: 129px;
    width: 328px;
    height: calc(100% + 0.0% + -183.9px);
  }
}

.Quai_view_dechScreen .elMode_plus {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Quai_view_dechScreen .elMode_plus:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elMode_plus {
    position: fixed;
    right: 10px;
    top: 60px;
    width: 25px;
    height: 25px;
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elMode_plus {
    right: 10px;
    top: 60px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elMode_plus {
    right: 10px;
    top: 60px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elMode_plus {
    right: 10px;
    top: 60px;
    width: 25px;
    height: 25px;
  }
}

.Quai_view_dechScreen .elMode_minus {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Quai_view_dechScreen .elMode_minus:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elMode_minus {
    position: absolute;
    left: 10px;
    top: 60px;
    width: 25px;
    height: 25px;
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elMode_minus {
    left: 10px;
    top: 60px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elMode_minus {
    left: 10px;
    top: 60px;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elMode_minus {
    left: 10px;
    top: 60px;
    width: 25px;
    height: 25px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elMode {
    overflow: hidden;
    position: absolute;
    left: calc(9.4% + 30px);
    right: calc(9.4% + 30px);
    top: 60px;
    width: calc(81.2% - 30px - 30px);
    height: 28px;
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elMode {
    overflow: hidden;
    left: calc(27.1% + 30px);
    right: calc(27.1% + 30px);
    top: 60px;
    width: calc(45.8% - 30px - 30px);
    height: 28px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elMode {
    overflow: hidden;
    left: calc(33.1% + 30px);
    right: calc(33.1% + 30px);
    top: 60px;
    width: calc(33.9% - 30px - 30px);
    height: 28px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elMode {
    overflow: hidden;
    left: calc(37.3% + 30px);
    right: calc(37.3% + 30px);
    top: 60px;
    width: calc(25.4% - 30px - 30px);
    height: 27px;
  }
}

.Quai_view_dechScreen .elDone {
  overflow: hidden;
}

.Quai_view_dechScreen .elDone.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elDone {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 90px;
    width: 100.0%;
    height: 182px;
  }
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elDone > .background > .containerMinHeight {
    min-height: 182px;
  }

@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elDone {
    left: 0px;
    right: 0px;
    top: 90px;
    width: 100.0%;
    height: 182px;
  }
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elDone > .background > .containerMinHeight {
    min-height: 182px;
  }

}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elDone {
    left: 0px;
    right: 0px;
    top: 89px;
    width: 100.0%;
    height: 180px;
  }
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elDone > .background > .containerMinHeight {
    min-height: 181px;
  }

}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elDone {
    left: 0px;
    right: 0px;
    top: 89px;
    width: 100.0%;
    height: 180px;
  }
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elDone > .background > .containerMinHeight {
    min-height: 181px;
  }

}

.Quai_view_dechScreen .elShow_apercu {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Quai_view_dechScreen .elShow_apercu:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elShow_apercu {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    position: fixed;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}

.Quai_view_dechScreen .elApercu {
  overflow: hidden;
  pointer-events: auto;
}

.Quai_view_dechScreen .elApercu.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elApercu {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

@media (min-width: 568px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

}
@media (min-width: 768px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}
@media (min-width: 1024px) {
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .Quai_view_dechScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}


/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .MenuPrincipalScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 50.0%;
  }
  .MenuPrincipalScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .MenuPrincipalScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 50.0%;
  }
  .MenuPrincipalScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 50.0%;
  }
  .MenuPrincipalScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 50.0%;
  }
  .MenuPrincipalScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 4px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MenuPrincipalScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 4px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 4px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 4px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
@media (min-width: 568px) {
  .MenuPrincipalScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .layoutFlow > .elHead_tour {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elHead_tour > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MenuPrincipalScreen > .layoutFlow > .elHead_tour {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elHead_tour > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .layoutFlow > .elHead_tour {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elHead_tour > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .layoutFlow > .elHead_tour {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elHead_tour > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .layoutFlow > .elTournee {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elTournee > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MenuPrincipalScreen > .layoutFlow > .elTournee {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elTournee > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .layoutFlow > .elTournee {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elTournee > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .layoutFlow > .elTournee {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elTournee > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .layoutFlow > .elPicker_j {
    position: relative;
    margin-top: 0px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elPicker_j > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MenuPrincipalScreen > .layoutFlow > .elPicker_j {
    position: relative;
    margin-top: 0px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elPicker_j > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .layoutFlow > .elPicker_j {
    position: relative;
    margin-top: 0px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elPicker_j > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .layoutFlow > .elPicker_j {
    position: relative;
    margin-top: 0px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elPicker_j > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .layoutFlow > .elTruck {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elTruck > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MenuPrincipalScreen > .layoutFlow > .elTruck {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elTruck > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .layoutFlow > .elTruck {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elTruck > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .layoutFlow > .elTruck {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elTruck > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .layoutFlow > .elPicker_j_truck {
    position: relative;
    margin-top: 0px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elPicker_j_truck > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MenuPrincipalScreen > .layoutFlow > .elPicker_j_truck {
    position: relative;
    margin-top: 0px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elPicker_j_truck > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .layoutFlow > .elPicker_j_truck {
    position: relative;
    margin-top: 0px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elPicker_j_truck > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .layoutFlow > .elPicker_j_truck {
    position: relative;
    margin-top: 0px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elPicker_j_truck > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .layoutFlow > .elAlert {
    position: relative;
    margin-top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elAlert > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MenuPrincipalScreen > .layoutFlow > .elAlert {
    position: relative;
    margin-top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elAlert > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .layoutFlow > .elAlert {
    position: relative;
    margin-top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elAlert > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .layoutFlow > .elAlert {
    position: relative;
    margin-top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elAlert > * { 
    width: 100%;
  }
  
}

.MenuPrincipalScreen .elTour_j button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.MenuPrincipalScreen .elTour_j button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .layoutFlow > .elTour_j {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 150px;
    margin-left: calc(50.0% - 75px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elTour_j > * { 
  width: 150px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .MenuPrincipalScreen > .layoutFlow > .elTour_j {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 150px;
    margin-left: calc(50.0% - 75px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elTour_j > * { 
  width: 150px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .layoutFlow > .elTour_j {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 149px;
    margin-left: calc(50.0% - 74px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elTour_j > * { 
  width: 149px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .layoutFlow > .elTour_j {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 149px;
    margin-left: calc(50.0% - 74px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elTour_j > * { 
  width: 149px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .layoutFlow > .elHead_dech {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elHead_dech > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MenuPrincipalScreen > .layoutFlow > .elHead_dech {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elHead_dech > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .layoutFlow > .elHead_dech {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elHead_dech > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .layoutFlow > .elHead_dech {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elHead_dech > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .layoutFlow > .elDech {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elDech > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MenuPrincipalScreen > .layoutFlow > .elDech {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elDech > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .layoutFlow > .elDech {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elDech > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .layoutFlow > .elDech {
    overflow: hidden;
    position: relative;
    margin-top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elDech > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .layoutFlow > .elPicker_dech {
    position: relative;
    margin-top: 0px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elPicker_dech > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .MenuPrincipalScreen > .layoutFlow > .elPicker_dech {
    position: relative;
    margin-top: 0px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elPicker_dech > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .layoutFlow > .elPicker_dech {
    position: relative;
    margin-top: 0px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elPicker_dech > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .layoutFlow > .elPicker_dech {
    position: relative;
    margin-top: 0px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elPicker_dech > * { 
    width: 100%;
  }
  
}

.MenuPrincipalScreen .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.MenuPrincipalScreen .elButton button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .MenuPrincipalScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .MenuPrincipalScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .screenFgContainer > .foreground > .elProgress {
    position: absolute;
    left: calc(50.0% + 0px - 24px);
    top: 70px;
    width: 48px;
    height: 48px;
  }
@media (min-width: 568px) {
  .MenuPrincipalScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 24px);
    top: 70px;
    width: 48px;
    height: 48px;
  }
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 24px);
    top: 70px;
    width: 47px;
    height: 47px;
  }
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 24px);
    top: 70px;
    width: 47px;
    height: 47px;
  }
}

.MenuPrincipalScreen .elLogout {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.MenuPrincipalScreen .elLogout:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .MenuPrincipalScreen > .screenFgContainer > .foreground > .elLogout {
    position: fixed;
    right: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }
@media (min-width: 568px) {
  .MenuPrincipalScreen > .screenFgContainer > .foreground > .elLogout {
    right: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 768px) {
  .MenuPrincipalScreen > .screenFgContainer > .foreground > .elLogout {
    right: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 1024px) {
  .MenuPrincipalScreen > .screenFgContainer > .foreground > .elLogout {
    right: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .background > .state0_elBackground825705 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ConnexionScreen > .background > .state0_elBackground825705 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ConnexionScreen > .background > .state0_elBackground825705 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ConnexionScreen > .background > .state0_elBackground825705 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state0_elBienvenue2129009774 {
    position: relative;
    margin-top: 135px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elBienvenue2129009774 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state0_elBienvenue2129009774 {
    position: relative;
    margin-top: 135px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elBienvenue2129009774 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state0_elBienvenue2129009774 {
    position: relative;
    margin-top: 134px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elBienvenue2129009774 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state0_elBienvenue2129009774 {
    position: relative;
    margin-top: 134px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elBienvenue2129009774 > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state0_elAuthentification {
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elAuthentification > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state0_elAuthentification {
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elAuthentification > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state0_elAuthentification {
    position: relative;
    margin-top: 30px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elAuthentification > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state0_elAuthentification {
    position: relative;
    margin-top: 30px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elAuthentification > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state0_elProgress1420551409 {
    position: relative;
    margin-top: 10px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elProgress1420551409 > * { 
  width: 48px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state0_elProgress1420551409 {
    position: relative;
    margin-top: 10px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elProgress1420551409 > * { 
  width: 48px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state0_elProgress1420551409 {
    position: relative;
    margin-top: 10px;
    width: 47px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elProgress1420551409 > * { 
  width: 47px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state0_elProgress1420551409 {
    position: relative;
    margin-top: 10px;
    width: 47px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state0_elProgress1420551409 > * { 
  width: 47px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .background > .state1_elBackground825705 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ConnexionScreen > .background > .state1_elBackground825705 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ConnexionScreen > .background > .state1_elBackground825705 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ConnexionScreen > .background > .state1_elBackground825705 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state1_elBienvenue2129009774 {
    position: relative;
    margin-top: 135px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elBienvenue2129009774 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state1_elBienvenue2129009774 {
    position: relative;
    margin-top: 135px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elBienvenue2129009774 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state1_elBienvenue2129009774 {
    position: relative;
    margin-top: 134px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elBienvenue2129009774 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state1_elBienvenue2129009774 {
    position: relative;
    margin-top: 134px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elBienvenue2129009774 > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state1_elUser_name {
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elUser_name > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state1_elUser_name {
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elUser_name > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state1_elUser_name {
    position: relative;
    margin-top: 30px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elUser_name > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state1_elUser_name {
    position: relative;
    margin-top: 30px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elUser_name > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state1_elEntreprise {
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elEntreprise > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state1_elEntreprise {
    position: relative;
    margin-top: 30px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elEntreprise > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state1_elEntreprise {
    position: relative;
    margin-top: 30px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elEntreprise > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state1_elEntreprise {
    position: relative;
    margin-top: 30px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elEntreprise > * { 
  width: 236px;
  }
  
}

.ConnexionScreen .state1_elCommencer button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.ConnexionScreen .state1_elCommencer button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state1_elCommencer {
    line-height: 35px;
    position: relative;
    margin-top: 30px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elCommencer > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state1_elCommencer {
    line-height: 35px;
    position: relative;
    margin-top: 30px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elCommencer > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state1_elCommencer {
    line-height: 35px;
    position: relative;
    margin-top: 30px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elCommencer > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state1_elCommencer {
    line-height: 35px;
    position: relative;
    margin-top: 30px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elCommencer > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state1_elProgress1420551409 {
    position: relative;
    margin-top: 10px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elProgress1420551409 > * { 
  width: 48px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state1_elProgress1420551409 {
    position: relative;
    margin-top: 10px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elProgress1420551409 > * { 
  width: 48px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state1_elProgress1420551409 {
    position: relative;
    margin-top: 10px;
    width: 47px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elProgress1420551409 > * { 
  width: 47px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state1_elProgress1420551409 {
    position: relative;
    margin-top: 10px;
    width: 47px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state1_elProgress1420551409 > * { 
  width: 47px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .screenFgContainer > .foreground > .state1_elLogo {
    position: absolute;
    left: calc(50.0% + 0px - 56px);
    top: 70px;
    width: 113px;
    height: 50px;
  }
@media (min-width: 568px) {
  .ConnexionScreen > .screenFgContainer > .foreground > .state1_elLogo {
    left: calc(50.0% + 0px - 56px);
    top: 70px;
    width: 113px;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .ConnexionScreen > .screenFgContainer > .foreground > .state1_elLogo {
    left: calc(50.0% + 0px - 56px);
    top: 70px;
    width: 112px;
    height: 50px;
  }
}
@media (min-width: 1024px) {
  .ConnexionScreen > .screenFgContainer > .foreground > .state1_elLogo {
    left: calc(50.0% + 0px - 56px);
    top: 70px;
    width: 112px;
    height: 50px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .background > .state2_elBackground825705 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ConnexionScreen > .background > .state2_elBackground825705 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ConnexionScreen > .background > .state2_elBackground825705 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ConnexionScreen > .background > .state2_elBackground825705 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state2_elBienvenue2129009774 {
    position: relative;
    margin-top: 135px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elBienvenue2129009774 > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state2_elBienvenue2129009774 {
    position: relative;
    margin-top: 135px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elBienvenue2129009774 > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state2_elBienvenue2129009774 {
    position: relative;
    margin-top: 134px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elBienvenue2129009774 > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state2_elBienvenue2129009774 {
    position: relative;
    margin-top: 134px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elBienvenue2129009774 > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state2_elEmail {
    position: relative;
    margin-top: 20px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elEmail > * { 
  width: 226px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state2_elEmail {
    position: relative;
    margin-top: 20px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elEmail > * { 
  width: 226px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state2_elEmail {
    position: relative;
    margin-top: 20px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elEmail > * { 
  width: 225px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state2_elEmail {
    position: relative;
    margin-top: 20px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elEmail > * { 
  width: 225px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state2_elPassword {
    position: relative;
    margin-top: 20px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elPassword > * { 
  width: 226px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state2_elPassword {
    position: relative;
    margin-top: 20px;
    width: 226px;
    margin-left: calc(50.0% - 113px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elPassword > * { 
  width: 226px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state2_elPassword {
    position: relative;
    margin-top: 20px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elPassword > * { 
  width: 225px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state2_elPassword {
    position: relative;
    margin-top: 20px;
    width: 225px;
    margin-left: calc(50.0% - 112px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elPassword > * { 
  width: 225px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state2_elErrorText {
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elErrorText > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state2_elErrorText {
    position: relative;
    margin-top: 20px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elErrorText > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state2_elErrorText {
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elErrorText > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state2_elErrorText {
    position: relative;
    margin-top: 20px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elErrorText > * { 
  width: 236px;
  }
  
}

.ConnexionScreen .state2_elConnexion button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.ConnexionScreen .state2_elConnexion button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state2_elConnexion {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 150px;
    margin-left: calc(50.0% - 75px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elConnexion > * { 
  width: 150px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state2_elConnexion {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 150px;
    margin-left: calc(50.0% - 75px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elConnexion > * { 
  width: 150px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state2_elConnexion {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 149px;
    margin-left: calc(50.0% - 74px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elConnexion > * { 
  width: 149px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state2_elConnexion {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 149px;
    margin-left: calc(50.0% - 74px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elConnexion > * { 
  width: 149px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state2_elReset_pwd {
    position: relative;
    margin-top: 25px;
    width: 150px;
    margin-left: calc(50.0% - 75px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elReset_pwd > * { 
  width: 150px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state2_elReset_pwd {
    position: relative;
    margin-top: 25px;
    width: 150px;
    margin-left: calc(50.0% - 75px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elReset_pwd > * { 
  width: 150px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state2_elReset_pwd {
    position: relative;
    margin-top: 25px;
    width: 149px;
    margin-left: calc(50.0% - 74px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elReset_pwd > * { 
  width: 149px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state2_elReset_pwd {
    position: relative;
    margin-top: 25px;
    width: 149px;
    margin-left: calc(50.0% - 74px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elReset_pwd > * { 
  width: 149px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state2_elImage {
    position: relative;
    margin-top: 20px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elImage > * { 
  width: 100px;
  height: 100px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state2_elImage {
    position: relative;
    margin-top: 20px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elImage > * { 
  width: 100px;
  height: 100px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state2_elImage {
    position: relative;
    margin-top: 20px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elImage > * { 
  width: 100px;
  height: 100px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state2_elImage {
    position: relative;
    margin-top: 20px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elImage > * { 
  width: 100px;
  height: 100px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state2_elNfc_status {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elNfc_status > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state2_elNfc_status {
    position: relative;
    margin-top: 0px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elNfc_status > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state2_elNfc_status {
    position: relative;
    margin-top: 0px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elNfc_status > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state2_elNfc_status {
    position: relative;
    margin-top: 0px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elNfc_status > * { 
  width: 236px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConnexionScreen > .layoutFlow > .state2_elProgress1420551409 {
    position: relative;
    margin-top: 10px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elProgress1420551409 > * { 
  width: 48px;
  }
  
@media (min-width: 568px) {
  .ConnexionScreen > .layoutFlow > .state2_elProgress1420551409 {
    position: relative;
    margin-top: 10px;
    width: 48px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elProgress1420551409 > * { 
  width: 48px;
  }
  
}
@media (min-width: 768px) {
  .ConnexionScreen > .layoutFlow > .state2_elProgress1420551409 {
    position: relative;
    margin-top: 10px;
    width: 47px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elProgress1420551409 > * { 
  width: 47px;
  }
  
}
@media (min-width: 1024px) {
  .ConnexionScreen > .layoutFlow > .state2_elProgress1420551409 {
    position: relative;
    margin-top: 10px;
    width: 47px;
    margin-left: calc(50.0% - 24px);
  }
  .ConnexionScreen > .layoutFlow > .state2_elProgress1420551409 > * { 
  width: 47px;
  }
  
}

/* This component has a fixed-size layout */
  .Button_tournee {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 100px; /* This component has a fixed size */
  }
  .Button_tournee > .background > .containerMinHeight {
    min-height: 30px;
  }

.Button_tournee .elButton_select {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Button_tournee .elButton_select:focus {
  box-shadow: 0 0 5px rgba(0, 223, 208, 0.5000);
  outline: none;
}



  .Button_tournee > .foreground > .elButton_select {
    line-height: 30px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

.Button_tournee .elButton_not_select {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Button_tournee .elButton_not_select:focus {
  box-shadow: 0 0 5px rgba(146, 146, 146, 0.5000);
  outline: none;
}



  .Button_tournee > .foreground > .elButton_not_select {
    line-height: 30px;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Header_feuille_tournee {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 300px; /* This component has a fixed size */
  }
  .Header_feuille_tournee > .background > .containerMinHeight {
    min-height: 30px;
  }


  .Header_feuille_tournee > .foreground > .elTextCopy2 {
    overflow: hidden;
    position: absolute;
    left: 2px;
    bottom: 4px;
    width: 90px;
    height: 26px;
  }


  .Header_feuille_tournee > .foreground > .elTextCopy3 {
    overflow: hidden;
    position: absolute;
    left: 90px;
    bottom: 4px;
    width: 90px;
    height: 26px;
  }


  .Header_feuille_tournee > .foreground > .elTextCopy {
    overflow: hidden;
    position: absolute;
    right: 5px;
    bottom: 4px;
    width: 90px;
    height: 26px;
  }

/* This component has a fixed-size layout */
  .Ot_tournee_j {
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }


  .Ot_tournee_j > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Ot_tournee_j > .background > .elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100.0%;
    height: 1px;
  }


  .Ot_tournee_j > .layoutFlow > .elSite {
    overflow: hidden;
    position: relative;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 70px;
    width: calc(100.0% - 75px);
  }
  .Ot_tournee_j > .layoutFlow > .elSite > * { 
    width: 100%;
  }
  


  .Ot_tournee_j > .layoutFlow > .flowRow > .elAction {
    overflow: hidden;
    position: absolute;
    margin-top: 5px;
    width: 100%;
  }
  .Ot_tournee_j > .layoutFlow > .flowRow > .elAction > * { 
  margin-left: auto;
  width: 120px;
  margin-right: 5px;
  }
  
  .flowRow_Ot_tournee_j_elAction_2061235096 { 
  }
  


  .Ot_tournee_j > .layoutFlow > .elFlux {
    overflow: hidden;
    position: relative;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 125px;
    width: calc(100.0% - 130px);
  }
  .Ot_tournee_j > .layoutFlow > .elFlux > * { 
    width: 100%;
  }
  


  .Ot_tournee_j > .layoutFlow > .elType {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    margin-left: 5px;
    margin-right: 125px;
    width: calc(100.0% - 130px);
  }
  .Ot_tournee_j > .layoutFlow > .elType > * { 
    width: 100%;
  }
  


  .Ot_tournee_j > .layoutFlow > .elExutoire {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    margin-left: 5px;
    margin-right: 5px;
    width: calc(100.0% - 10px);
  }
  .Ot_tournee_j > .layoutFlow > .elExutoire > * { 
    width: 100%;
  }
  


  .Ot_tournee_j > .layoutFlow > .elRemark {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    margin-left: 5px;
    margin-right: 5px;
    width: calc(100.0% - 10px);
  }
  .Ot_tournee_j > .layoutFlow > .elRemark > * { 
    width: 100%;
  }
  


  .Ot_tournee_j > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Ot_tournee_j > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 5px;
  }
  

.Ot_tournee_j .elChuter {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Ot_tournee_j .elChuter:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .Ot_tournee_j > .foreground > .elChuter {
    position: absolute;
    right: 0px;
    top: 3px;
    width: 30px;
    height: 30px;
  }

.Ot_tournee_j .elPlay {
  overflow: hidden;
}

.Ot_tournee_j .elPlay.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Ot_tournee_j > .foreground > .elPlay {
    position: absolute;
    right: 40px;
    top: 3px;
    width: 30px;
    height: 30px;
  }

.Ot_tournee_j .elPrevent_interraction {
  overflow: hidden;
}

.Ot_tournee_j .elPrevent_interraction.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Ot_tournee_j > .foreground > .elPrevent_interraction {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


/* Narrowest size for this element's responsive layout */
  .TournéeDuJourScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .TournéeDuJourScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .TournéeDuJourScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .TournéeDuJourScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.TournéeDuJourScreen .elImage_fill_full {
  overflow: hidden;
}

.TournéeDuJourScreen .elImage_fill_full.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elImage_fill_full {
    position: fixed;
    left: calc(50.0% + 0px - 18px);
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

@media (min-width: 568px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elImage_fill_full {
    left: calc(50.0% + 0px - 18px);
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 768px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elImage_fill_full {
    left: calc(50.0% + 0px - 18px);
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 1024px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elImage_fill_full {
    left: calc(50.0% + 0px - 18px);
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elProgress {
    position: fixed;
    left: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elProgress {
    left: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elProgress {
    left: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elProgress {
    left: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}

.TournéeDuJourScreen .elButton {
  cursor: pointer;
  border: 0.9px solid #ff7d78;
  border-radius: 3.4px;
}

.TournéeDuJourScreen .elButton:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elButton {
    line-height: 35px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 140px;
    height: 35px;
  }
@media (min-width: 568px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elButton {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 140px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elButton {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 139px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elButton {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 139px;
    height: 35px;
  }
}

.TournéeDuJourScreen .elListCopy {
  overflow: auto;
  pointer-events: auto;
}



/* Narrowest size for this element's responsive layout */
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elListCopy {
    position: absolute;
    left: 3.0%;
    right: calc(3.0% + 0px);
    top: 60px;
    width: 94.0%;
    height: calc(100% + 0.0% + -115.0px);
  }
@media (min-width: 568px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elListCopy {
    left: 3.0%;
    right: calc(3.0% + 0px);
    top: 60px;
    width: 94.0%;
    height: calc(100% + 0.0% + -115.0px);
  }
}
@media (min-width: 768px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elListCopy {
    left: 3.0%;
    right: calc(3.0% + 0px);
    top: 60px;
    width: 94.0%;
    height: calc(100% + 0.0% + -114.3px);
  }
}
@media (min-width: 1024px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elListCopy {
    left: 3.0%;
    right: calc(3.0% + 0px);
    top: 60px;
    width: 94.0%;
    height: calc(100% + 0.0% + -114.3px);
  }
}

.TournéeDuJourScreen .elMenu {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.TournéeDuJourScreen .elMenu:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elMenu {
    position: absolute;
    left: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }
@media (min-width: 568px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elMenu {
    left: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 768px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elMenu {
    left: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 1024px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elMenu {
    left: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }
}

.TournéeDuJourScreen .elShow_apercu {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.TournéeDuJourScreen .elShow_apercu:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elShow_apercu {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    position: fixed;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
@media (min-width: 568px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 768px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 1024px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}

.TournéeDuJourScreen .elApercu {
  overflow: hidden;
  pointer-events: auto;
}

.TournéeDuJourScreen .elApercu.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elApercu {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

@media (min-width: 568px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

}
@media (min-width: 768px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}
@media (min-width: 1024px) {
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .TournéeDuJourScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}


/* Narrowest size for this element's responsive layout */
  .TournéeDemainScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .TournéeDemainScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .TournéeDemainScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .TournéeDemainScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .TournéeDemainScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 84px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .TournéeDemainScreen > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
@media (min-width: 568px) {
  .TournéeDemainScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 84px;
    width: 238px;
    margin-left: calc(50.0% - 119px);
  }
  .TournéeDemainScreen > .layoutFlow > .elText > * { 
  width: 238px;
  }
  
}
@media (min-width: 768px) {
  .TournéeDemainScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 84px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .TournéeDemainScreen > .layoutFlow > .elText > * { 
  width: 236px;
  }
  
}
@media (min-width: 1024px) {
  .TournéeDemainScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 84px;
    width: 236px;
    margin-left: calc(50.0% - 118px);
  }
  .TournéeDemainScreen > .layoutFlow > .elText > * { 
  width: 236px;
  }
  
}



/* Narrowest size for this element's responsive layout */
  .TournéeDemainScreen > .layoutFlow > .elListCopy {
    position: relative;
    margin-top: 20px;
    margin-left: 3.0%;
    margin-right: calc(3.0% + 0px);
    width: calc(94.0% - 0px);
  }
  .TournéeDemainScreen > .layoutFlow > .elListCopy > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .TournéeDemainScreen > .layoutFlow > .elListCopy {
    position: relative;
    margin-top: 20px;
    margin-left: 3.0%;
    margin-right: calc(3.0% + 0px);
    width: calc(94.0% - 0px);
  }
  .TournéeDemainScreen > .layoutFlow > .elListCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .TournéeDemainScreen > .layoutFlow > .elListCopy {
    position: relative;
    margin-top: 20px;
    margin-left: 3.0%;
    margin-right: calc(3.0% + 0px);
    width: calc(94.0% - 0px);
  }
  .TournéeDemainScreen > .layoutFlow > .elListCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .TournéeDemainScreen > .layoutFlow > .elListCopy {
    position: relative;
    margin-top: 20px;
    margin-left: 3.0%;
    margin-right: calc(3.0% + 0px);
    width: calc(94.0% - 0px);
  }
  .TournéeDemainScreen > .layoutFlow > .elListCopy > * { 
    width: 100%;
  }
  
}

/* This component has a fixed-size layout */
  .Rot_enlev {
    min-height: 32px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 32px; /* This component has a fixed size */
  }
  .Rot_enlev > .background > .containerMinHeight {
    min-height: 32px;
  }

.Rot_enlev .state0_elIconButtonCopy2 {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Rot_enlev .state0_elIconButtonCopy2:focus {
  box-shadow: 0 0 5px rgba(169, 169, 169, 0.5000);
  outline: none;
}



  .Rot_enlev > .foreground > .state0_elIconButtonCopy2 {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 30px;
    height: 30px;
  }

/* This component has a fixed-size layout */
  .Rot_enlev {
    min-height: 32px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 32px; /* This component has a fixed size */
  }
  .Rot_enlev > .background > .containerMinHeight {
    min-height: 32px;
  }

.Rot_enlev .state1_elIconButtonCopy {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Rot_enlev .state1_elIconButtonCopy:focus {
  box-shadow: 0 0 5px rgba(169, 169, 169, 0.5000);
  outline: none;
}



  .Rot_enlev > .foreground > .state1_elIconButtonCopy {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 30px;
    height: 30px;
  }

/* This component has a fixed-size layout */
  .Rot_enlev {
    min-height: 32px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 32px; /* This component has a fixed size */
  }
  .Rot_enlev > .background > .containerMinHeight {
    min-height: 32px;
  }

.Rot_enlev .state2_elIconButtonCopy {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Rot_enlev .state2_elIconButtonCopy:focus {
  box-shadow: 0 0 5px rgba(169, 169, 169, 0.5000);
  outline: none;
}



  .Rot_enlev > .foreground > .state2_elIconButtonCopy {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 30px;
    height: 30px;
  }

/* This component has a fixed-size layout */
  .Rot_enlev {
    min-height: 32px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 32px; /* This component has a fixed size */
  }
  .Rot_enlev > .background > .containerMinHeight {
    min-height: 32px;
  }

.Rot_enlev .state3_elIconButtonCopy {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Rot_enlev .state3_elIconButtonCopy:focus {
  box-shadow: 0 0 5px rgba(169, 169, 169, 0.5000);
  outline: none;
}



  .Rot_enlev > .foreground > .state3_elIconButtonCopy {
    position: absolute;
    left: 0px;
    top: -9px;
    width: 30px;
    height: 30px;
  }

/* This component has a fixed-size layout */
  .Rot_enlev {
    min-height: 32px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 32px; /* This component has a fixed size */
  }
  .Rot_enlev > .background > .containerMinHeight {
    min-height: 32px;
  }

.Rot_enlev .state4_elIconButtonCopy {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Rot_enlev .state4_elIconButtonCopy:focus {
  box-shadow: 0 0 5px rgba(169, 169, 169, 0.5000);
  outline: none;
}



  .Rot_enlev > .foreground > .state4_elIconButtonCopy {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 30px;
    height: 30px;
  }


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ChuterUnOTScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 142px;
  }
  .ChuterUnOTScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 142px;
  }
  .ChuterUnOTScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 140px;
  }
  .ChuterUnOTScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 140px;
  }
  .ChuterUnOTScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 10px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 10px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 10px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .elText {
    position: relative;
    margin-top: 10px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCard_ot {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(9.4% + -10px);
    margin-right: 7.8%;
    width: calc(82.8% - -10px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCard_ot > * { 
    width: 100%;
  height: 120px;
  }
  
  .flowRow_ChuterUnOTScreen_elCard_ot_276273553 { 
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCard_ot {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(27.1% + -10px);
    margin-right: 26.2%;
    width: calc(46.7% - -10px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCard_ot > * { 
    width: 100%;
  height: 120px;
  }
  
  .flowRow_ChuterUnOTScreen_elCard_ot_276273553 { 
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCard_ot {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(33.1% + -10px);
    margin-right: 32.4%;
    width: calc(34.5% - -10px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCard_ot > * { 
    width: 100%;
  height: 119px;
  }
  
  .flowRow_ChuterUnOTScreen_elCard_ot_276273553 { 
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCard_ot {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(37.3% + -10px);
    margin-right: 36.8%;
    width: calc(25.9% - -10px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCard_ot > * { 
    width: 100%;
  height: 119px;
  }
  
  .flowRow_ChuterUnOTScreen_elCard_ot_276273553 { 
  }
  
}

.ChuterUnOTScreen .elImage_fill_full {
  overflow: hidden;
}

.ChuterUnOTScreen .elImage_fill_full.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage_fill_full {
    position: absolute;
    top: 20px;
    width: 100px;
    margin-left: calc(90.6% + -100px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage_fill_full > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage_fill_full {
    position: absolute;
    top: 20px;
    width: 100px;
    margin-left: calc(72.9% + -100px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage_fill_full > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage_fill_full {
    position: absolute;
    top: 20px;
    width: 100px;
    margin-left: calc(66.9% + -99px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage_fill_full > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage_fill_full {
    position: absolute;
    top: 20px;
    width: 100px;
    margin-left: calc(62.7% + -99px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage_fill_full > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elSite {
    overflow: hidden;
    position: absolute;
    top: 20px;
    margin-left: 9.4%;
    margin-right: 26.6%;
    width: calc(64.1% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elSite > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elSite {
    overflow: hidden;
    position: absolute;
    top: 20px;
    margin-left: 27.1%;
    margin-right: 36.8%;
    width: calc(36.1% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elSite > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elSite {
    overflow: hidden;
    position: absolute;
    top: 20px;
    margin-left: 33.1%;
    margin-right: 40.2%;
    width: calc(26.7% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elSite > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elSite {
    overflow: hidden;
    position: absolute;
    top: 20px;
    margin-left: 37.3%;
    margin-right: 42.6%;
    width: calc(20.1% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elSite > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elFlux {
    overflow: hidden;
    position: absolute;
    top: 40px;
    margin-left: 9.4%;
    margin-right: 26.6%;
    width: calc(64.1% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elFlux > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elFlux {
    overflow: hidden;
    position: absolute;
    top: 40px;
    margin-left: 27.1%;
    margin-right: 36.8%;
    width: calc(36.1% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elFlux > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elFlux {
    overflow: hidden;
    position: absolute;
    top: 40px;
    margin-left: 33.1%;
    margin-right: 40.2%;
    width: calc(26.7% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elFlux > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elFlux {
    overflow: hidden;
    position: absolute;
    top: 40px;
    margin-left: 37.3%;
    margin-right: 42.6%;
    width: calc(20.1% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elFlux > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elType {
    overflow: hidden;
    position: absolute;
    top: 60px;
    margin-left: 9.4%;
    margin-right: 26.6%;
    width: calc(64.1% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elType > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elType {
    overflow: hidden;
    position: absolute;
    top: 60px;
    margin-left: 27.1%;
    margin-right: 36.8%;
    width: calc(36.1% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elType > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elType {
    overflow: hidden;
    position: absolute;
    top: 60px;
    margin-left: 33.1%;
    margin-right: 40.2%;
    width: calc(26.7% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elType > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elType {
    overflow: hidden;
    position: absolute;
    top: 60px;
    margin-left: 37.3%;
    margin-right: 42.6%;
    width: calc(20.1% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elType > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elExutoire {
    overflow: hidden;
    position: absolute;
    top: 80px;
    margin-left: 9.4%;
    margin-right: 26.6%;
    width: calc(64.1% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elExutoire > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elExutoire {
    overflow: hidden;
    position: absolute;
    top: 80px;
    margin-left: 27.1%;
    margin-right: 36.8%;
    width: calc(36.1% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elExutoire > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elExutoire {
    overflow: hidden;
    position: absolute;
    top: 80px;
    margin-left: 33.1%;
    margin-right: 40.2%;
    width: calc(26.7% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elExutoire > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elExutoire {
    overflow: hidden;
    position: absolute;
    top: 80px;
    margin-left: 37.3%;
    margin-right: 42.6%;
    width: calc(20.1% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elExutoire > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elRemark {
    overflow: hidden;
    position: absolute;
    top: 100px;
    margin-left: 9.4%;
    margin-right: 26.6%;
    width: calc(64.1% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elRemark > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elRemark {
    overflow: hidden;
    position: absolute;
    top: 100px;
    margin-left: 27.1%;
    margin-right: 36.8%;
    width: calc(36.1% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elRemark > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elRemark {
    overflow: hidden;
    position: absolute;
    top: 100px;
    margin-left: 33.1%;
    margin-right: 40.2%;
    width: calc(26.7% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elRemark > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elRemark {
    overflow: hidden;
    position: absolute;
    top: 100px;
    margin-left: 37.3%;
    margin-right: 42.6%;
    width: calc(20.1% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elRemark > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ChuterUnOTScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 140px;
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ChuterUnOTScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 140px;
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ChuterUnOTScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 140px;
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ChuterUnOTScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 140px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(9.4% + -10px);
    margin-right: 7.8%;
    width: calc(82.8% - -10px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 200px;
  }
  
  .flowRow_ChuterUnOTScreen_elCard_2053344682 { 
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(27.1% + -10px);
    margin-right: 26.2%;
    width: calc(46.7% - -10px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 200px;
  }
  
  .flowRow_ChuterUnOTScreen_elCard_2053344682 { 
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(33.1% + -10px);
    margin-right: 32.4%;
    width: calc(34.5% - -10px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 199px;
  }
  
  .flowRow_ChuterUnOTScreen_elCard_2053344682 { 
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(37.3% + -10px);
    margin-right: 36.8%;
    width: calc(25.9% - -10px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 199px;
  }
  
  .flowRow_ChuterUnOTScreen_elCard_2053344682 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCommentaire {
    position: absolute;
    top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCommentaire > * { 
    width: 100%;
  height: 60px;
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCommentaire {
    position: absolute;
    top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCommentaire > * { 
    width: 100%;
  height: 60px;
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCommentaire {
    position: absolute;
    top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCommentaire > * { 
    width: 100%;
  height: 60px;
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCommentaire {
    position: absolute;
    top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCommentaire > * { 
    width: 100%;
  height: 60px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 100px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 100px;
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 100px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 100px;
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 100px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 100px;
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 100px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 100px;
  }
  
}

.ChuterUnOTScreen .elErase button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ChuterUnOTScreen .elErase button:focus {
  box-shadow: 0 0 6px rgba(255, 129, 120, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 100px;
    width: 100%;
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 9.4%;
  height: 30px;
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 100px;
    width: 100%;
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 27.1%;
  height: 30px;
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 100px;
    width: 100%;
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 33.1%;
  height: 30px;
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 100px;
    width: 100%;
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 37.3%;
  height: 30px;
  }
  
}

.ChuterUnOTScreen .elCapture button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ChuterUnOTScreen .elCapture button:focus {
  box-shadow: 0 0 6px rgba(0, 226, 217, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 100px;
    width: 140px;
    margin-left: calc(50.0% - 70px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCapture > * { 
  width: 140px;
  height: 100px;
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 100px;
    width: 140px;
    margin-left: calc(50.0% - 70px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCapture > * { 
  width: 140px;
  height: 100px;
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 100px;
    width: 139px;
    margin-left: calc(50.0% - 70px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCapture > * { 
  width: 139px;
  height: 99px;
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 100px;
    width: 139px;
    margin-left: calc(50.0% - 70px);
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elCapture > * { 
  width: 139px;
  height: 99px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ChuterUnOTScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 210px;
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ChuterUnOTScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 210px;
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ChuterUnOTScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 210px;
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ChuterUnOTScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 210px;
  }
  
}

.ChuterUnOTScreen .elRetour button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.ChuterUnOTScreen .elRetour button:focus {
  box-shadow: 0 0 5px rgba(255, 129, 120, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elRetour {
    line-height: 35px;
    position: absolute;
    margin-top: 20px;
    width: 100%;
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elRetour > * { 
  margin-left: auto;
  width: 145px;
  margin-right: 10px;
  height: 35px;
  }
  
  .flowRow_ChuterUnOTScreen_elRetour_467334 { 
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elRetour {
    line-height: 35px;
    position: absolute;
    margin-top: 20px;
    width: 100%;
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elRetour > * { 
  margin-left: auto;
  width: 145px;
  margin-right: 10px;
  height: 35px;
  }
  
  .flowRow_ChuterUnOTScreen_elRetour_467334 { 
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elRetour {
    line-height: 35px;
    position: absolute;
    margin-top: 20px;
    width: 100%;
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elRetour > * { 
  margin-left: auto;
  width: 144px;
  margin-right: 10px;
  height: 35px;
  }
  
  .flowRow_ChuterUnOTScreen_elRetour_467334 { 
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elRetour {
    line-height: 35px;
    position: absolute;
    margin-top: 20px;
    width: 100%;
  }
  .ChuterUnOTScreen > .layoutFlow > .flowRow > .elRetour > * { 
  margin-left: auto;
  width: 144px;
  margin-right: 10px;
  height: 35px;
  }
  
  .flowRow_ChuterUnOTScreen_elRetour_467334 { 
  }
  
}

.ChuterUnOTScreen .elValider button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.ChuterUnOTScreen .elValider button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .layoutFlow > .elValider {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: 10px;
  }
  .ChuterUnOTScreen > .layoutFlow > .elValider > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .ChuterUnOTScreen > .layoutFlow > .elValider {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: 10px;
  }
  .ChuterUnOTScreen > .layoutFlow > .elValider > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .layoutFlow > .elValider {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: 10px;
  }
  .ChuterUnOTScreen > .layoutFlow > .elValider > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .layoutFlow > .elValider {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: 10px;
  }
  .ChuterUnOTScreen > .layoutFlow > .elValider > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .screenFgContainer > .foreground > .elBig_image {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
@media (min-width: 568px) {
  .ChuterUnOTScreen > .screenFgContainer > .foreground > .elBig_image {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .screenFgContainer > .foreground > .elBig_image {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .screenFgContainer > .foreground > .elBig_image {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}


/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .screenFgContainer > .foreground > .elCommentaire_fg {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ChuterUnOTScreen > .screenFgContainer > .foreground > .elCommentaire_fg {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .screenFgContainer > .foreground > .elCommentaire_fg {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .screenFgContainer > .foreground > .elCommentaire_fg {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.ChuterUnOTScreen .elSave_input {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ChuterUnOTScreen .elSave_input:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1250);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ChuterUnOTScreen > .screenFgContainer > .foreground > .elSave_input {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .ChuterUnOTScreen > .screenFgContainer > .foreground > .elSave_input {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .ChuterUnOTScreen > .screenFgContainer > .foreground > .elSave_input {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .ChuterUnOTScreen > .screenFgContainer > .foreground > .elSave_input {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ScannerScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
@media (min-width: 568px) {
  .ScannerScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
}
@media (min-width: 768px) {
  .ScannerScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}
@media (min-width: 1024px) {
  .ScannerScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}


/* Narrowest size for this element's responsive layout */
  .ScannerScreen > .screenFgContainer > .foreground > .elScanner {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -100.0px);
  }
@media (min-width: 568px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elScanner {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -100.0px);
  }
}
@media (min-width: 768px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elScanner {
    left: 0px;
    right: 0px;
    top: 75px;
    width: 100.0%;
    height: calc(100% + 0.0% + -124.7px);
  }
}
@media (min-width: 1024px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elScanner {
    left: 0px;
    right: 0px;
    top: 75px;
    width: 100.0%;
    height: calc(100% + 0.0% + -124.7px);
  }
}

.ScannerScreen .elCapture {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.ScannerScreen .elCapture:focus {
  box-shadow: 0 0 5px rgba(0, 223, 208, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ScannerScreen > .screenFgContainer > .foreground > .elCapture {
    line-height: 40px;
    position: fixed;
    left: calc(50.0% + 0px - 50px);
    bottom: 5px;
    width: 100px;
    height: 40px;
  }
@media (min-width: 568px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elCapture {
    line-height: 40px;
    left: calc(50.0% + 0px - 50px);
    bottom: 5px;
    width: 100px;
    height: 40px;
  }
}
@media (min-width: 768px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elCapture {
    line-height: 40px;
    left: calc(50.0% + 0px - 50px);
    bottom: 5px;
    width: 99px;
    height: 40px;
  }
}
@media (min-width: 1024px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elCapture {
    line-height: 40px;
    left: calc(50.0% + 0px - 50px);
    bottom: 5px;
    width: 99px;
    height: 40px;
  }
}

.ScannerScreen .elIcon_back {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.ScannerScreen .elIcon_back:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ScannerScreen > .screenFgContainer > .foreground > .elIcon_back {
    position: fixed;
    left: 5px;
    bottom: 5px;
    width: 41px;
    height: 40px;
  }
@media (min-width: 568px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elIcon_back {
    left: 5px;
    bottom: 5px;
    width: 41px;
    height: 40px;
  }
}
@media (min-width: 768px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elIcon_back {
    left: 5px;
    bottom: 5px;
    width: 41px;
    height: 40px;
  }
}
@media (min-width: 1024px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elIcon_back {
    left: 5px;
    bottom: 5px;
    width: 41px;
    height: 40px;
  }
}

.ScannerScreen .elIconList {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.ScannerScreen .elIconList:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ScannerScreen > .screenFgContainer > .foreground > .elIconList {
    position: fixed;
    right: 5px;
    bottom: 5px;
    width: 41px;
    height: 40px;
  }
@media (min-width: 568px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elIconList {
    right: 5px;
    bottom: 5px;
    width: 41px;
    height: 40px;
  }
}
@media (min-width: 768px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elIconList {
    right: 5px;
    bottom: 5px;
    width: 41px;
    height: 40px;
  }
}
@media (min-width: 1024px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elIconList {
    right: 5px;
    bottom: 5px;
    width: 41px;
    height: 40px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ScannerScreen > .screenFgContainer > .foreground > .elProgress {
    position: absolute;
    left: calc(50.0% + 0px - 24px);
    top: 200px;
    width: 48px;
    height: 48px;
  }
@media (min-width: 568px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 24px);
    top: 200px;
    width: 48px;
    height: 48px;
  }
}
@media (min-width: 768px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 24px);
    top: 199px;
    width: 47px;
    height: 47px;
  }
}
@media (min-width: 1024px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 0px - 24px);
    top: 199px;
    width: 47px;
    height: 47px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ScannerScreen > .screenFgContainer > .foreground > .elSearch {
    position: absolute;
    left: 50px;
    right: 50px;
    top: 50px;
    width: calc(100.0% - 50px - 50px);
    height: 34px;
  }
@media (min-width: 568px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elSearch {
    left: 50px;
    right: 50px;
    top: 50px;
    width: calc(100.0% - 50px - 50px);
    height: 34px;
  }
}
@media (min-width: 768px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elSearch {
    left: 50px;
    right: 50px;
    top: 50px;
    width: calc(100.0% - 50px - 50px);
    height: 34px;
  }
}
@media (min-width: 1024px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elSearch {
    left: 50px;
    right: 50px;
    top: 50px;
    width: calc(100.0% - 50px - 50px);
    height: 34px;
  }
}

.ScannerScreen .elList_immatriculations {
  overflow: auto;
  pointer-events: auto;
}



/* Narrowest size for this element's responsive layout */
  .ScannerScreen > .screenFgContainer > .foreground > .elList_immatriculations {
    position: absolute;
    left: 50px;
    right: 50px;
    top: 90px;
    width: calc(100.0% - 50px - 50px);
    height: calc(100% + 0.0% + -140.0px);
  }
@media (min-width: 568px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elList_immatriculations {
    left: 50px;
    right: 50px;
    top: 90px;
    width: calc(100.0% - 50px - 50px);
    height: calc(100% + 0.0% + -140.0px);
  }
}
@media (min-width: 768px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elList_immatriculations {
    left: 50px;
    right: 50px;
    top: 89px;
    width: calc(100.0% - 50px - 50px);
    height: calc(100% + 0.0% + -139.1px);
  }
}
@media (min-width: 1024px) {
  .ScannerScreen > .screenFgContainer > .foreground > .elList_immatriculations {
    left: 50px;
    right: 50px;
    top: 89px;
    width: calc(100.0% - 50px - 50px);
    height: calc(100% + 0.0% + -139.1px);
  }
}

/* This component has a fixed-size layout */
  .Action {
    min-height: 40px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 111px */
    height: 100%;
  }
  .Action > .background > .containerMinHeight {
    min-height: 40px;
  }


  .Action > .foreground > .state0_elAction {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Action {
    min-height: 40px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 111px */
    height: 100%;
  }
  .Action > .background > .containerMinHeight {
    min-height: 40px;
  }


  .Action > .foreground > .state1_elAction {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Action {
    min-height: 40px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 111px */
    height: 100%;
  }
  .Action > .background > .containerMinHeight {
    min-height: 40px;
  }


  .Action > .foreground > .state2_elAction {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Action {
    min-height: 40px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 111px */
    height: 100%;
  }
  .Action > .background > .containerMinHeight {
    min-height: 40px;
  }


  .Action > .foreground > .state3_elAction {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Action {
    min-height: 40px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 111px */
    height: 100%;
  }
  .Action > .background > .containerMinHeight {
    min-height: 40px;
  }


  .Action > .foreground > .state4_elAction {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Action {
    min-height: 40px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 111px */
    height: 100%;
  }
  .Action > .background > .containerMinHeight {
    min-height: 40px;
  }


  .Action > .foreground > .state5_elAction {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Action {
    min-height: 40px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 111px */
    height: 100%;
  }
  .Action > .background > .containerMinHeight {
    min-height: 40px;
  }


  .Action > .foreground > .state6_elAction {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .background > .state0_elBackground1042437 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .DétailVidageScreen > .background > .state0_elBackground1042437 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .DétailVidageScreen > .background > .state0_elBackground1042437 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .background > .state0_elBackground1042437 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer831938 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: calc(50.0% - 5px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer831938 > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer831938 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: calc(50.0% - 5px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer831938 > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer831938 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: calc(50.0% - 5px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer831938 > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer831938 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: calc(50.0% - 5px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer831938 > * { 
  width: 10px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .state0_elExutoireCopy {
    position: relative;
    margin-top: 10px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elExutoireCopy > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .state0_elExutoireCopy {
    position: relative;
    margin-top: 10px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elExutoireCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .state0_elExutoireCopy {
    position: relative;
    margin-top: 10px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elExutoireCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .state0_elExutoireCopy {
    position: relative;
    margin-top: 10px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elExutoireCopy > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .state0_elRectangle1463615537 {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elRectangle1463615537 > * { 
    width: 100%;
  height: 1px;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .state0_elRectangle1463615537 {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elRectangle1463615537 > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .state0_elRectangle1463615537 {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elRectangle1463615537 > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .state0_elRectangle1463615537 {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elRectangle1463615537 > * { 
    width: 100%;
  height: 1px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elId_scan1649174831 {
    position: absolute;
    margin-top: 5px;
    width: 60px;
    margin-left: 50.0%;
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elId_scan1649174831 > * { 
  width: 60px;
  }
  
  .flowRow_DétailVidageScreen_state0_elId_scan1649174831_1649174831 { 
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elId_scan1649174831 {
    position: absolute;
    margin-top: 5px;
    width: 60px;
    margin-left: 50.0%;
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elId_scan1649174831 > * { 
  width: 60px;
  }
  
  .flowRow_DétailVidageScreen_state0_elId_scan1649174831_1649174831 { 
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elId_scan1649174831 {
    position: absolute;
    margin-top: 5px;
    width: 60px;
    margin-left: 50.0%;
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elId_scan1649174831 > * { 
  width: 60px;
  }
  
  .flowRow_DétailVidageScreen_state0_elId_scan1649174831_1649174831 { 
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elId_scan1649174831 {
    position: absolute;
    margin-top: 5px;
    width: 60px;
    margin-left: 50.0%;
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elId_scan1649174831 > * { 
  width: 60px;
  }
  
  .flowRow_DétailVidageScreen_state0_elId_scan1649174831_1649174831 { 
  }
  
}

.DétailVidageScreen .state0_elImage_fill_full964740336 {
  overflow: hidden;
}

.DétailVidageScreen .state0_elImage_fill_full964740336.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .state0_elImage_fill_full964740336 {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elImage_fill_full964740336 > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .DétailVidageScreen > .layoutFlow > .state0_elImage_fill_full964740336 > .background > .containerMinHeight {
    min-height: 30px;
  }

@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .state0_elImage_fill_full964740336 {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elImage_fill_full964740336 > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .DétailVidageScreen > .layoutFlow > .state0_elImage_fill_full964740336 > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .state0_elImage_fill_full964740336 {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elImage_fill_full964740336 > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .DétailVidageScreen > .layoutFlow > .state0_elImage_fill_full964740336 > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .state0_elImage_fill_full964740336 {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elImage_fill_full964740336 > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .DétailVidageScreen > .layoutFlow > .state0_elImage_fill_full964740336 > .background > .containerMinHeight {
    min-height: 30px;
  }

}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(9.4% + -10px);
    margin-right: 7.8%;
    width: calc(82.8% - -10px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCard > * { 
    width: 100%;
  height: 55px;
  }
  
  .flowRow_DétailVidageScreen_state0_elCard_1209029603 { 
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(27.1% + -10px);
    margin-right: 26.2%;
    width: calc(46.7% - -10px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCard > * { 
    width: 100%;
  height: 55px;
  }
  
  .flowRow_DétailVidageScreen_state0_elCard_1209029603 { 
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(33.1% + -10px);
    margin-right: 32.4%;
    width: calc(34.5% - -10px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCard > * { 
    width: 100%;
  height: 55px;
  }
  
  .flowRow_DétailVidageScreen_state0_elCard_1209029603 { 
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(37.3% + -10px);
    margin-right: 36.8%;
    width: calc(25.9% - -10px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCard > * { 
    width: 100%;
  height: 55px;
  }
  
  .flowRow_DétailVidageScreen_state0_elCard_1209029603 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elExutoire_select {
    position: absolute;
    top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elExutoire_select > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elExutoire_select {
    position: absolute;
    top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elExutoire_select > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elExutoire_select {
    position: absolute;
    top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elExutoire_select > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elExutoire_select {
    position: absolute;
    top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elExutoire_select > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 70px;
  }
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer2 > * { 
  width: 10px;
  height: 70px;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 70px;
  }
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer2 > * { 
  width: 10px;
  height: 70px;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 70px;
  }
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer2 > * { 
  width: 10px;
  height: 70px;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 70px;
  }
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer2 > * { 
  width: 10px;
  height: 70px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCard1 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(9.4% + -10px);
    margin-right: 7.8%;
    width: calc(82.8% - -10px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCard1 > * { 
    width: 100%;
  height: 210px;
  }
  
  .flowRow_DétailVidageScreen_state0_elCard1_1027341795 { 
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCard1 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(27.1% + -10px);
    margin-right: 26.2%;
    width: calc(46.7% - -10px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCard1 > * { 
    width: 100%;
  height: 210px;
  }
  
  .flowRow_DétailVidageScreen_state0_elCard1_1027341795 { 
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCard1 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(33.1% + -10px);
    margin-right: 32.4%;
    width: calc(34.5% - -10px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCard1 > * { 
    width: 100%;
  height: 209px;
  }
  
  .flowRow_DétailVidageScreen_state0_elCard1_1027341795 { 
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCard1 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(37.3% + -10px);
    margin-right: 36.8%;
    width: calc(25.9% - -10px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCard1 > * { 
    width: 100%;
  height: 209px;
  }
  
  .flowRow_DétailVidageScreen_state0_elCard1_1027341795 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elImage {
    position: absolute;
    top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elImage > * { 
    width: 100%;
  height: 100px;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elImage {
    position: absolute;
    top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elImage > * { 
    width: 100%;
  height: 100px;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elImage {
    position: absolute;
    top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elImage > * { 
    width: 100%;
  height: 100px;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elImage {
    position: absolute;
    top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elImage > * { 
    width: 100%;
  height: 100px;
  }
  
}

.DétailVidageScreen .state0_elErase button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.DétailVidageScreen .state0_elErase button:focus {
  box-shadow: 0 0 6px rgba(255, 129, 120, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 9.4%;
  height: 30px;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 27.1%;
  height: 30px;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 33.1%;
  height: 30px;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 37.3%;
  height: 30px;
  }
  
}

.DétailVidageScreen .state0_elCapture button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.DétailVidageScreen .state0_elCapture button:focus {
  box-shadow: 0 0 6px rgba(0, 226, 217, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCapture {
    position: absolute;
    top: 20px;
    width: 140px;
    margin-left: calc(50.0% - 70px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCapture > * { 
  width: 140px;
  height: 100px;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCapture {
    position: absolute;
    top: 20px;
    width: 140px;
    margin-left: calc(50.0% - 70px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCapture > * { 
  width: 140px;
  height: 100px;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCapture {
    position: absolute;
    top: 20px;
    width: 139px;
    margin-left: calc(50.0% - 70px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCapture > * { 
  width: 139px;
  height: 99px;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCapture {
    position: absolute;
    top: 20px;
    width: 139px;
    margin-left: calc(50.0% - 70px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCapture > * { 
  width: 139px;
  height: 99px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elTonnage {
    position: absolute;
    top: 130px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elTonnage > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elTonnage {
    position: absolute;
    top: 130px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elTonnage > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elTonnage {
    position: absolute;
    top: 129px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elTonnage > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elTonnage {
    position: absolute;
    top: 129px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elTonnage > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCommentaire {
    position: absolute;
    top: 175px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCommentaire > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCommentaire {
    position: absolute;
    top: 175px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCommentaire > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCommentaire {
    position: absolute;
    top: 174px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCommentaire > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCommentaire {
    position: absolute;
    top: 174px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state0_elCommentaire > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer3 > * { 
  width: 10px;
  height: 220px;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer3 > * { 
  width: 10px;
  height: 220px;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer3 > * { 
  width: 10px;
  height: 220px;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .DétailVidageScreen > .layoutFlow > .state0_elSpacer3 > * { 
  width: 10px;
  height: 220px;
  }
  
}

.DétailVidageScreen .state0_elValider button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.DétailVidageScreen .state0_elValider button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .state0_elValider {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 150px;
    margin-left: calc(50.0% - 75px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elValider > * { 
  width: 150px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .state0_elValider {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 150px;
    margin-left: calc(50.0% - 75px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elValider > * { 
  width: 150px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .state0_elValider {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 149px;
    margin-left: calc(50.0% - 74px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elValider > * { 
  width: 149px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .state0_elValider {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 149px;
    margin-left: calc(50.0% - 74px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elValider > * { 
  width: 149px;
  height: 35px;
  }
  
}

.DétailVidageScreen .state0_elVidage_diff button {
  cursor: pointer;
  border: 0.9px solid #00e5d9;
  border-radius: 3.0px;
}

.DétailVidageScreen .state0_elVidage_diff button:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .state0_elVidage_diff {
    position: relative;
    margin-top: 20px;
    width: 150px;
    margin-left: calc(50.0% - 75px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elVidage_diff > * { 
  width: 150px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .state0_elVidage_diff {
    position: relative;
    margin-top: 20px;
    width: 150px;
    margin-left: calc(50.0% - 75px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elVidage_diff > * { 
  width: 150px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .state0_elVidage_diff {
    position: relative;
    margin-top: 20px;
    width: 149px;
    margin-left: calc(50.0% - 74px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elVidage_diff > * { 
  width: 149px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .state0_elVidage_diff {
    position: relative;
    margin-top: 20px;
    width: 149px;
    margin-left: calc(50.0% - 74px);
  }
  .DétailVidageScreen > .layoutFlow > .state0_elVidage_diff > * { 
  width: 149px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elBig_image {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
@media (min-width: 568px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elBig_image {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
}
@media (min-width: 768px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elBig_image {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elBig_image {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}

.DétailVidageScreen .state0_elCancel1509691112 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.DétailVidageScreen .state0_elCancel1509691112:focus {
  box-shadow: 0 0 6px rgba(255, 129, 120, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elCancel1509691112 {
    line-height: 35px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elCancel1509691112 {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elCancel1509691112 {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elCancel1509691112 {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}

.DétailVidageScreen .state0_elShow_apercu212082506 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.DétailVidageScreen .state0_elShow_apercu212082506:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elShow_apercu212082506 {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elShow_apercu212082506 {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elShow_apercu212082506 {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elShow_apercu212082506 {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elNotif815668831 {
    overflow: hidden;
    position: fixed;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
@media (min-width: 568px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elNotif815668831 {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 768px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elNotif815668831 {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elNotif815668831 {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}

.DétailVidageScreen .state0_elApercu245793648 {
  overflow: hidden;
  pointer-events: auto;
}

.DétailVidageScreen .state0_elApercu245793648.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elApercu245793648 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elApercu245793648 > .background > .containerMinHeight {
    min-height: 300px;
  }

@media (min-width: 568px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elApercu245793648 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elApercu245793648 > .background > .containerMinHeight {
    min-height: 300px;
  }

}
@media (min-width: 768px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elApercu245793648 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elApercu245793648 > .background > .containerMinHeight {
    min-height: 299px;
  }

}
@media (min-width: 1024px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elApercu245793648 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elApercu245793648 > .background > .containerMinHeight {
    min-height: 299px;
  }

}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elTonnage_fg {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elTonnage_fg {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elTonnage_fg {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elTonnage_fg {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elCommentaire_fg {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elCommentaire_fg {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elCommentaire_fg {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elCommentaire_fg {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.DétailVidageScreen .state0_elSave_input {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.DétailVidageScreen .state0_elSave_input:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1250);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elSave_input {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elSave_input {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elSave_input {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state0_elSave_input {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .background > .state1_elBackground1042437 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .DétailVidageScreen > .background > .state1_elBackground1042437 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .DétailVidageScreen > .background > .state1_elBackground1042437 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .background > .state1_elBackground1042437 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .state1_elSpacer831938 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: calc(50.0% - 5px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elSpacer831938 > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .state1_elSpacer831938 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: calc(50.0% - 5px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elSpacer831938 > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .state1_elSpacer831938 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: calc(50.0% - 5px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elSpacer831938 > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .state1_elSpacer831938 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: calc(50.0% - 5px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elSpacer831938 > * { 
  width: 10px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .state1_elExutoireCopy {
    position: relative;
    margin-top: 10px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elExutoireCopy > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .state1_elExutoireCopy {
    position: relative;
    margin-top: 10px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elExutoireCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .state1_elExutoireCopy {
    position: relative;
    margin-top: 10px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elExutoireCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .state1_elExutoireCopy {
    position: relative;
    margin-top: 10px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elExutoireCopy > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .state1_elRectangle1463615537 {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elRectangle1463615537 > * { 
    width: 100%;
  height: 1px;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .state1_elRectangle1463615537 {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elRectangle1463615537 > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .state1_elRectangle1463615537 {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elRectangle1463615537 > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .state1_elRectangle1463615537 {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elRectangle1463615537 > * { 
    width: 100%;
  height: 1px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .flowRow > .state1_elId_scan1649174831 {
    position: absolute;
    margin-top: 5px;
    width: 60px;
    margin-left: 50.0%;
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state1_elId_scan1649174831 > * { 
  width: 60px;
  }
  
  .flowRow_DétailVidageScreen_state1_elId_scan1649174831_1649174831 { 
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state1_elId_scan1649174831 {
    position: absolute;
    margin-top: 5px;
    width: 60px;
    margin-left: 50.0%;
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state1_elId_scan1649174831 > * { 
  width: 60px;
  }
  
  .flowRow_DétailVidageScreen_state1_elId_scan1649174831_1649174831 { 
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state1_elId_scan1649174831 {
    position: absolute;
    margin-top: 5px;
    width: 60px;
    margin-left: 50.0%;
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state1_elId_scan1649174831 > * { 
  width: 60px;
  }
  
  .flowRow_DétailVidageScreen_state1_elId_scan1649174831_1649174831 { 
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .flowRow > .state1_elId_scan1649174831 {
    position: absolute;
    margin-top: 5px;
    width: 60px;
    margin-left: 50.0%;
  }
  .DétailVidageScreen > .layoutFlow > .flowRow > .state1_elId_scan1649174831 > * { 
  width: 60px;
  }
  
  .flowRow_DétailVidageScreen_state1_elId_scan1649174831_1649174831 { 
  }
  
}

.DétailVidageScreen .state1_elImage_fill_full964740336 {
  overflow: hidden;
}

.DétailVidageScreen .state1_elImage_fill_full964740336.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .state1_elImage_fill_full964740336 {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elImage_fill_full964740336 > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .DétailVidageScreen > .layoutFlow > .state1_elImage_fill_full964740336 > .background > .containerMinHeight {
    min-height: 30px;
  }

@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .state1_elImage_fill_full964740336 {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elImage_fill_full964740336 > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .DétailVidageScreen > .layoutFlow > .state1_elImage_fill_full964740336 > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .state1_elImage_fill_full964740336 {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elImage_fill_full964740336 > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .DétailVidageScreen > .layoutFlow > .state1_elImage_fill_full964740336 > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .state1_elImage_fill_full964740336 {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: calc(50.0% - 50px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elImage_fill_full964740336 > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .DétailVidageScreen > .layoutFlow > .state1_elImage_fill_full964740336 > .background > .containerMinHeight {
    min-height: 30px;
  }

}

.DétailVidageScreen .state1_elTransfert button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.DétailVidageScreen .state1_elTransfert button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .layoutFlow > .state1_elTransfert {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elTransfert > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .DétailVidageScreen > .layoutFlow > .state1_elTransfert {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elTransfert > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .DétailVidageScreen > .layoutFlow > .state1_elTransfert {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elTransfert > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .layoutFlow > .state1_elTransfert {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .DétailVidageScreen > .layoutFlow > .state1_elTransfert > * { 
  width: 144px;
  height: 35px;
  }
  
}

.DétailVidageScreen .state1_elCancel1509691112 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.DétailVidageScreen .state1_elCancel1509691112:focus {
  box-shadow: 0 0 6px rgba(255, 129, 120, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elCancel1509691112 {
    line-height: 35px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elCancel1509691112 {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elCancel1509691112 {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elCancel1509691112 {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}

.DétailVidageScreen .state1_elShow_apercu212082506 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.DétailVidageScreen .state1_elShow_apercu212082506:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elShow_apercu212082506 {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elShow_apercu212082506 {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elShow_apercu212082506 {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elShow_apercu212082506 {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elNotif815668831 {
    overflow: hidden;
    position: fixed;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
@media (min-width: 568px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elNotif815668831 {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 768px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elNotif815668831 {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 1024px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elNotif815668831 {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}

.DétailVidageScreen .state1_elApercu245793648 {
  overflow: hidden;
  pointer-events: auto;
}

.DétailVidageScreen .state1_elApercu245793648.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elApercu245793648 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elApercu245793648 > .background > .containerMinHeight {
    min-height: 300px;
  }

@media (min-width: 568px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elApercu245793648 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elApercu245793648 > .background > .containerMinHeight {
    min-height: 300px;
  }

}
@media (min-width: 768px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elApercu245793648 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elApercu245793648 > .background > .containerMinHeight {
    min-height: 299px;
  }

}
@media (min-width: 1024px) {
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elApercu245793648 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DétailVidageScreen > .screenFgContainer > .foreground > .state1_elApercu245793648 > .background > .containerMinHeight {
    min-height: 299px;
  }

}

/* This component has a fixed-size layout */
  .Play {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Play > .background > .containerMinHeight {
    min-height: 30px;
  }

.Play .state0_elCharge {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Play .state0_elCharge:focus {
  box-shadow: 0 0 5px rgba(107, 245, 120, 0.5000);
  outline: none;
}



  .Play > .foreground > .state0_elCharge {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 30px;
    height: 30px;
  }

/* This component has a fixed-size layout */
  .Play {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Play > .background > .containerMinHeight {
    min-height: 30px;
  }


  .Play > .foreground > .state1_elEllipse171166065 {
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    width: calc(100.0% - 1px - 1px);
    height: calc(100% + 0.0% + -2.0px);
  }


  .Play > .foreground > .state1_elV751851499633 {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 15px;
    height: 15px;
  }


  .Play > .foreground > .state1_elV501345751315 {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 15px;
    height: 15px;
  }


  .Play > .foreground > .state1_elV25 {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 15px;
    height: 15px;
  }

/* This component has a fixed-size layout */
  .Play {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Play > .background > .containerMinHeight {
    min-height: 30px;
  }


  .Play > .foreground > .state2_elEllipse171166065 {
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    width: calc(100.0% - 1px - 1px);
    height: calc(100% + 0.0% + -2.0px);
  }


  .Play > .foreground > .state2_elV751851499633 {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 15px;
    height: 15px;
  }


  .Play > .foreground > .state2_elV501345751315 {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 15px;
    height: 15px;
  }

/* This component has a fixed-size layout */
  .Play {
    min-height: 30px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 30px */
    height: 100%;
  }
  .Play > .background > .containerMinHeight {
    min-height: 30px;
  }


  .Play > .foreground > .state3_elEllipse171166065 {
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    width: calc(100.0% - 1px - 1px);
    height: calc(100% + 0.0% + -2.0px);
  }


  .Play > .foreground > .state3_elV751851499633 {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 15px;
    height: 15px;
  }

/* This component has a fixed-size layout */
  .Item_immatriculation {
    width: 100%; /* This component was designed using a width of 182px */
    height: 100%;
  }


  .Item_immatriculation > .background > .elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 1px;
  }


  .Item_immatriculation > .layoutFlow > .elText {
    position: relative;
    margin-top: 10px;
    width: 70px;
    margin-left: 0px;
  }
  .Item_immatriculation > .layoutFlow > .elText > * { 
  width: 70px;
  }
  


  .Item_immatriculation > .layoutFlow > .elDecription {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Item_immatriculation > .layoutFlow > .elDecription > * { 
    width: 100%;
  }
  


  .Item_immatriculation > .layoutFlow > .elType {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Item_immatriculation > .layoutFlow > .elType > * { 
    width: 100%;
  }
  


  .Item_immatriculation > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .Item_immatriculation > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  


/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .AnomalieScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .AnomalieScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .AnomalieScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .AnomalieScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .AnomalieScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .AnomalieScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .AnomalieScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .elTitle {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .elTitle {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .elTitle {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .elTitle {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(9.4% + -10px);
    margin-right: 7.8%;
    width: calc(82.8% - -10px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 120px;
  }
  
  .flowRow_AnomalieScreen_elCard_1344004846 { 
  }
  
@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(27.1% + -10px);
    margin-right: 26.2%;
    width: calc(46.7% - -10px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 120px;
  }
  
  .flowRow_AnomalieScreen_elCard_1344004846 { 
  }
  
}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(33.1% + -10px);
    margin-right: 32.4%;
    width: calc(34.5% - -10px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 119px;
  }
  
  .flowRow_AnomalieScreen_elCard_1344004846 { 
  }
  
}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(37.3% + -10px);
    margin-right: 36.8%;
    width: calc(25.9% - -10px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 119px;
  }
  
  .flowRow_AnomalieScreen_elCard_1344004846 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .flowRow > .elPicto_benne {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elPicto_benne > * { 
  margin-left: auto;
  width: 100px;
  margin-right: 9.4%;
  height: 100px;
  }
  
@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elPicto_benne {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elPicto_benne > * { 
  margin-left: auto;
  width: 100px;
  margin-right: 27.1%;
  height: 100px;
  }
  
}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elPicto_benne {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elPicto_benne > * { 
  margin-left: auto;
  width: 100px;
  margin-right: 33.1%;
  height: 100px;
  }
  
}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elPicto_benne {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elPicto_benne > * { 
  margin-left: auto;
  width: 100px;
  margin-right: 37.3%;
  height: 100px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .flowRow > .elId_scan {
    position: absolute;
    top: 20px;
    margin-left: 9.4%;
    margin-right: 25.0%;
    width: calc(65.6% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elId_scan > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elId_scan {
    position: absolute;
    top: 20px;
    margin-left: 27.1%;
    margin-right: 35.9%;
    width: calc(37.0% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elId_scan > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elId_scan {
    position: absolute;
    top: 20px;
    margin-left: 33.1%;
    margin-right: 39.5%;
    width: calc(27.4% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elId_scan > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elId_scan {
    position: absolute;
    top: 20px;
    margin-left: 37.3%;
    margin-right: 42.2%;
    width: calc(20.5% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elId_scan > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .flowRow > .elType {
    position: absolute;
    top: 45px;
    margin-left: 9.4%;
    margin-right: 25.0%;
    width: calc(65.6% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elType > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elType {
    position: absolute;
    top: 45px;
    margin-left: 27.1%;
    margin-right: 35.9%;
    width: calc(37.0% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elType > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elType {
    position: absolute;
    top: 44px;
    margin-left: 33.1%;
    margin-right: 39.5%;
    width: calc(27.4% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elType > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elType {
    position: absolute;
    top: 45px;
    margin-left: 37.3%;
    margin-right: 42.2%;
    width: calc(20.5% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elType > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .flowRow > .elDescription {
    position: absolute;
    top: 70px;
    margin-left: 9.4%;
    margin-right: 25.0%;
    width: calc(65.6% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elDescription > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elDescription {
    position: absolute;
    top: 70px;
    margin-left: 27.1%;
    margin-right: 35.9%;
    width: calc(37.0% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elDescription > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elDescription {
    position: absolute;
    top: 70px;
    margin-left: 33.1%;
    margin-right: 39.5%;
    width: calc(27.4% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elDescription > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elDescription {
    position: absolute;
    top: 70px;
    margin-left: 37.3%;
    margin-right: 42.2%;
    width: calc(20.5% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elDescription > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .AnomalieScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 130px;
  }
  
@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .AnomalieScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 130px;
  }
  
}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .AnomalieScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 130px;
  }
  
}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .AnomalieScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 130px;
  }
  
}

.AnomalieScreen .elComp {
  overflow: hidden;
}

.AnomalieScreen .elComp.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .elComp {
    position: relative;
    margin-top: 0px;
    margin-left: calc(9.4% + -10px);
    margin-right: 7.8%;
    width: calc(82.8% - -10px);
  }
  .AnomalieScreen > .layoutFlow > .elComp > * { 
    width: 100%;
  }
  
  .AnomalieScreen > .layoutFlow > .elComp > .background > .containerMinHeight {
    min-height: 293px;
  }

@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .elComp {
    position: relative;
    margin-top: 0px;
    margin-left: calc(27.1% + -10px);
    margin-right: 26.2%;
    width: calc(46.7% - -10px);
  }
  .AnomalieScreen > .layoutFlow > .elComp > * { 
    width: 100%;
  }
  
  .AnomalieScreen > .layoutFlow > .elComp > .background > .containerMinHeight {
    min-height: 293px;
  }

}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .elComp {
    position: relative;
    margin-top: 0px;
    margin-left: calc(33.1% + -10px);
    margin-right: 32.4%;
    width: calc(34.5% - -10px);
  }
  .AnomalieScreen > .layoutFlow > .elComp > * { 
    width: 100%;
  }
  
  .AnomalieScreen > .layoutFlow > .elComp > .background > .containerMinHeight {
    min-height: 291px;
  }

}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .elComp {
    position: relative;
    margin-top: 0px;
    margin-left: calc(37.3% + -10px);
    margin-right: 36.8%;
    width: calc(25.9% - -10px);
  }
  .AnomalieScreen > .layoutFlow > .elComp > * { 
    width: 100%;
  }
  
  .AnomalieScreen > .layoutFlow > .elComp > .background > .containerMinHeight {
    min-height: 291px;
  }

}


/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .flowRow > .elCard3 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(9.4% + -10px);
    margin-right: 7.8%;
    width: calc(82.8% - -10px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elCard3 > * { 
    width: 100%;
  height: 60px;
  }
  
  .flowRow_AnomalieScreen_elCard3_396353737 { 
  }
  
@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elCard3 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(27.1% + -10px);
    margin-right: 26.2%;
    width: calc(46.7% - -10px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elCard3 > * { 
    width: 100%;
  height: 60px;
  }
  
  .flowRow_AnomalieScreen_elCard3_396353737 { 
  }
  
}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elCard3 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(33.1% + -10px);
    margin-right: 32.4%;
    width: calc(34.5% - -10px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elCard3 > * { 
    width: 100%;
  height: 60px;
  }
  
  .flowRow_AnomalieScreen_elCard3_396353737 { 
  }
  
}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elCard3 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(37.3% + -10px);
    margin-right: 36.8%;
    width: calc(25.9% - -10px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elCard3 > * { 
    width: 100%;
  height: 60px;
  }
  
  .flowRow_AnomalieScreen_elCard3_396353737 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 40px;
  }
  
@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 40px;
  }
  
}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 40px;
  }
  
}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 40px;
  }
  
}

.AnomalieScreen .elCapture button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.AnomalieScreen .elCapture button:focus {
  box-shadow: 0 0 6px rgba(0, 226, 217, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elCapture > * { 
    width: 100%;
  height: 40px;
  }
  
@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elCapture > * { 
    width: 100%;
  height: 40px;
  }
  
}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elCapture > * { 
    width: 100%;
  height: 40px;
  }
  
}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elCapture > * { 
    width: 100%;
  height: 40px;
  }
  
}

.AnomalieScreen .elErase button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.AnomalieScreen .elErase button:focus {
  box-shadow: 0 0 6px rgba(255, 129, 120, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 9.4%;
  height: 30px;
  }
  
@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 27.1%;
  height: 30px;
  }
  
}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 33.1%;
  height: 30px;
  }
  
}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .AnomalieScreen > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 37.3%;
  height: 30px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .elSpacerCopy3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .AnomalieScreen > .layoutFlow > .elSpacerCopy3 > * { 
  width: 10px;
  height: 70px;
  }
  
@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .elSpacerCopy3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .AnomalieScreen > .layoutFlow > .elSpacerCopy3 > * { 
  width: 10px;
  height: 70px;
  }
  
}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .elSpacerCopy3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .AnomalieScreen > .layoutFlow > .elSpacerCopy3 > * { 
  width: 10px;
  height: 70px;
  }
  
}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .elSpacerCopy3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .AnomalieScreen > .layoutFlow > .elSpacerCopy3 > * { 
  width: 10px;
  height: 70px;
  }
  
}

.AnomalieScreen .elSave button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.AnomalieScreen .elSave button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .elSave {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .AnomalieScreen > .layoutFlow > .elSave > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .elSave {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .AnomalieScreen > .layoutFlow > .elSave > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .elSave {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .AnomalieScreen > .layoutFlow > .elSave > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .elSave {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .AnomalieScreen > .layoutFlow > .elSave > * { 
  width: 144px;
  height: 35px;
  }
  
}

.AnomalieScreen .elCancel button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.AnomalieScreen .elCancel button:focus {
  box-shadow: 0 0 5px rgba(255, 129, 120, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .elCancel {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .AnomalieScreen > .layoutFlow > .elCancel > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .elCancel {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .AnomalieScreen > .layoutFlow > .elCancel > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .elCancel {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .AnomalieScreen > .layoutFlow > .elCancel > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .elCancel {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .AnomalieScreen > .layoutFlow > .elCancel > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .layoutFlow > .elSpacerCopy4 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .AnomalieScreen > .layoutFlow > .elSpacerCopy4 > * { 
  width: 10px;
  height: 20px;
  }
  
@media (min-width: 568px) {
  .AnomalieScreen > .layoutFlow > .elSpacerCopy4 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .AnomalieScreen > .layoutFlow > .elSpacerCopy4 > * { 
  width: 10px;
  height: 20px;
  }
  
}
@media (min-width: 768px) {
  .AnomalieScreen > .layoutFlow > .elSpacerCopy4 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .AnomalieScreen > .layoutFlow > .elSpacerCopy4 > * { 
  width: 10px;
  height: 20px;
  }
  
}
@media (min-width: 1024px) {
  .AnomalieScreen > .layoutFlow > .elSpacerCopy4 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .AnomalieScreen > .layoutFlow > .elSpacerCopy4 > * { 
  width: 10px;
  height: 20px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .screenFgContainer > .foreground > .elBig_image {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
@media (min-width: 568px) {
  .AnomalieScreen > .screenFgContainer > .foreground > .elBig_image {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
}
@media (min-width: 768px) {
  .AnomalieScreen > .screenFgContainer > .foreground > .elBig_image {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}
@media (min-width: 1024px) {
  .AnomalieScreen > .screenFgContainer > .foreground > .elBig_image {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}

.AnomalieScreen .elAnomalies_explorer {
  overflow: hidden;
  pointer-events: auto;
}

.AnomalieScreen .elAnomalies_explorer.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .AnomalieScreen > .screenFgContainer > .foreground > .elAnomalies_explorer {
    position: absolute;
    left: calc(9.4% + -10px);
    right: calc(9.4% + -10px);
    top: 100px;
    width: calc(81.2% - -10px - -10px);
    height: calc(100% + 0.0% + -100.0px);
  }
  .AnomalieScreen > .screenFgContainer > .foreground > .elAnomalies_explorer > .background > .containerMinHeight {
    min-height: 250px;
  }

@media (min-width: 568px) {
  .AnomalieScreen > .screenFgContainer > .foreground > .elAnomalies_explorer {
    left: calc(27.1% + -10px);
    right: calc(27.1% + -10px);
    top: 100px;
    width: calc(45.8% - -10px - -10px);
    height: calc(100% + 0.0% + -100.0px);
  }
  .AnomalieScreen > .screenFgContainer > .foreground > .elAnomalies_explorer > .background > .containerMinHeight {
    min-height: 250px;
  }

}
@media (min-width: 768px) {
  .AnomalieScreen > .screenFgContainer > .foreground > .elAnomalies_explorer {
    left: calc(33.1% + -10px);
    right: calc(33.1% + -10px);
    top: 99px;
    width: calc(33.9% - -10px - -10px);
    height: calc(100% + 0.0% + -99.4px);
  }
  .AnomalieScreen > .screenFgContainer > .foreground > .elAnomalies_explorer > .background > .containerMinHeight {
    min-height: 249px;
  }

}
@media (min-width: 1024px) {
  .AnomalieScreen > .screenFgContainer > .foreground > .elAnomalies_explorer {
    left: calc(37.3% + -10px);
    right: calc(37.3% + -10px);
    top: 99px;
    width: calc(25.4% - -10px - -10px);
    height: calc(100% + 0.0% + -99.4px);
  }
  .AnomalieScreen > .screenFgContainer > .foreground > .elAnomalies_explorer > .background > .containerMinHeight {
    min-height: 249px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ConfirmationAnomalieScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ConfirmationAnomalieScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ConfirmationAnomalieScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ConfirmationAnomalieScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .ConfirmationAnomalieScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 180px;
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 180px;
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 178px;
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 178px;
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConfirmationAnomalieScreen > .layoutFlow > .flowRow > .elCheck {
    position: absolute;
    margin-top: 20px;
    margin-left: 50.0%;
    margin-right: 9.4%;
    width: calc(40.6% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .flowRow > .elCheck > * { 
    width: 100%;
  height: 100px;
  }
  
  .flowRow_ConfirmationAnomalieScreen_elCheck_1739166588 { 
  }
  
@media (min-width: 568px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .flowRow > .elCheck {
    position: absolute;
    margin-top: 20px;
    margin-left: 50.0%;
    margin-right: 27.1%;
    width: calc(22.9% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .flowRow > .elCheck > * { 
    width: 100%;
  height: 100px;
  }
  
  .flowRow_ConfirmationAnomalieScreen_elCheck_1739166588 { 
  }
  
}
@media (min-width: 768px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .flowRow > .elCheck {
    position: absolute;
    margin-top: 20px;
    margin-left: 50.0%;
    margin-right: 33.1%;
    width: calc(16.9% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .flowRow > .elCheck > * { 
    width: 100%;
  height: 100px;
  }
  
  .flowRow_ConfirmationAnomalieScreen_elCheck_1739166588 { 
  }
  
}
@media (min-width: 1024px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .flowRow > .elCheck {
    position: absolute;
    margin-top: 20px;
    margin-left: 50.0%;
    margin-right: 37.3%;
    width: calc(12.7% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .flowRow > .elCheck > * { 
    width: 100%;
  height: 100px;
  }
  
  .flowRow_ConfirmationAnomalieScreen_elCheck_1739166588 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConfirmationAnomalieScreen > .layoutFlow > .elProvenance {
    position: relative;
    margin-top: 20px;
    margin-left: 9.4%;
    margin-right: 50.0%;
    width: calc(40.6% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elProvenance > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elProvenance {
    position: relative;
    margin-top: 20px;
    margin-left: 27.1%;
    margin-right: 50.0%;
    width: calc(22.9% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elProvenance > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elProvenance {
    position: relative;
    margin-top: 20px;
    margin-left: 33.1%;
    margin-right: 50.0%;
    width: calc(16.9% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elProvenance > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elProvenance {
    position: relative;
    margin-top: 20px;
    margin-left: 37.3%;
    margin-right: 50.0%;
    width: calc(12.7% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elProvenance > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConfirmationAnomalieScreen > .layoutFlow > .elMatricule_txt {
    position: relative;
    margin-top: 30px;
    margin-left: 9.4%;
    margin-right: 50.0%;
    width: calc(40.6% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elMatricule_txt > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elMatricule_txt {
    position: relative;
    margin-top: 30px;
    margin-left: 27.1%;
    margin-right: 50.0%;
    width: calc(22.9% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elMatricule_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elMatricule_txt {
    position: relative;
    margin-top: 30px;
    margin-left: 33.1%;
    margin-right: 50.0%;
    width: calc(16.9% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elMatricule_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elMatricule_txt {
    position: relative;
    margin-top: 30px;
    margin-left: 37.3%;
    margin-right: 50.0%;
    width: calc(12.7% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elMatricule_txt > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConfirmationAnomalieScreen > .layoutFlow > .elMatricule {
    position: relative;
    margin-top: 5px;
    margin-left: 9.4%;
    margin-right: 50.0%;
    width: calc(40.6% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elMatricule > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elMatricule {
    position: relative;
    margin-top: 5px;
    margin-left: 27.1%;
    margin-right: 50.0%;
    width: calc(22.9% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elMatricule > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elMatricule {
    position: relative;
    margin-top: 5px;
    margin-left: 33.1%;
    margin-right: 50.0%;
    width: calc(16.9% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elMatricule > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elMatricule {
    position: relative;
    margin-top: 5px;
    margin-left: 37.3%;
    margin-right: 50.0%;
    width: calc(12.7% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elMatricule > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConfirmationAnomalieScreen > .layoutFlow > .elCommentaire_txt {
    position: relative;
    margin-top: 30px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elCommentaire_txt > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elCommentaire_txt {
    position: relative;
    margin-top: 30px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elCommentaire_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elCommentaire_txt {
    position: relative;
    margin-top: 30px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elCommentaire_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elCommentaire_txt {
    position: relative;
    margin-top: 30px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elCommentaire_txt > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConfirmationAnomalieScreen > .layoutFlow > .elCommentaire {
    position: relative;
    margin-top: 5px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elCommentaire > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elCommentaire {
    position: relative;
    margin-top: 5px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elCommentaire > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elCommentaire {
    position: relative;
    margin-top: 5px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elCommentaire > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elCommentaire {
    position: relative;
    margin-top: 5px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elCommentaire > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConfirmationAnomalieScreen > .layoutFlow > .elDate_txt {
    position: relative;
    margin-top: 30px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elDate_txt > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elDate_txt {
    position: relative;
    margin-top: 30px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elDate_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elDate_txt {
    position: relative;
    margin-top: 30px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elDate_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elDate_txt {
    position: relative;
    margin-top: 30px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elDate_txt > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConfirmationAnomalieScreen > .layoutFlow > .elDate {
    position: relative;
    margin-top: 5px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elDate > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elDate {
    position: relative;
    margin-top: 5px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elDate > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elDate {
    position: relative;
    margin-top: 5px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elDate > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elDate {
    position: relative;
    margin-top: 5px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elDate > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConfirmationAnomalieScreen > .layoutFlow > .elPhoto_txt {
    position: relative;
    margin-top: 30px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elPhoto_txt > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elPhoto_txt {
    position: relative;
    margin-top: 30px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elPhoto_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elPhoto_txt {
    position: relative;
    margin-top: 30px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elPhoto_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elPhoto_txt {
    position: relative;
    margin-top: 30px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elPhoto_txt > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConfirmationAnomalieScreen > .layoutFlow > .elImage {
    position: relative;
    margin-top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elImage > * { 
    width: 100%;
  height: 160px;
  }
  
@media (min-width: 568px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elImage {
    position: relative;
    margin-top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elImage > * { 
    width: 100%;
  height: 160px;
  }
  
}
@media (min-width: 768px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elImage {
    position: relative;
    margin-top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elImage > * { 
    width: 100%;
  height: 160px;
  }
  
}
@media (min-width: 1024px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elImage {
    position: relative;
    margin-top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elImage > * { 
    width: 100%;
  height: 160px;
  }
  
}

.ConfirmationAnomalieScreen .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.ConfirmationAnomalieScreen .elButton button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ConfirmationAnomalieScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ConfirmationAnomalieScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 180px;
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 30px;
  }
  
@media (min-width: 568px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 180px;
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 30px;
  }
  
}
@media (min-width: 768px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 178px;
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 30px;
  }
  
}
@media (min-width: 1024px) {
  .ConfirmationAnomalieScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 178px;
  }
  .ConfirmationAnomalieScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 30px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .elClient {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elClient > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elClient {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elClient > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elClient {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elClient > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elClient {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elClient > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCard_ot {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(9.4% + -10px);
    margin-right: 7.8%;
    width: calc(82.8% - -10px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCard_ot > * { 
    width: 100%;
  height: 190px;
  }
  
  .flowRow_PriseEnChargeV3Screen_elCard_ot_399491667 { 
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCard_ot {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(27.1% + -10px);
    margin-right: 26.2%;
    width: calc(46.7% - -10px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCard_ot > * { 
    width: 100%;
  height: 190px;
  }
  
  .flowRow_PriseEnChargeV3Screen_elCard_ot_399491667 { 
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCard_ot {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(33.1% + -10px);
    margin-right: 32.4%;
    width: calc(34.5% - -10px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCard_ot > * { 
    width: 100%;
  height: 189px;
  }
  
  .flowRow_PriseEnChargeV3Screen_elCard_ot_399491667 { 
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCard_ot {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(37.3% + -10px);
    margin-right: 36.8%;
    width: calc(25.9% - -10px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCard_ot > * { 
    width: 100%;
  height: 189px;
  }
  
  .flowRow_PriseEnChargeV3Screen_elCard_ot_399491667 { 
  }
  
}

.PriseEnChargeV3Screen .elImage_fill_full {
  overflow: hidden;
}

.PriseEnChargeV3Screen .elImage_fill_full.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage_fill_full {
    position: absolute;
    top: 25px;
    width: 100px;
    margin-left: calc(90.6% + -110px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage_fill_full > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage_fill_full {
    position: absolute;
    top: 25px;
    width: 100px;
    margin-left: calc(72.9% + -110px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage_fill_full > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage_fill_full {
    position: absolute;
    top: 25px;
    width: 100px;
    margin-left: calc(66.9% + -109px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage_fill_full > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage_fill_full {
    position: absolute;
    top: 25px;
    width: 100px;
    margin-left: calc(62.7% + -109px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage_fill_full > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}

.PriseEnChargeV3Screen .elScanner button {
  cursor: pointer;
  border: 0.9px solid #00e5d9;
}

.PriseEnChargeV3Screen .elScanner button:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elScanner {
    position: absolute;
    top: 20px;
    width: 80px;
    margin-left: 50.0%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elScanner > * { 
  width: 80px;
  height: 20px;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elScanner {
    position: absolute;
    top: 20px;
    width: 80px;
    margin-left: 50.0%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elScanner > * { 
  width: 80px;
  height: 20px;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elScanner {
    position: absolute;
    top: 20px;
    width: 80px;
    margin-left: 50.0%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elScanner > * { 
  width: 80px;
  height: 20px;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elScanner {
    position: absolute;
    top: 20px;
    width: 80px;
    margin-left: 50.0%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elScanner > * { 
  width: 80px;
  height: 20px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elId_scan_txt {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elId_scan_txt > * { 
  margin-left: auto;
  width: 60px;
  margin-right: 9.4%;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elId_scan_txt {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elId_scan_txt > * { 
  margin-left: auto;
  width: 60px;
  margin-right: 27.1%;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elId_scan_txt {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elId_scan_txt > * { 
  margin-left: auto;
  width: 60px;
  margin-right: 33.1%;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elId_scan_txt {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elId_scan_txt > * { 
  margin-left: auto;
  width: 60px;
  margin-right: 37.3%;
  }
  
}

.PriseEnChargeV3Screen .elPackmat_display {
  overflow: hidden;
}

.PriseEnChargeV3Screen .elPackmat_display.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elPackmat_display {
    position: absolute;
    top: 115px;
    width: 30px;
    margin-left: 50.0%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elPackmat_display > * { 
  width: 30px;
  }
  
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elPackmat_display > .background > .containerMinHeight {
    min-height: 30px;
  }

@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elPackmat_display {
    position: absolute;
    top: 115px;
    width: 30px;
    margin-left: 50.0%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elPackmat_display > * { 
  width: 30px;
  }
  
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elPackmat_display > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elPackmat_display {
    position: absolute;
    top: 115px;
    width: 30px;
    margin-left: 50.0%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elPackmat_display > * { 
  width: 30px;
  }
  
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elPackmat_display > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elPackmat_display {
    position: absolute;
    top: 114px;
    width: 30px;
    margin-left: 50.0%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elPackmat_display > * { 
  width: 30px;
  }
  
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elPackmat_display > .background > .containerMinHeight {
    min-height: 30px;
  }

}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elState {
    position: absolute;
    top: 120px;
    width: 100%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elState > * { 
  margin-left: auto;
  width: 100px;
  margin-right: 9.4%;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elState {
    position: absolute;
    top: 120px;
    width: 100%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elState > * { 
  margin-left: auto;
  width: 100px;
  margin-right: 27.1%;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elState {
    position: absolute;
    top: 120px;
    width: 100%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elState > * { 
  margin-left: auto;
  width: 99px;
  margin-right: 33.1%;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elState {
    position: absolute;
    top: 119px;
    width: 100%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elState > * { 
  margin-left: auto;
  width: 99px;
  margin-right: 37.3%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .elSite {
    position: relative;
    margin-top: 20px;
    margin-left: 9.4%;
    margin-right: 50.0%;
    width: calc(40.6% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSite > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elSite {
    position: relative;
    margin-top: 20px;
    margin-left: 27.1%;
    margin-right: 50.0%;
    width: calc(22.9% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSite > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elSite {
    position: relative;
    margin-top: 20px;
    margin-left: 33.1%;
    margin-right: 50.0%;
    width: calc(16.9% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSite > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elSite {
    position: relative;
    margin-top: 20px;
    margin-left: 37.3%;
    margin-right: 50.0%;
    width: calc(12.7% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSite > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .elFlux {
    position: relative;
    margin-top: 2px;
    margin-left: 9.4%;
    margin-right: 50.0%;
    width: calc(40.6% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elFlux > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elFlux {
    position: relative;
    margin-top: 2px;
    margin-left: 27.1%;
    margin-right: 50.0%;
    width: calc(22.9% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elFlux > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elFlux {
    position: relative;
    margin-top: 2px;
    margin-left: 33.1%;
    margin-right: 50.0%;
    width: calc(16.9% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elFlux > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elFlux {
    position: relative;
    margin-top: 2px;
    margin-left: 37.3%;
    margin-right: 50.0%;
    width: calc(12.7% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elFlux > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .elType {
    position: relative;
    margin-top: 2px;
    margin-left: 9.4%;
    margin-right: 50.0%;
    width: calc(40.6% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elType > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elType {
    position: relative;
    margin-top: 2px;
    margin-left: 27.1%;
    margin-right: 50.0%;
    width: calc(22.9% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elType > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elType {
    position: relative;
    margin-top: 2px;
    margin-left: 33.1%;
    margin-right: 50.0%;
    width: calc(16.9% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elType > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elType {
    position: relative;
    margin-top: 2px;
    margin-left: 37.3%;
    margin-right: 50.0%;
    width: calc(12.7% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elType > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .elEuxutoire {
    position: relative;
    margin-top: 2px;
    margin-left: 9.4%;
    margin-right: 50.0%;
    width: calc(40.6% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elEuxutoire > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elEuxutoire {
    position: relative;
    margin-top: 2px;
    margin-left: 27.1%;
    margin-right: 50.0%;
    width: calc(22.9% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elEuxutoire > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elEuxutoire {
    position: relative;
    margin-top: 2px;
    margin-left: 33.1%;
    margin-right: 50.0%;
    width: calc(16.9% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elEuxutoire > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elEuxutoire {
    position: relative;
    margin-top: 2px;
    margin-left: 37.3%;
    margin-right: 50.0%;
    width: calc(12.7% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elEuxutoire > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 18px;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 18px;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 18px;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 18px;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 50px;
  }
  
}

.PriseEnChargeV3Screen .elComp_list_anomalies {
  overflow: hidden;
}

.PriseEnChargeV3Screen .elComp_list_anomalies.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .elComp_list_anomalies {
    position: relative;
    margin-top: 0px;
    margin-left: calc(9.4% + -10px);
    margin-right: 7.8%;
    width: calc(82.8% - -10px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elComp_list_anomalies > * { 
    width: 100%;
  }
  
  .PriseEnChargeV3Screen > .layoutFlow > .elComp_list_anomalies > .background > .containerMinHeight {
    min-height: 293px;
  }

@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elComp_list_anomalies {
    position: relative;
    margin-top: 0px;
    margin-left: calc(27.1% + -10px);
    margin-right: 26.2%;
    width: calc(46.7% - -10px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elComp_list_anomalies > * { 
    width: 100%;
  }
  
  .PriseEnChargeV3Screen > .layoutFlow > .elComp_list_anomalies > .background > .containerMinHeight {
    min-height: 293px;
  }

}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elComp_list_anomalies {
    position: relative;
    margin-top: 0px;
    margin-left: calc(33.1% + -10px);
    margin-right: 32.4%;
    width: calc(34.5% - -10px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elComp_list_anomalies > * { 
    width: 100%;
  }
  
  .PriseEnChargeV3Screen > .layoutFlow > .elComp_list_anomalies > .background > .containerMinHeight {
    min-height: 291px;
  }

}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elComp_list_anomalies {
    position: relative;
    margin-top: 0px;
    margin-left: calc(37.3% + -10px);
    margin-right: 36.8%;
    width: calc(25.9% - -10px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elComp_list_anomalies > * { 
    width: 100%;
  }
  
  .PriseEnChargeV3Screen > .layoutFlow > .elComp_list_anomalies > .background > .containerMinHeight {
    min-height: 291px;
  }

}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCard_photo {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(9.4% + -10px);
    margin-right: 7.8%;
    width: calc(82.8% - -10px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCard_photo > * { 
    width: 100%;
  height: 60px;
  }
  
  .flowRow_PriseEnChargeV3Screen_elCard_photo_1591097439 { 
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCard_photo {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(27.1% + -10px);
    margin-right: 26.2%;
    width: calc(46.7% - -10px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCard_photo > * { 
    width: 100%;
  height: 60px;
  }
  
  .flowRow_PriseEnChargeV3Screen_elCard_photo_1591097439 { 
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCard_photo {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(33.1% + -10px);
    margin-right: 32.4%;
    width: calc(34.5% - -10px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCard_photo > * { 
    width: 100%;
  height: 60px;
  }
  
  .flowRow_PriseEnChargeV3Screen_elCard_photo_1591097439 { 
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCard_photo {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(37.3% + -10px);
    margin-right: 36.8%;
    width: calc(25.9% - -10px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCard_photo > * { 
    width: 100%;
  height: 60px;
  }
  
  .flowRow_PriseEnChargeV3Screen_elCard_photo_1591097439 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 40px;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 40px;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 40px;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 40px;
  }
  
}

.PriseEnChargeV3Screen .elErase button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.PriseEnChargeV3Screen .elErase button:focus {
  box-shadow: 0 0 6px rgba(255, 129, 120, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 9.4%;
  height: 30px;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 27.1%;
  height: 30px;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 33.1%;
  height: 30px;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 37.3%;
  height: 30px;
  }
  
}

.PriseEnChargeV3Screen .elCapture button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.PriseEnChargeV3Screen .elCapture button:focus {
  box-shadow: 0 0 6px rgba(0, 226, 217, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCapture > * { 
    width: 100%;
  height: 40px;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCapture > * { 
    width: 100%;
  height: 40px;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCapture > * { 
    width: 100%;
  height: 40px;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .flowRow > .elCapture > * { 
    width: 100%;
  height: 40px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 60px;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 60px;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 60px;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 60px;
  }
  
}

.PriseEnChargeV3Screen .elValid button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.PriseEnChargeV3Screen .elValid button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .elValid {
    line-height: 35px;
    position: relative;
    margin-top: 30px;
    width: 155px;
    margin-left: calc(50.0% - 78px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elValid > * { 
  width: 155px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elValid {
    line-height: 35px;
    position: relative;
    margin-top: 30px;
    width: 155px;
    margin-left: calc(50.0% - 78px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elValid > * { 
  width: 155px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elValid {
    line-height: 35px;
    position: relative;
    margin-top: 30px;
    width: 154px;
    margin-left: calc(50.0% - 77px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elValid > * { 
  width: 154px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elValid {
    line-height: 35px;
    position: relative;
    margin-top: 30px;
    width: 154px;
    margin-left: calc(50.0% - 77px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elValid > * { 
  width: 154px;
  height: 35px;
  }
  
}

.PriseEnChargeV3Screen .elAdd_to_tour button {
  cursor: pointer;
  border: 0.9px solid #00e5d9;
  border-radius: 3.0px;
}

.PriseEnChargeV3Screen .elAdd_to_tour button:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .elAdd_to_tour {
    position: relative;
    margin-top: 20px;
    width: 155px;
    margin-left: calc(50.0% - 78px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elAdd_to_tour > * { 
  width: 155px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elAdd_to_tour {
    position: relative;
    margin-top: 20px;
    width: 155px;
    margin-left: calc(50.0% - 78px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elAdd_to_tour > * { 
  width: 155px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elAdd_to_tour {
    position: relative;
    margin-top: 20px;
    width: 154px;
    margin-left: calc(50.0% - 77px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elAdd_to_tour > * { 
  width: 154px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elAdd_to_tour {
    position: relative;
    margin-top: 20px;
    width: 154px;
    margin-left: calc(50.0% - 77px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elAdd_to_tour > * { 
  width: 154px;
  height: 35px;
  }
  
}

.PriseEnChargeV3Screen .elValidCopy button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.PriseEnChargeV3Screen .elValidCopy button:focus {
  box-shadow: 0 0 5px rgba(255, 129, 120, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .elValidCopy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 155px;
    margin-left: calc(50.0% - 78px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elValidCopy > * { 
  width: 155px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elValidCopy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 155px;
    margin-left: calc(50.0% - 78px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elValidCopy > * { 
  width: 155px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elValidCopy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 154px;
    margin-left: calc(50.0% - 77px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elValidCopy > * { 
  width: 154px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elValidCopy {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 154px;
    margin-left: calc(50.0% - 77px);
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elValidCopy > * { 
  width: 154px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 20px;
  }
  
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 20px;
  }
  
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 20px;
  }
  
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .PriseEnChargeV3Screen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 20px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elBig_image {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elBig_image {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elBig_image {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elBig_image {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}

.PriseEnChargeV3Screen .elAnomalies_explorer {
  overflow: hidden;
  pointer-events: auto;
}

.PriseEnChargeV3Screen .elAnomalies_explorer.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elAnomalies_explorer {
    position: absolute;
    left: calc(9.4% + -10px);
    right: calc(9.4% + -10px);
    top: 100px;
    width: calc(81.2% - -10px - -10px);
    height: calc(100% + 0.0% + -100.0px);
  }
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elAnomalies_explorer > .background > .containerMinHeight {
    min-height: 250px;
  }

@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elAnomalies_explorer {
    left: calc(27.1% + -10px);
    right: calc(27.1% + -10px);
    top: 100px;
    width: calc(45.8% - -10px - -10px);
    height: calc(100% + 0.0% + -100.0px);
  }
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elAnomalies_explorer > .background > .containerMinHeight {
    min-height: 250px;
  }

}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elAnomalies_explorer {
    left: calc(33.1% + -10px);
    right: calc(33.1% + -10px);
    top: 99px;
    width: calc(33.9% - -10px - -10px);
    height: calc(100% + 0.0% + -99.4px);
  }
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elAnomalies_explorer > .background > .containerMinHeight {
    min-height: 249px;
  }

}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elAnomalies_explorer {
    left: calc(37.3% + -10px);
    right: calc(37.3% + -10px);
    top: 99px;
    width: calc(25.4% - -10px - -10px);
    height: calc(100% + 0.0% + -99.4px);
  }
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elAnomalies_explorer > .background > .containerMinHeight {
    min-height: 249px;
  }

}

.PriseEnChargeV3Screen .elCancel {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.PriseEnChargeV3Screen .elCancel:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elCancel {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elCancel {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elCancel {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elCancel {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    position: fixed;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}

.PriseEnChargeV3Screen .elApercu {
  overflow: hidden;
  pointer-events: auto;
}

.PriseEnChargeV3Screen .elApercu.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elApercu {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

@media (min-width: 568px) {
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

}
@media (min-width: 768px) {
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}
@media (min-width: 1024px) {
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .PriseEnChargeV3Screen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}


/* Narrowest size for this element's responsive layout */
  .OperationScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .OperationScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .OperationScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .OperationScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .OperationScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .OperationScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .OperationScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .OperationScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .OperationScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .OperationScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .OperationScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .OperationScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}

.OperationScreen .elVidage button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.OperationScreen .elVidage button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .OperationScreen > .layoutFlow > .elVidage {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .OperationScreen > .layoutFlow > .elVidage > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .OperationScreen > .layoutFlow > .elVidage {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .OperationScreen > .layoutFlow > .elVidage > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .OperationScreen > .layoutFlow > .elVidage {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .OperationScreen > .layoutFlow > .elVidage > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .OperationScreen > .layoutFlow > .elVidage {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .OperationScreen > .layoutFlow > .elVidage > * { 
  width: 144px;
  height: 35px;
  }
  
}

.OperationScreen .elVidage_diff button {
  cursor: pointer;
  border: 0.9px solid #00e5d9;
  border-radius: 3.0px;
}

.OperationScreen .elVidage_diff button:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .OperationScreen > .layoutFlow > .elVidage_diff {
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .OperationScreen > .layoutFlow > .elVidage_diff > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .OperationScreen > .layoutFlow > .elVidage_diff {
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .OperationScreen > .layoutFlow > .elVidage_diff > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .OperationScreen > .layoutFlow > .elVidage_diff {
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .OperationScreen > .layoutFlow > .elVidage_diff > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .OperationScreen > .layoutFlow > .elVidage_diff {
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .OperationScreen > .layoutFlow > .elVidage_diff > * { 
  width: 144px;
  height: 35px;
  }
  
}

.OperationScreen .elTransfert button {
  cursor: pointer;
  border: 0.9px solid #00e5d9;
  border-radius: 3.4px;
}

.OperationScreen .elTransfert button:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .OperationScreen > .layoutFlow > .elTransfert {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .OperationScreen > .layoutFlow > .elTransfert > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .OperationScreen > .layoutFlow > .elTransfert {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .OperationScreen > .layoutFlow > .elTransfert > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .OperationScreen > .layoutFlow > .elTransfert {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .OperationScreen > .layoutFlow > .elTransfert > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .OperationScreen > .layoutFlow > .elTransfert {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .OperationScreen > .layoutFlow > .elTransfert > * { 
  width: 144px;
  height: 35px;
  }
  
}

.OperationScreen .elPackmat button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.OperationScreen .elPackmat button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .OperationScreen > .layoutFlow > .elPackmat {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .OperationScreen > .layoutFlow > .elPackmat > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .OperationScreen > .layoutFlow > .elPackmat {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .OperationScreen > .layoutFlow > .elPackmat > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .OperationScreen > .layoutFlow > .elPackmat {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .OperationScreen > .layoutFlow > .elPackmat > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .OperationScreen > .layoutFlow > .elPackmat {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .OperationScreen > .layoutFlow > .elPackmat > * { 
  width: 144px;
  height: 35px;
  }
  
}

.OperationScreen .elCancel {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.OperationScreen .elCancel:focus {
  box-shadow: 0 0 5px rgba(255, 129, 120, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .OperationScreen > .screenFgContainer > .foreground > .elCancel {
    line-height: 35px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 145px;
    height: 35px;
  }
@media (min-width: 568px) {
  .OperationScreen > .screenFgContainer > .foreground > .elCancel {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 145px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .OperationScreen > .screenFgContainer > .foreground > .elCancel {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 144px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .OperationScreen > .screenFgContainer > .foreground > .elCancel {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 144px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV3Screen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .DepotV3Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .DepotV3Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .DepotV3Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.DepotV3Screen .elList {
  overflow: auto;
  pointer-events: auto;
}

.DepotV3Screen > .screenFgContainer > .foreground > .elList > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 5px;
  grid-column-gap: 5px;  /* old name still required by some browsers */
}

.DepotV3Screen > .screenFgContainer > .foreground > .elList > div > * {
  display: inline-block;
  position: relative;
  min-width: 159px;
}

  @media (min-width: 1024px) {
    .DepotV3Screen > .screenFgContainer > .foreground > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .DepotV3Screen > .screenFgContainer > .foreground > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .DepotV3Screen > .screenFgContainer > .foreground > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .DepotV3Screen > .screenFgContainer > .foreground > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .DepotV3Screen > .screenFgContainer > .foreground > .elList {
    position: absolute;
    left: calc(50.0% + 0px - 168px);
    top: 210px;
    width: 335px;
    height: calc(100% + 0.0% + -265.0px);
  }
@media (min-width: 568px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elList {
    left: calc(50.0% + 0px - 168px);
    top: 210px;
    width: 335px;
    height: calc(100% + 0.0% + -265.0px);
  }
}
@media (min-width: 768px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elList {
    left: calc(50.0% + 0px - 166px);
    top: 209px;
    width: 333px;
    height: calc(100% + 0.0% + -263.4px);
  }
}
@media (min-width: 1024px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elList {
    left: calc(50.0% + 0px - 166px);
    top: 209px;
    width: 333px;
    height: calc(100% + 0.0% + -263.4px);
  }
}

.DepotV3Screen .elValider {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.DepotV3Screen .elValider:focus {
  box-shadow: 0 0 5px rgba(0, 223, 208, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DepotV3Screen > .screenFgContainer > .foreground > .elValider {
    line-height: 35px;
    position: absolute;
    left: calc(50.0% + 0px - 72px);
    top: 210px;
    width: 145px;
    height: 35px;
  }
@media (min-width: 568px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elValider {
    line-height: 35px;
    left: calc(50.0% + 0px - 72px);
    top: 210px;
    width: 145px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elValider {
    line-height: 35px;
    left: calc(50.0% + 0px - 72px);
    top: 209px;
    width: 144px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elValider {
    line-height: 35px;
    left: calc(50.0% + 0px - 72px);
    top: 209px;
    width: 144px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV3Screen > .screenFgContainer > .foreground > .elCheckbox_quai {
    position: absolute;
    right: calc(50.0% + 0px);
    top: 170px;
    width: 80px;
    height: 27px;
  }
@media (min-width: 568px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elCheckbox_quai {
    right: calc(50.0% + 0px);
    top: 170px;
    width: 80px;
    height: 27px;
  }
}
@media (min-width: 768px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elCheckbox_quai {
    right: calc(50.0% + 0px);
    top: 169px;
    width: 80px;
    height: 27px;
  }
}
@media (min-width: 1024px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elCheckbox_quai {
    right: calc(50.0% + 0px);
    top: 169px;
    width: 80px;
    height: 27px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV3Screen > .screenFgContainer > .foreground > .elCheckbox_hors_quai {
    position: absolute;
    left: calc(50.0% + 0px);
    top: 175px;
    width: 100px;
    height: 27px;
  }
@media (min-width: 568px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elCheckbox_hors_quai {
    left: calc(50.0% + 0px);
    top: 175px;
    width: 100px;
    height: 27px;
  }
}
@media (min-width: 768px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elCheckbox_hors_quai {
    left: calc(50.0% + 0px);
    top: 174px;
    width: 99px;
    height: 27px;
  }
}
@media (min-width: 1024px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elCheckbox_hors_quai {
    left: calc(50.0% + 0px);
    top: 174px;
    width: 99px;
    height: 27px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV3Screen > .screenFgContainer > .foreground > .elPicker_producer {
    position: absolute;
    left: calc(9.4% + 0px);
    right: calc(9.4% + 0px);
    top: 130px;
    width: 81.2%;
    height: 30px;
  }
@media (min-width: 568px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elPicker_producer {
    left: calc(27.1% + 0px);
    right: calc(27.1% + 0px);
    top: 130px;
    width: 45.8%;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elPicker_producer {
    left: calc(33.1% + 0px);
    right: calc(33.1% + 0px);
    top: 129px;
    width: 33.9%;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elPicker_producer {
    left: calc(37.3% + 0px);
    right: calc(37.3% + 0px);
    top: 129px;
    width: 25.4%;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV3Screen > .screenFgContainer > .foreground > .elPicker_dech {
    position: absolute;
    left: calc(9.4% + 0px);
    right: calc(9.4% + 0px);
    top: 130px;
    width: 81.2%;
    height: 30px;
  }
@media (min-width: 568px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elPicker_dech {
    left: calc(27.1% + 0px);
    right: calc(27.1% + 0px);
    top: 130px;
    width: 45.8%;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elPicker_dech {
    left: calc(33.1% + 0px);
    right: calc(33.1% + 0px);
    top: 129px;
    width: 33.9%;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elPicker_dech {
    left: calc(37.3% + 0px);
    right: calc(37.3% + 0px);
    top: 129px;
    width: 25.4%;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV3Screen > .screenFgContainer > .foreground > .elCheckbox_dech {
    position: absolute;
    right: calc(50.0% + 0px);
    top: 100px;
    width: 120px;
    height: 27px;
  }
@media (min-width: 568px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elCheckbox_dech {
    right: calc(50.0% + 0px);
    top: 100px;
    width: 120px;
    height: 27px;
  }
}
@media (min-width: 768px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elCheckbox_dech {
    right: calc(50.0% + 0px);
    top: 99px;
    width: 119px;
    height: 27px;
  }
}
@media (min-width: 1024px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elCheckbox_dech {
    right: calc(50.0% + 0px);
    top: 99px;
    width: 119px;
    height: 27px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV3Screen > .screenFgContainer > .foreground > .elCheckbox_autres {
    position: absolute;
    left: calc(50.0% + 0px);
    top: 105px;
    width: 120px;
    height: 27px;
  }
@media (min-width: 568px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elCheckbox_autres {
    left: calc(50.0% + 0px);
    top: 105px;
    width: 120px;
    height: 27px;
  }
}
@media (min-width: 768px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elCheckbox_autres {
    left: calc(50.0% + 0px);
    top: 104px;
    width: 119px;
    height: 27px;
  }
}
@media (min-width: 1024px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elCheckbox_autres {
    left: calc(50.0% + 0px);
    top: 104px;
    width: 119px;
    height: 27px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV3Screen > .screenFgContainer > .foreground > .elText {
    position: absolute;
    left: calc(9.4% + 0px);
    right: calc(9.4% + 0px);
    top: 60px;
    width: 81.2%;
    height: 30px;
  }
@media (min-width: 568px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elText {
    left: calc(27.1% + 0px);
    right: calc(27.1% + 0px);
    top: 60px;
    width: 45.8%;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elText {
    left: calc(33.1% + 0px);
    right: calc(33.1% + 0px);
    top: 60px;
    width: 33.9%;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elText {
    left: calc(37.3% + 0px);
    right: calc(37.3% + 0px);
    top: 60px;
    width: 25.4%;
    height: 30px;
  }
}

.DepotV3Screen .elCancel {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.DepotV3Screen .elCancel:focus {
  box-shadow: 0 0 6px rgba(255, 129, 120, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DepotV3Screen > .screenFgContainer > .foreground > .elCancel {
    line-height: 35px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elCancel {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elCancel {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elCancel {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV3Screen > .screenFgContainer > .foreground > .elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 92px;
    width: 100.0%;
    height: 1px;
  }
@media (min-width: 568px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 92px;
    width: 100.0%;
    height: 1px;
  }
}
@media (min-width: 768px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 91px;
    width: 100.0%;
    height: 1px;
  }
}
@media (min-width: 1024px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 91px;
    width: 100.0%;
    height: 1px;
  }
}

.DepotV3Screen .elShow_apercu {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.DepotV3Screen .elShow_apercu:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DepotV3Screen > .screenFgContainer > .foreground > .elShow_apercu {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV3Screen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    position: fixed;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
@media (min-width: 568px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 768px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 1024px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}

.DepotV3Screen .elApercu {
  overflow: hidden;
  pointer-events: auto;
}

.DepotV3Screen .elApercu.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .DepotV3Screen > .screenFgContainer > .foreground > .elApercu {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DepotV3Screen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

@media (min-width: 568px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DepotV3Screen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

}
@media (min-width: 768px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DepotV3Screen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}
@media (min-width: 1024px) {
  .DepotV3Screen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DepotV3Screen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}


/* Narrowest size for this element's responsive layout */
  .Point_dechScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .Point_dechScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .Point_dechScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .Point_dechScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .Point_dechScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Point_dechScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .Point_dechScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Point_dechScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .Point_dechScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Point_dechScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .Point_dechScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Point_dechScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Point_dechScreen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .Point_dechScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .Point_dechScreen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .Point_dechScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .Point_dechScreen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .Point_dechScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .Point_dechScreen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .Point_dechScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Point_dechScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Point_dechScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
@media (min-width: 568px) {
  .Point_dechScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Point_dechScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 768px) {
  .Point_dechScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Point_dechScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 1024px) {
  .Point_dechScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Point_dechScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Point_dechScreen > .layoutFlow > .flowRow > .elCheckbox_hors_quai {
    position: absolute;
    margin-top: 20px;
    width: 120px;
    margin-left: 50.0%;
  }
  .Point_dechScreen > .layoutFlow > .flowRow > .elCheckbox_hors_quai > * { 
  width: 120px;
  }
  
  .flowRow_Point_dechScreen_elCheckbox_hors_quai_1357987987 { 
  }
  
@media (min-width: 568px) {
  .Point_dechScreen > .layoutFlow > .flowRow > .elCheckbox_hors_quai {
    position: absolute;
    margin-top: 20px;
    width: 120px;
    margin-left: 50.0%;
  }
  .Point_dechScreen > .layoutFlow > .flowRow > .elCheckbox_hors_quai > * { 
  width: 120px;
  }
  
  .flowRow_Point_dechScreen_elCheckbox_hors_quai_1357987987 { 
  }
  
}
@media (min-width: 768px) {
  .Point_dechScreen > .layoutFlow > .flowRow > .elCheckbox_hors_quai {
    position: absolute;
    margin-top: 20px;
    width: 119px;
    margin-left: 50.0%;
  }
  .Point_dechScreen > .layoutFlow > .flowRow > .elCheckbox_hors_quai > * { 
  width: 119px;
  }
  
  .flowRow_Point_dechScreen_elCheckbox_hors_quai_1357987987 { 
  }
  
}
@media (min-width: 1024px) {
  .Point_dechScreen > .layoutFlow > .flowRow > .elCheckbox_hors_quai {
    position: absolute;
    margin-top: 20px;
    width: 119px;
    margin-left: 50.0%;
  }
  .Point_dechScreen > .layoutFlow > .flowRow > .elCheckbox_hors_quai > * { 
  width: 119px;
  }
  
  .flowRow_Point_dechScreen_elCheckbox_hors_quai_1357987987 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Point_dechScreen > .layoutFlow > .elCheckbox_quai {
    position: relative;
    margin-top: 20px;
    width: 100%;
  }
  .Point_dechScreen > .layoutFlow > .elCheckbox_quai > * { 
  margin-left: auto;
  width: 120px;
  margin-right: 50.0%;
  }
  
@media (min-width: 568px) {
  .Point_dechScreen > .layoutFlow > .elCheckbox_quai {
    position: relative;
    margin-top: 20px;
    width: 100%;
  }
  .Point_dechScreen > .layoutFlow > .elCheckbox_quai > * { 
  margin-left: auto;
  width: 120px;
  margin-right: 50.0%;
  }
  
}
@media (min-width: 768px) {
  .Point_dechScreen > .layoutFlow > .elCheckbox_quai {
    position: relative;
    margin-top: 20px;
    width: 100%;
  }
  .Point_dechScreen > .layoutFlow > .elCheckbox_quai > * { 
  margin-left: auto;
  width: 119px;
  margin-right: 50.0%;
  }
  
}
@media (min-width: 1024px) {
  .Point_dechScreen > .layoutFlow > .elCheckbox_quai {
    position: relative;
    margin-top: 20px;
    width: 100%;
  }
  .Point_dechScreen > .layoutFlow > .elCheckbox_quai > * { 
  margin-left: auto;
  width: 119px;
  margin-right: 50.0%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Point_dechScreen > .layoutFlow > .elPicker_dech {
    position: relative;
    margin-top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .Point_dechScreen > .layoutFlow > .elPicker_dech > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .Point_dechScreen > .layoutFlow > .elPicker_dech {
    position: relative;
    margin-top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .Point_dechScreen > .layoutFlow > .elPicker_dech > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .Point_dechScreen > .layoutFlow > .elPicker_dech {
    position: relative;
    margin-top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .Point_dechScreen > .layoutFlow > .elPicker_dech > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .Point_dechScreen > .layoutFlow > .elPicker_dech {
    position: relative;
    margin-top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .Point_dechScreen > .layoutFlow > .elPicker_dech > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Point_dechScreen > .layoutFlow > .elPicker_producer {
    position: relative;
    margin-top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .Point_dechScreen > .layoutFlow > .elPicker_producer > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .Point_dechScreen > .layoutFlow > .elPicker_producer {
    position: relative;
    margin-top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .Point_dechScreen > .layoutFlow > .elPicker_producer > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .Point_dechScreen > .layoutFlow > .elPicker_producer {
    position: relative;
    margin-top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .Point_dechScreen > .layoutFlow > .elPicker_producer > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .Point_dechScreen > .layoutFlow > .elPicker_producer {
    position: relative;
    margin-top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .Point_dechScreen > .layoutFlow > .elPicker_producer > * { 
    width: 100%;
  }
  
}

.Point_dechScreen .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Point_dechScreen .elButton button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Point_dechScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .Point_dechScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .Point_dechScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .Point_dechScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .Point_dechScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .Point_dechScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .Point_dechScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .Point_dechScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}

.Point_dechScreen .elSwitch_producers button {
  cursor: pointer;
  border: 0.9px solid #00e5d9;
  border-radius: 3.0px;
}

.Point_dechScreen .elSwitch_producers button:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Point_dechScreen > .layoutFlow > .elSwitch_producers {
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .Point_dechScreen > .layoutFlow > .elSwitch_producers > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .Point_dechScreen > .layoutFlow > .elSwitch_producers {
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .Point_dechScreen > .layoutFlow > .elSwitch_producers > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .Point_dechScreen > .layoutFlow > .elSwitch_producers {
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .Point_dechScreen > .layoutFlow > .elSwitch_producers > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .Point_dechScreen > .layoutFlow > .elSwitch_producers {
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .Point_dechScreen > .layoutFlow > .elSwitch_producers > * { 
  width: 144px;
  height: 35px;
  }
  
}

.Point_dechScreen .elOther_place button {
  cursor: pointer;
  border: 0.9px solid #ffd478;
  border-radius: 3.4px;
}

.Point_dechScreen .elOther_place button:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Point_dechScreen > .layoutFlow > .elOther_place {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .Point_dechScreen > .layoutFlow > .elOther_place > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .Point_dechScreen > .layoutFlow > .elOther_place {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .Point_dechScreen > .layoutFlow > .elOther_place > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .Point_dechScreen > .layoutFlow > .elOther_place {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .Point_dechScreen > .layoutFlow > .elOther_place > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .Point_dechScreen > .layoutFlow > .elOther_place {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .Point_dechScreen > .layoutFlow > .elOther_place > * { 
  width: 144px;
  height: 35px;
  }
  
}

.Point_dechScreen .elAnnuler {
  cursor: pointer;
  border: 0.9px solid #ff7d78;
  border-radius: 3.4px;
}

.Point_dechScreen .elAnnuler:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Point_dechScreen > .screenFgContainer > .foreground > .elAnnuler {
    line-height: 35px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 145px;
    height: 35px;
  }
@media (min-width: 568px) {
  .Point_dechScreen > .screenFgContainer > .foreground > .elAnnuler {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 145px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .Point_dechScreen > .screenFgContainer > .foreground > .elAnnuler {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 144px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .Point_dechScreen > .screenFgContainer > .foreground > .elAnnuler {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 144px;
    height: 35px;
  }
}

.Point_dechScreen .elShow_apercu {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Point_dechScreen .elShow_apercu:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Point_dechScreen > .screenFgContainer > .foreground > .elShow_apercu {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .Point_dechScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .Point_dechScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .Point_dechScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Point_dechScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    position: fixed;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
@media (min-width: 568px) {
  .Point_dechScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 768px) {
  .Point_dechScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 1024px) {
  .Point_dechScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}

.Point_dechScreen .elApercu {
  overflow: hidden;
  pointer-events: auto;
}

.Point_dechScreen .elApercu.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .Point_dechScreen > .screenFgContainer > .foreground > .elApercu {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .Point_dechScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

@media (min-width: 568px) {
  .Point_dechScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .Point_dechScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

}
@media (min-width: 768px) {
  .Point_dechScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .Point_dechScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}
@media (min-width: 1024px) {
  .Point_dechScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .Point_dechScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}

/* This component has a fixed-size layout */
  .Tail_picker_niveau {
    min-height: 159px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 159px; /* This component has a fixed size */
  }
  .Tail_picker_niveau > .background > .containerMinHeight {
    min-height: 159px;
  }

.Tail_picker_niveau .elButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Tail_picker_niveau .elButton:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



  .Tail_picker_niveau > .foreground > .elButton {
    line-height: 35px;
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 5px;
    width: calc(100.0% - 5px - 5px);
    height: 35px;
  }


  .Tail_picker_niveau > .foreground > .elPicker {
    position: absolute;
    left: 5px;
    right: 5px;
    top: 80px;
    width: calc(100.0% - 5px - 5px);
    height: 30px;
  }


  .Tail_picker_niveau > .foreground > .elPicker_quai {
    position: absolute;
    left: 5px;
    right: 5px;
    top: 40px;
    width: calc(100.0% - 5px - 5px);
    height: 30px;
  }


  .Tail_picker_niveau > .foreground > .elText {
    overflow: hidden;
    position: absolute;
    left: 5px;
    right: 5px;
    top: 5px;
    width: calc(100.0% - 5px - 5px);
    height: 26px;
  }

/* This component has a fixed-size layout */
  .Add_prise_en_charge {
    min-height: 95px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 300px; /* This component has a fixed size */
  }
  .Add_prise_en_charge > .background > .containerMinHeight {
    min-height: 95px;
  }

.Add_prise_en_charge .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Add_prise_en_charge .elIconButton:focus {
  box-shadow: 0 0 6px rgba(213, 213, 213, 0.2500);
  outline: none;
}



  .Add_prise_en_charge > .foreground > .elIconButton {
    position: absolute;
    right: calc(50.0% + 0px);
    top: 0px;
    width: 95px;
    height: 100%;
  }

.Add_prise_en_charge .elTxt_button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Add_prise_en_charge .elTxt_button:focus {
  box-shadow: 0 0 6px rgba(213, 213, 213, 0.2500);
  outline: none;
}



  .Add_prise_en_charge > .foreground > .elTxt_button {
    position: absolute;
    left: calc(50.0% + 0px);
    top: 0px;
    width: 95px;
    height: 100%;
  }

.Add_prise_en_charge .elHotspot {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}



  .Add_prise_en_charge > .foreground > .elHotspot {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

.Add_prise_en_charge .elPrevent_interraction {
  overflow: hidden;
}

.Add_prise_en_charge .elPrevent_interraction.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Add_prise_en_charge > .foreground > .elPrevent_interraction {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .background > .state0_elBackground321605362 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .EchangeScreen > .background > .state0_elBackground321605362 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .background > .state0_elBackground321605362 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .background > .state0_elBackground321605362 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .background > .state0_elRectangle440755512 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 92px;
    width: 100.0%;
    height: 1px;
  }
@media (min-width: 568px) {
  .EchangeScreen > .background > .state0_elRectangle440755512 {
    left: 0px;
    right: 0px;
    top: 92px;
    width: 100.0%;
    height: 1px;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .background > .state0_elRectangle440755512 {
    left: 0px;
    right: 0px;
    top: 91px;
    width: 100.0%;
    height: 1px;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .background > .state0_elRectangle440755512 {
    left: 0px;
    right: 0px;
    top: 91px;
    width: 100.0%;
    height: 1px;
  }
}

.EchangeScreen .state0_elCancel464519933 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.EchangeScreen .state0_elCancel464519933:focus {
  box-shadow: 0 0 6px rgba(255, 129, 120, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elCancel464519933 {
    line-height: 35px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elCancel464519933 {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elCancel464519933 {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elCancel464519933 {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}

.EchangeScreen .state0_elNo {
  cursor: pointer;
  border: 0.9px solid #00e5d9;
  border-radius: 3.0px;
}

.EchangeScreen .state0_elNo:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elNo {
    position: absolute;
    left: calc(50.0% + 0px - 78px);
    top: 290px;
    width: 155px;
    height: 35px;
  }
@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elNo {
    left: calc(50.0% + 0px - 78px);
    top: 290px;
    width: 155px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elNo {
    left: calc(50.0% + 0px - 77px);
    top: 288px;
    width: 154px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elNo {
    left: calc(50.0% + 0px - 77px);
    top: 288px;
    width: 154px;
    height: 35px;
  }
}

.EchangeScreen .state0_elYes_here {
  cursor: pointer;
  border: 0.9px solid #00e5d9;
  border-radius: 3.4px;
}

.EchangeScreen .state0_elYes_here:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elYes_here {
    line-height: 35px;
    position: absolute;
    left: calc(50.0% + 0px - 78px);
    top: 240px;
    width: 155px;
    height: 35px;
  }
@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elYes_here {
    line-height: 35px;
    left: calc(50.0% + 0px - 78px);
    top: 240px;
    width: 155px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elYes_here {
    line-height: 35px;
    left: calc(50.0% + 0px - 77px);
    top: 238px;
    width: 154px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elYes_here {
    line-height: 35px;
    left: calc(50.0% + 0px - 77px);
    top: 238px;
    width: 154px;
    height: 35px;
  }
}

.EchangeScreen .state0_elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.EchangeScreen .state0_elIconButton:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elIconButton {
    position: absolute;
    left: calc(50.0% + 0px - 30px);
    top: 135px;
    width: 60px;
    height: 60px;
  }
@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elIconButton {
    left: calc(50.0% + 0px - 30px);
    top: 135px;
    width: 60px;
    height: 60px;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elIconButton {
    left: calc(50.0% + 0px - 30px);
    top: 134px;
    width: 60px;
    height: 60px;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elIconButton {
    left: calc(50.0% + 0px - 30px);
    top: 134px;
    width: 60px;
    height: 60px;
  }
}

.EchangeScreen .state0_elImage_fill_empty {
  overflow: hidden;
}

.EchangeScreen .state0_elImage_fill_empty.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elImage_fill_empty {
    position: absolute;
    left: calc(50.0% + 20px);
    top: 105px;
    width: 120px;
    height: 120px;
  }
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elImage_fill_empty > .background > .containerMinHeight {
    min-height: 30px;
  }

@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elImage_fill_empty {
    left: calc(50.0% + 20px);
    top: 105px;
    width: 120px;
    height: 120px;
  }
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elImage_fill_empty > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elImage_fill_empty {
    left: calc(50.0% + 20px);
    top: 104px;
    width: 120px;
    height: 120px;
  }
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elImage_fill_empty > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elImage_fill_empty {
    left: calc(50.0% + 20px);
    top: 104px;
    width: 120px;
    height: 120px;
  }
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elImage_fill_empty > .background > .containerMinHeight {
    min-height: 30px;
  }

}

.EchangeScreen .state0_elImage_fill_full {
  overflow: hidden;
}

.EchangeScreen .state0_elImage_fill_full.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elImage_fill_full {
    position: absolute;
    right: calc(50.0% + 20px);
    top: 105px;
    width: 120px;
    height: 120px;
  }
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elImage_fill_full {
    right: calc(50.0% + 20px);
    top: 105px;
    width: 120px;
    height: 120px;
  }
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elImage_fill_full {
    right: calc(50.0% + 20px);
    top: 104px;
    width: 120px;
    height: 120px;
  }
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elImage_fill_full {
    right: calc(50.0% + 20px);
    top: 104px;
    width: 120px;
    height: 120px;
  }
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}


/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elId_scan {
    position: absolute;
    right: calc(50.0% + 10px);
    top: 105px;
    width: 60px;
    height: 20px;
  }
@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elId_scan {
    right: calc(50.0% + 10px);
    top: 105px;
    width: 60px;
    height: 20px;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elId_scan {
    right: calc(50.0% + 10px);
    top: 104px;
    width: 60px;
    height: 20px;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elId_scan {
    right: calc(50.0% + 10px);
    top: 104px;
    width: 60px;
    height: 20px;
  }
}


/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elText {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 60px;
    width: 100.0%;
    height: 30px;
  }
@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elText {
    overflow: hidden;
    left: 0px;
    right: 0px;
    top: 60px;
    width: 100.0%;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elText {
    overflow: hidden;
    left: 0px;
    right: 0px;
    top: 60px;
    width: 100.0%;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elText {
    overflow: hidden;
    left: 0px;
    right: 0px;
    top: 60px;
    width: 100.0%;
    height: 30px;
  }
}

.EchangeScreen .state0_elShow_apercu521157686 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.EchangeScreen .state0_elShow_apercu521157686:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elShow_apercu521157686 {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elShow_apercu521157686 {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elShow_apercu521157686 {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elShow_apercu521157686 {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elNotif776150942 {
    overflow: hidden;
    position: fixed;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elNotif776150942 {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elNotif776150942 {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elNotif776150942 {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}

.EchangeScreen .state0_elApercu65562753 {
  overflow: hidden;
  pointer-events: auto;
}

.EchangeScreen .state0_elApercu65562753.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elApercu65562753 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elApercu65562753 > .background > .containerMinHeight {
    min-height: 300px;
  }

@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elApercu65562753 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elApercu65562753 > .background > .containerMinHeight {
    min-height: 300px;
  }

}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elApercu65562753 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elApercu65562753 > .background > .containerMinHeight {
    min-height: 299px;
  }

}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elApercu65562753 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .EchangeScreen > .screenFgContainer > .foreground > .state0_elApercu65562753 > .background > .containerMinHeight {
    min-height: 299px;
  }

}


/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .background > .state1_elBackground321605362 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .EchangeScreen > .background > .state1_elBackground321605362 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .background > .state1_elBackground321605362 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .background > .state1_elBackground321605362 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .background > .state1_elRectangle440755512 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 92px;
    width: 100.0%;
    height: 1px;
  }
@media (min-width: 568px) {
  .EchangeScreen > .background > .state1_elRectangle440755512 {
    left: 0px;
    right: 0px;
    top: 92px;
    width: 100.0%;
    height: 1px;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .background > .state1_elRectangle440755512 {
    left: 0px;
    right: 0px;
    top: 91px;
    width: 100.0%;
    height: 1px;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .background > .state1_elRectangle440755512 {
    left: 0px;
    right: 0px;
    top: 91px;
    width: 100.0%;
    height: 1px;
  }
}

.EchangeScreen .state1_elCancel464519933 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.EchangeScreen .state1_elCancel464519933:focus {
  box-shadow: 0 0 6px rgba(255, 129, 120, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elCancel464519933 {
    line-height: 35px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elCancel464519933 {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elCancel464519933 {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elCancel464519933 {
    line-height: 35px;
    right: 10px;
    bottom: 10px;
    width: 35px;
    height: 35px;
  }
}

.EchangeScreen .state1_elList {
  overflow: auto;
  pointer-events: auto;
}

.EchangeScreen > .screenFgContainer > .foreground > .state1_elList > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 5px;
  grid-column-gap: 5px;  /* old name still required by some browsers */
}

.EchangeScreen > .screenFgContainer > .foreground > .state1_elList > div > * {
  display: inline-block;
  position: relative;
  min-width: 159px;
}

  @media (min-width: 1024px) {
    .EchangeScreen > .screenFgContainer > .foreground > .state1_elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .EchangeScreen > .screenFgContainer > .foreground > .state1_elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .EchangeScreen > .screenFgContainer > .foreground > .state1_elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .EchangeScreen > .screenFgContainer > .foreground > .state1_elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elList {
    position: absolute;
    left: calc(50.0% + 0px - 165px);
    top: 125px;
    width: 330px;
    height: calc(100% + 0.0% + -180.0px);
  }
@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elList {
    left: calc(50.0% + 0px - 165px);
    top: 125px;
    width: 330px;
    height: calc(100% + 0.0% + -180.0px);
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elList {
    left: calc(50.0% + 0px - 164px);
    top: 124px;
    width: 328px;
    height: calc(100% + 0.0% + -178.9px);
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elList {
    left: calc(50.0% + 0px - 164px);
    top: 124px;
    width: 328px;
    height: calc(100% + 0.0% + -178.9px);
  }
}


/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elSite {
    position: absolute;
    left: calc(50.0% + -165px);
    top: 100px;
    width: 260px;
    height: 20px;
  }
@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elSite {
    left: calc(50.0% + -165px);
    top: 100px;
    width: 260px;
    height: 20px;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elSite {
    left: calc(50.0% + -164px);
    top: 99px;
    width: 259px;
    height: 20px;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elSite {
    left: calc(50.0% + -164px);
    top: 99px;
    width: 259px;
    height: 20px;
  }
}


/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elText {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 60px;
    width: 100.0%;
    height: 30px;
  }
@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elText {
    overflow: hidden;
    left: 0px;
    right: 0px;
    top: 60px;
    width: 100.0%;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elText {
    overflow: hidden;
    left: 0px;
    right: 0px;
    top: 60px;
    width: 100.0%;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elText {
    overflow: hidden;
    left: 0px;
    right: 0px;
    top: 60px;
    width: 100.0%;
    height: 30px;
  }
}

.EchangeScreen .state1_elReturn {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.EchangeScreen .state1_elReturn:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elReturn {
    position: absolute;
    left: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }
@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elReturn {
    left: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elReturn {
    left: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elReturn {
    left: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }
}

.EchangeScreen .state1_elShow_apercu521157686 {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.EchangeScreen .state1_elShow_apercu521157686:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elShow_apercu521157686 {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elShow_apercu521157686 {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elShow_apercu521157686 {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elShow_apercu521157686 {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elNotif776150942 {
    overflow: hidden;
    position: fixed;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elNotif776150942 {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elNotif776150942 {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elNotif776150942 {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}

.EchangeScreen .state1_elApercu65562753 {
  overflow: hidden;
  pointer-events: auto;
}

.EchangeScreen .state1_elApercu65562753.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elApercu65562753 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elApercu65562753 > .background > .containerMinHeight {
    min-height: 300px;
  }

@media (min-width: 568px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elApercu65562753 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elApercu65562753 > .background > .containerMinHeight {
    min-height: 300px;
  }

}
@media (min-width: 768px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elApercu65562753 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elApercu65562753 > .background > .containerMinHeight {
    min-height: 299px;
  }

}
@media (min-width: 1024px) {
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elApercu65562753 {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .EchangeScreen > .screenFgContainer > .foreground > .state1_elApercu65562753 > .background > .containerMinHeight {
    min-height: 299px;
  }

}

/* This component has a fixed-size layout */
  .Tail_picker_echange {
    min-height: 159px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 159px; /* This component has a fixed size */
  }
  .Tail_picker_echange > .background > .containerMinHeight {
    min-height: 159px;
  }

.Tail_picker_echange .elButtonCopy {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Tail_picker_echange .elButtonCopy:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



  .Tail_picker_echange > .foreground > .elButtonCopy {
    line-height: 35px;
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 5px;
    width: calc(100.0% - 5px - 5px);
    height: 35px;
  }


  .Tail_picker_echange > .foreground > .elMatricule {
    position: absolute;
    left: 5px;
    right: 5px;
    top: 85px;
    width: calc(100.0% - 5px - 5px);
    height: 20px;
  }

.Tail_picker_echange .elScanner {
  cursor: pointer;
  border: 0.9px solid #00e5d9;
}

.Tail_picker_echange .elScanner:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .Tail_picker_echange > .foreground > .elScanner {
    position: absolute;
    left: 5px;
    top: 85px;
    width: 80px;
    height: 20px;
  }


  .Tail_picker_echange > .foreground > .elFlux {
    position: absolute;
    left: 5px;
    right: 5px;
    top: 7px;
    width: calc(100.0% - 5px - 5px);
    height: 20px;
  }

.Tail_picker_echange .elClose {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Tail_picker_echange .elClose:focus {
  box-shadow: 0 0 5px rgba(255, 129, 120, 0.5000);
  outline: none;
}



  .Tail_picker_echange > .foreground > .elClose {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 41px;
    height: 36px;
  }


  .Tail_picker_echange > .foreground > .elPicker_vide {
    position: absolute;
    left: 5px;
    right: 5px;
    top: 40px;
    width: calc(100.0% - 5px - 5px);
    height: 30px;
  }

.Tail_picker_echange .elAdd {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Tail_picker_echange .elAdd:focus {
  box-shadow: 0 0 5px rgba(213, 213, 213, 0.5000);
  outline: none;
}



  .Tail_picker_echange > .foreground > .elAdd {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

/* This component has a fixed-size layout */
  .Picker_echange2 {
    min-height: 159px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 159px */
    height: 100%;
  }
  .Picker_echange2 > .background > .containerMinHeight {
    min-height: 159px;
  }


  .Picker_echange2 > .background > .elCard {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Picker_echange2 > .layoutFlow > .flowRow > .elN0_0 {
    position: absolute;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_echange2 > .layoutFlow > .flowRow > .elN0_0 > * { 
    width: 100%;
  height: 0px;
  }
  
  .flowRow_Picker_echange2_elN0_0_1191061661 { 
  }
  


  .Picker_echange2 > .layoutFlow > .flowRow > .elN0_2 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_echange2 > .layoutFlow > .flowRow > .elN0_2 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_echange2 > .layoutFlow > .flowRow > .elN0_3 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_echange2 > .layoutFlow > .flowRow > .elN0_3 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_echange2 > .layoutFlow > .flowRow > .elN0_4 {
    position: absolute;
    top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_echange2 > .layoutFlow > .flowRow > .elN0_4 > * { 
    width: 100%;
  height: 0px;
  }
  


  .Picker_echange2 > .foreground > .elFlux {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 134px;
    width: 100.0%;
    height: 20px;
  }

.Picker_echange2 .elImage_fill {
  overflow: hidden;
}

.Picker_echange2 .elImage_fill.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Picker_echange2 > .foreground > .elImage_fill {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 110px;
  }


  .Picker_echange2 > .foreground > .elId_scan {
    position: absolute;
    left: calc(50.0% + 15px);
    right: 5px;
    top: 5px;
    width: calc(50.0% - 15px - 5px);
    height: 20px;
  }


  .Picker_echange2 > .foreground > .elQuai {
    position: absolute;
    left: 5px;
    right: calc(50.0% + 5px);
    top: 5px;
    width: calc(50.0% - 5px - 5px);
    height: 30px;
  }


  .Picker_echange2 > .foreground > .elState {
    position: absolute;
    left: calc(50.0% + 0px - 35px);
    top: 110px;
    width: 70px;
    height: 20px;
  }

.Picker_echange2 .elPackmat_display {
  overflow: hidden;
}

.Picker_echange2 .elPackmat_display.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Picker_echange2 > .foreground > .elPackmat_display {
    position: absolute;
    right: calc(50.0% + 40px);
    top: 105px;
    width: 30px;
    height: 30px;
  }

.Picker_echange2 .elClient_diff {
  overflow: hidden;
}

.Picker_echange2 .elClient_diff.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Picker_echange2 > .foreground > .elClient_diff {
    position: absolute;
    left: calc(50.0% + 0px - 40px);
    top: 110px;
    width: 80px;
    height: 20px;
  }

/* This component has a fixed-size layout */
  .Picker_depot2 {
    min-height: 159px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 159px */
    height: 100%;
  }
  .Picker_depot2 > .background > .containerMinHeight {
    min-height: 159px;
  }


  .Picker_depot2 > .background > .elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Picker_depot2 > .layoutFlow > .flowRow > .elVide {
    position: absolute;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_depot2 > .layoutFlow > .flowRow > .elVide > * { 
    width: 100%;
  height: 0px;
  }
  
  .flowRow_Picker_depot2_elVide_2078006252 { 
  }
  


  .Picker_depot2 > .foreground > .elFlux {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 134px;
    width: 100.0%;
    height: 20px;
  }


  .Picker_depot2 > .foreground > .elState {
    position: absolute;
    left: calc(50.0% + 0px - 30px);
    top: 110px;
    width: 60px;
    height: 20px;
  }

.Picker_depot2 .elImage_fill {
  overflow: hidden;
}

.Picker_depot2 .elImage_fill.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Picker_depot2 > .foreground > .elImage_fill {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 110px;
  }


  .Picker_depot2 > .foreground > .elId_scan {
    position: absolute;
    left: calc(50.0% + 5px);
    right: 5px;
    top: 5px;
    width: calc(50.0% - 5px - 5px);
    height: 20px;
  }


  .Picker_depot2 > .foreground > .elQuai {
    position: absolute;
    left: 5px;
    right: calc(50.0% + 5px);
    top: 5px;
    width: calc(50.0% - 5px - 5px);
    height: 30px;
  }


/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .Valider_simpleScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 159px;
  }
  .Valider_simpleScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .Valider_simpleScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 159px;
  }
  .Valider_simpleScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 158px;
  }
  .Valider_simpleScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 158px;
  }
  .Valider_simpleScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .layoutFlow > .elExutoireCopy {
    position: relative;
    margin-top: 10px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .elExutoireCopy > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .Valider_simpleScreen > .layoutFlow > .elExutoireCopy {
    position: relative;
    margin-top: 10px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .elExutoireCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .layoutFlow > .elExutoireCopy {
    position: relative;
    margin-top: 10px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .elExutoireCopy > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .layoutFlow > .elExutoireCopy {
    position: relative;
    margin-top: 10px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .elExutoireCopy > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
@media (min-width: 568px) {
  .Valider_simpleScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCard1 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(9.4% + -10px);
    margin-right: 7.8%;
    width: calc(82.8% - -10px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCard1 > * { 
    width: 100%;
  height: 145px;
  }
  
  .flowRow_Valider_simpleScreen_elCard1_273695898 { 
  }
  
@media (min-width: 568px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCard1 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(27.1% + -10px);
    margin-right: 26.2%;
    width: calc(46.7% - -10px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCard1 > * { 
    width: 100%;
  height: 145px;
  }
  
  .flowRow_Valider_simpleScreen_elCard1_273695898 { 
  }
  
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCard1 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(33.1% + -10px);
    margin-right: 32.4%;
    width: calc(34.5% - -10px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCard1 > * { 
    width: 100%;
  height: 144px;
  }
  
  .flowRow_Valider_simpleScreen_elCard1_273695898 { 
  }
  
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCard1 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(37.3% + -10px);
    margin-right: 36.8%;
    width: calc(25.9% - -10px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCard1 > * { 
    width: 100%;
  height: 144px;
  }
  
  .flowRow_Valider_simpleScreen_elCard1_273695898 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCommentaire {
    position: absolute;
    top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCommentaire > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCommentaire {
    position: absolute;
    top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCommentaire > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCommentaire {
    position: absolute;
    top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCommentaire > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCommentaire {
    position: absolute;
    top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCommentaire > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elTonnage {
    position: absolute;
    top: 65px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elTonnage > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elTonnage {
    position: absolute;
    top: 65px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elTonnage > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elTonnage {
    position: absolute;
    top: 65px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elTonnage > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elTonnage {
    position: absolute;
    top: 64px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elTonnage > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elVolume {
    position: absolute;
    top: 110px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elVolume > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elVolume {
    position: absolute;
    top: 110px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elVolume > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elVolume {
    position: absolute;
    top: 110px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elVolume > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elVolume {
    position: absolute;
    top: 110px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elVolume > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Valider_simpleScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 155px;
  }
  
@media (min-width: 568px) {
  .Valider_simpleScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Valider_simpleScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 155px;
  }
  
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Valider_simpleScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 155px;
  }
  
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .layoutFlow > .elSpacer3 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Valider_simpleScreen > .layoutFlow > .elSpacer3 > * { 
  width: 10px;
  height: 155px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCard3 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(9.4% + -10px);
    margin-right: 7.8%;
    width: calc(82.8% - -10px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCard3 > * { 
    width: 100%;
  height: 120px;
  }
  
  .flowRow_Valider_simpleScreen_elCard3_39283406 { 
  }
  
@media (min-width: 568px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCard3 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(27.1% + -10px);
    margin-right: 26.2%;
    width: calc(46.7% - -10px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCard3 > * { 
    width: 100%;
  height: 120px;
  }
  
  .flowRow_Valider_simpleScreen_elCard3_39283406 { 
  }
  
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCard3 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(33.1% + -10px);
    margin-right: 32.4%;
    width: calc(34.5% - -10px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCard3 > * { 
    width: 100%;
  height: 119px;
  }
  
  .flowRow_Valider_simpleScreen_elCard3_39283406 { 
  }
  
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCard3 {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(37.3% + -10px);
    margin-right: 36.8%;
    width: calc(25.9% - -10px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCard3 > * { 
    width: 100%;
  height: 119px;
  }
  
  .flowRow_Valider_simpleScreen_elCard3_39283406 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 100px;
  }
  
@media (min-width: 568px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 100px;
  }
  
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 100px;
  }
  
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elImage > * { 
    width: 100%;
  height: 100px;
  }
  
}

.Valider_simpleScreen .elCapture button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Valider_simpleScreen .elCapture button:focus {
  box-shadow: 0 0 6px rgba(0, 226, 217, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 20px;
    width: 140px;
    margin-left: calc(50.0% - 70px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCapture > * { 
  width: 140px;
  height: 100px;
  }
  
@media (min-width: 568px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 20px;
    width: 140px;
    margin-left: calc(50.0% - 70px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCapture > * { 
  width: 140px;
  height: 100px;
  }
  
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 20px;
    width: 139px;
    margin-left: calc(50.0% - 70px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCapture > * { 
  width: 139px;
  height: 99px;
  }
  
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCapture {
    position: absolute;
    top: 20px;
    width: 139px;
    margin-left: calc(50.0% - 70px);
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elCapture > * { 
  width: 139px;
  height: 99px;
  }
  
}

.Valider_simpleScreen .elErase button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Valider_simpleScreen .elErase button:focus {
  box-shadow: 0 0 6px rgba(255, 129, 120, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 9.4%;
  height: 30px;
  }
  
@media (min-width: 568px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 27.1%;
  height: 30px;
  }
  
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 33.1%;
  height: 30px;
  }
  
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elErase {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elErase > * { 
  margin-left: auto;
  width: 30px;
  margin-right: 37.3%;
  height: 30px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Valider_simpleScreen > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 130px;
  }
  
@media (min-width: 568px) {
  .Valider_simpleScreen > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Valider_simpleScreen > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 130px;
  }
  
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Valider_simpleScreen > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 130px;
  }
  
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .layoutFlow > .elSpacerCopy2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Valider_simpleScreen > .layoutFlow > .elSpacerCopy2 > * { 
  width: 10px;
  height: 130px;
  }
  
}

.Valider_simpleScreen .elRetour button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Valider_simpleScreen .elRetour button:focus {
  box-shadow: 0 0 5px rgba(255, 129, 120, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elRetour {
    line-height: 35px;
    position: absolute;
    margin-top: 20px;
    width: 100%;
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elRetour > * { 
  margin-left: auto;
  width: 145px;
  margin-right: 10px;
  height: 35px;
  }
  
  .flowRow_Valider_simpleScreen_elRetour_2132217722 { 
  }
  
@media (min-width: 568px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elRetour {
    line-height: 35px;
    position: absolute;
    margin-top: 20px;
    width: 100%;
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elRetour > * { 
  margin-left: auto;
  width: 145px;
  margin-right: 10px;
  height: 35px;
  }
  
  .flowRow_Valider_simpleScreen_elRetour_2132217722 { 
  }
  
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elRetour {
    line-height: 35px;
    position: absolute;
    margin-top: 20px;
    width: 100%;
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elRetour > * { 
  margin-left: auto;
  width: 144px;
  margin-right: 10px;
  height: 35px;
  }
  
  .flowRow_Valider_simpleScreen_elRetour_2132217722 { 
  }
  
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elRetour {
    line-height: 35px;
    position: absolute;
    margin-top: 20px;
    width: 100%;
  }
  .Valider_simpleScreen > .layoutFlow > .flowRow > .elRetour > * { 
  margin-left: auto;
  width: 144px;
  margin-right: 10px;
  height: 35px;
  }
  
  .flowRow_Valider_simpleScreen_elRetour_2132217722 { 
  }
  
}

.Valider_simpleScreen .elValider button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Valider_simpleScreen .elValider button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .layoutFlow > .elValider {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: 10px;
  }
  .Valider_simpleScreen > .layoutFlow > .elValider > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .Valider_simpleScreen > .layoutFlow > .elValider {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: 10px;
  }
  .Valider_simpleScreen > .layoutFlow > .elValider > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .layoutFlow > .elValider {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: 10px;
  }
  .Valider_simpleScreen > .layoutFlow > .elValider > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .layoutFlow > .elValider {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: 10px;
  }
  .Valider_simpleScreen > .layoutFlow > .elValider > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elBig_image {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
@media (min-width: 568px) {
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elBig_image {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elBig_image {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elBig_image {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
}

.Valider_simpleScreen .elShow_apercu {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Valider_simpleScreen .elShow_apercu:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elShow_apercu {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    position: fixed;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
@media (min-width: 568px) {
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 768px) {
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}

.Valider_simpleScreen .elApercu {
  overflow: hidden;
  pointer-events: auto;
}

.Valider_simpleScreen .elApercu.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elApercu {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

@media (min-width: 568px) {
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

}
@media (min-width: 768px) {
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}
@media (min-width: 1024px) {
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .Valider_simpleScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .ChargeDuCamionScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ChargeDuCamionScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .ChargeDuCamionScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ChargeDuCamionScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ChargeDuCamionScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ChargeDuCamionScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ChargeDuCamionScreen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(9.4% + -10px);
    margin-right: 7.8%;
    width: calc(82.8% - -10px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 120px;
  }
  
  .flowRow_ChargeDuCamionScreen_elCard_727190444 { 
  }
  
@media (min-width: 568px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(27.1% + -10px);
    margin-right: 26.2%;
    width: calc(46.7% - -10px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 120px;
  }
  
  .flowRow_ChargeDuCamionScreen_elCard_727190444 { 
  }
  
}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(33.1% + -10px);
    margin-right: 32.4%;
    width: calc(34.5% - -10px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 119px;
  }
  
  .flowRow_ChargeDuCamionScreen_elCard_727190444 { 
  }
  
}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(37.3% + -10px);
    margin-right: 36.8%;
    width: calc(25.9% - -10px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 119px;
  }
  
  .flowRow_ChargeDuCamionScreen_elCard_727190444 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elId_scanCopy {
    position: absolute;
    top: 15px;
    width: 100%;
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elId_scanCopy > * { 
  margin-left: auto;
  width: 70px;
  margin-right: 8.8%;
  }
  
@media (min-width: 568px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elId_scanCopy {
    position: absolute;
    top: 15px;
    width: 100%;
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elId_scanCopy > * { 
  margin-left: auto;
  width: 70px;
  margin-right: 26.8%;
  }
  
}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elId_scanCopy {
    position: absolute;
    top: 15px;
    width: 100%;
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elId_scanCopy > * { 
  margin-left: auto;
  width: 70px;
  margin-right: 32.8%;
  }
  
}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elId_scanCopy {
    position: absolute;
    top: 15px;
    width: 100%;
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elId_scanCopy > * { 
  margin-left: auto;
  width: 70px;
  margin-right: 37.1%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elId_scan {
    position: absolute;
    top: 20px;
    margin-left: 9.4%;
    margin-right: 25.0%;
    width: calc(65.6% - 0px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elId_scan > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elId_scan {
    position: absolute;
    top: 20px;
    margin-left: 27.1%;
    margin-right: 35.9%;
    width: calc(37.0% - 0px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elId_scan > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elId_scan {
    position: absolute;
    top: 20px;
    margin-left: 33.1%;
    margin-right: 39.5%;
    width: calc(27.4% - 0px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elId_scan > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elId_scan {
    position: absolute;
    top: 20px;
    margin-left: 37.3%;
    margin-right: 42.2%;
    width: calc(20.5% - 0px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elId_scan > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elType {
    position: absolute;
    top: 45px;
    margin-left: 9.4%;
    margin-right: 25.0%;
    width: calc(65.6% - 0px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elType > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elType {
    position: absolute;
    top: 45px;
    margin-left: 27.1%;
    margin-right: 35.9%;
    width: calc(37.0% - 0px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elType > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elType {
    position: absolute;
    top: 44px;
    margin-left: 33.1%;
    margin-right: 39.5%;
    width: calc(27.4% - 0px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elType > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elType {
    position: absolute;
    top: 44px;
    margin-left: 37.3%;
    margin-right: 42.2%;
    width: calc(20.5% - 0px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elType > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elDescription {
    position: absolute;
    top: 70px;
    margin-left: 9.4%;
    margin-right: 25.0%;
    width: calc(65.6% - 0px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elDescription > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elDescription {
    position: absolute;
    top: 70px;
    margin-left: 27.1%;
    margin-right: 35.9%;
    width: calc(37.0% - 0px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elDescription > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elDescription {
    position: absolute;
    top: 70px;
    margin-left: 33.1%;
    margin-right: 39.5%;
    width: calc(27.4% - 0px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elDescription > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elDescription {
    position: absolute;
    top: 70px;
    margin-left: 37.3%;
    margin-right: 42.2%;
    width: calc(20.5% - 0px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elDescription > * { 
    width: 100%;
  }
  
}

.ChargeDuCamionScreen .elImage_fill_full {
  overflow: hidden;
}

.ChargeDuCamionScreen .elImage_fill_full.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elImage_fill_full {
    position: absolute;
    top: 20px;
    width: 100px;
    margin-left: calc(90.6% + -100px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elImage_fill_full > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

@media (min-width: 568px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elImage_fill_full {
    position: absolute;
    top: 20px;
    width: 100px;
    margin-left: calc(72.9% + -100px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elImage_fill_full > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elImage_fill_full {
    position: absolute;
    top: 20px;
    width: 100px;
    margin-left: calc(66.9% + -99px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elImage_fill_full > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elImage_fill_full {
    position: absolute;
    top: 20px;
    width: 100px;
    margin-left: calc(62.7% + -99px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elImage_fill_full > * { 
  width: 100px;
    padding-top: calc(100.0% - 30px);
  }
  
  .ChargeDuCamionScreen > .layoutFlow > .flowRow > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}


/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ChargeDuCamionScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 130px;
  }
  
@media (min-width: 568px) {
  .ChargeDuCamionScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ChargeDuCamionScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 130px;
  }
  
}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ChargeDuCamionScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 130px;
  }
  
}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .ChargeDuCamionScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 130px;
  }
  
}

.ChargeDuCamionScreen .elDrop button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.ChargeDuCamionScreen .elDrop button:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .layoutFlow > .elDrop {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 170px;
    margin-left: calc(50.0% - 85px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .elDrop > * { 
  width: 170px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .ChargeDuCamionScreen > .layoutFlow > .elDrop {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 170px;
    margin-left: calc(50.0% - 85px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .elDrop > * { 
  width: 170px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .layoutFlow > .elDrop {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 169px;
    margin-left: calc(50.0% - 84px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .elDrop > * { 
  width: 169px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .layoutFlow > .elDrop {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 169px;
    margin-left: calc(50.0% - 84px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .elDrop > * { 
  width: 169px;
  height: 35px;
  }
  
}

.ChargeDuCamionScreen .elAnomalie button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.ChargeDuCamionScreen .elAnomalie button:focus {
  box-shadow: 0 0 5px rgba(255, 211, 120, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .layoutFlow > .elAnomalie {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 170px;
    margin-left: calc(50.0% - 85px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .elAnomalie > * { 
  width: 170px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .ChargeDuCamionScreen > .layoutFlow > .elAnomalie {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 170px;
    margin-left: calc(50.0% - 85px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .elAnomalie > * { 
  width: 170px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .layoutFlow > .elAnomalie {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 169px;
    margin-left: calc(50.0% - 84px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .elAnomalie > * { 
  width: 169px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .layoutFlow > .elAnomalie {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 169px;
    margin-left: calc(50.0% - 84px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .elAnomalie > * { 
  width: 169px;
  height: 35px;
  }
  
}

.ChargeDuCamionScreen .elScanner button {
  cursor: pointer;
  border: 0.9px solid #00e5d9;
  border-radius: 3.4px;
}

.ChargeDuCamionScreen .elScanner button:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .layoutFlow > .elScanner {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 170px;
    margin-left: calc(50.0% - 85px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .elScanner > * { 
  width: 170px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .ChargeDuCamionScreen > .layoutFlow > .elScanner {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 170px;
    margin-left: calc(50.0% - 85px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .elScanner > * { 
  width: 170px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .layoutFlow > .elScanner {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 169px;
    margin-left: calc(50.0% - 84px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .elScanner > * { 
  width: 169px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .layoutFlow > .elScanner {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 169px;
    margin-left: calc(50.0% - 84px);
  }
  .ChargeDuCamionScreen > .layoutFlow > .elScanner > * { 
  width: 169px;
  height: 35px;
  }
  
}

.ChargeDuCamionScreen .elShow_apercu {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ChargeDuCamionScreen .elShow_apercu:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elShow_apercu {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    position: fixed;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
@media (min-width: 568px) {
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}

.ChargeDuCamionScreen .elApercu {
  overflow: hidden;
  pointer-events: auto;
}

.ChargeDuCamionScreen .elApercu.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elApercu {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

@media (min-width: 568px) {
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}

.ChargeDuCamionScreen .elMenu {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.ChargeDuCamionScreen .elMenu:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elMenu {
    position: absolute;
    left: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }
@media (min-width: 568px) {
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elMenu {
    left: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 768px) {
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elMenu {
    left: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 1024px) {
  .ChargeDuCamionScreen > .screenFgContainer > .foreground > .elMenu {
    left: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .DébutDeTournéeScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .DébutDeTournéeScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .DébutDeTournéeScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .DébutDeTournéeScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(9.4% + -10px);
    margin-right: 7.8%;
    width: calc(82.8% - -10px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 340px;
  }
  
  .flowRow_DébutDeTournéeScreen_elCard_2008976711 { 
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(27.1% + -10px);
    margin-right: 26.2%;
    width: calc(46.7% - -10px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 340px;
  }
  
  .flowRow_DébutDeTournéeScreen_elCard_2008976711 { 
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(33.1% + -10px);
    margin-right: 32.4%;
    width: calc(34.5% - -10px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 338px;
  }
  
  .flowRow_DébutDeTournéeScreen_elCard_2008976711 { 
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(37.3% + -10px);
    margin-right: 36.8%;
    width: calc(25.9% - -10px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 338px;
  }
  
  .flowRow_DébutDeTournéeScreen_elCard_2008976711 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elName_txt {
    overflow: hidden;
    position: absolute;
    top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elName_txt > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elName_txt {
    overflow: hidden;
    position: absolute;
    top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elName_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elName_txt {
    overflow: hidden;
    position: absolute;
    top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elName_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elName_txt {
    overflow: hidden;
    position: absolute;
    top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elName_txt > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elName {
    overflow: hidden;
    position: absolute;
    top: 45px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elName > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elName {
    overflow: hidden;
    position: absolute;
    top: 45px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elName > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elName {
    overflow: hidden;
    position: absolute;
    top: 45px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elName > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elName {
    overflow: hidden;
    position: absolute;
    top: 44px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elName > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elTruck_txt {
    overflow: hidden;
    position: absolute;
    top: 85px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elTruck_txt > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elTruck_txt {
    overflow: hidden;
    position: absolute;
    top: 85px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elTruck_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elTruck_txt {
    overflow: hidden;
    position: absolute;
    top: 85px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elTruck_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elTruck_txt {
    overflow: hidden;
    position: absolute;
    top: 84px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elTruck_txt > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elTruck {
    overflow: hidden;
    position: absolute;
    top: 110px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elTruck > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elTruck {
    overflow: hidden;
    position: absolute;
    top: 110px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elTruck > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elTruck {
    overflow: hidden;
    position: absolute;
    top: 110px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elTruck > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elTruck {
    overflow: hidden;
    position: absolute;
    top: 110px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elTruck > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elSite {
    overflow: hidden;
    position: absolute;
    top: 150px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elSite > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elSite {
    overflow: hidden;
    position: absolute;
    top: 150px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elSite > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elSite {
    overflow: hidden;
    position: absolute;
    top: 149px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elSite > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elSite {
    overflow: hidden;
    position: absolute;
    top: 149px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elSite > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elPicker_site {
    position: absolute;
    top: 170px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elPicker_site > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elPicker_site {
    position: absolute;
    top: 170px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elPicker_site > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elPicker_site {
    position: absolute;
    top: 169px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elPicker_site > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elPicker_site {
    position: absolute;
    top: 169px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elPicker_site > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elKm_txt {
    overflow: hidden;
    position: absolute;
    top: 215px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elKm_txt > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elKm_txt {
    overflow: hidden;
    position: absolute;
    top: 215px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elKm_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elKm_txt {
    overflow: hidden;
    position: absolute;
    top: 214px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elKm_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elKm_txt {
    overflow: hidden;
    position: absolute;
    top: 213px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elKm_txt > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elKm {
    position: absolute;
    top: 235px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elKm > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elKm {
    position: absolute;
    top: 235px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elKm > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elKm {
    position: absolute;
    top: 234px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elKm > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elKm {
    position: absolute;
    top: 233px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elKm > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elNote_txt {
    overflow: hidden;
    position: absolute;
    top: 280px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elNote_txt > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elNote_txt {
    overflow: hidden;
    position: absolute;
    top: 280px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elNote_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elNote_txt {
    overflow: hidden;
    position: absolute;
    top: 278px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elNote_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elNote_txt {
    overflow: hidden;
    position: absolute;
    top: 278px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elNote_txt > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elNote {
    position: absolute;
    top: 300px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elNote > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elNote {
    position: absolute;
    top: 300px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elNote > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elNote {
    position: absolute;
    top: 298px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elNote > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elNote {
    position: absolute;
    top: 298px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .flowRow > .elNote > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .DébutDeTournéeScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 350px;
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .DébutDeTournéeScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 350px;
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .DébutDeTournéeScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 350px;
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .DébutDeTournéeScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 350px;
  }
  
}

.DébutDeTournéeScreen .elStart button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.DébutDeTournéeScreen .elStart button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .elStart {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .elStart > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .elStart {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .elStart > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .elStart {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .elStart > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .elStart {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .DébutDeTournéeScreen > .layoutFlow > .elStart > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .DébutDeTournéeScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 20px;
  }
  
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .DébutDeTournéeScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 20px;
  }
  
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .DébutDeTournéeScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 20px;
  }
  
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .DébutDeTournéeScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 20px;
  }
  
}

.DébutDeTournéeScreen .elShow_apercu {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.DébutDeTournéeScreen .elShow_apercu:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .screenFgContainer > .foreground > .elShow_apercu {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    position: fixed;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
@media (min-width: 568px) {
  .DébutDeTournéeScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}

.DébutDeTournéeScreen .elApercu {
  overflow: hidden;
  pointer-events: auto;
}

.DébutDeTournéeScreen .elApercu.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .DébutDeTournéeScreen > .screenFgContainer > .foreground > .elApercu {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DébutDeTournéeScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

@media (min-width: 568px) {
  .DébutDeTournéeScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DébutDeTournéeScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

}
@media (min-width: 768px) {
  .DébutDeTournéeScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DébutDeTournéeScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}
@media (min-width: 1024px) {
  .DébutDeTournéeScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DébutDeTournéeScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}


/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .FinDeTournéeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .FinDeTournéeScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .FinDeTournéeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .FinDeTournéeScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .FinDeTournéeScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .FinDeTournéeScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .FinDeTournéeScreen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .layoutFlow > .elTitle {
    position: relative;
    margin-top: 10px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
@media (min-width: 568px) {
  .FinDeTournéeScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(9.4% + -10px);
    margin-right: 7.8%;
    width: calc(82.8% - -10px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 280px;
  }
  
  .flowRow_FinDeTournéeScreen_elCard_1340743110 { 
  }
  
@media (min-width: 568px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(27.1% + -10px);
    margin-right: 26.2%;
    width: calc(46.7% - -10px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 280px;
  }
  
  .flowRow_FinDeTournéeScreen_elCard_1340743110 { 
  }
  
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(33.1% + -10px);
    margin-right: 32.4%;
    width: calc(34.5% - -10px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 278px;
  }
  
  .flowRow_FinDeTournéeScreen_elCard_1340743110 { 
  }
  
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elCard {
    position: absolute;
    margin-top: 10px;
    margin-left: calc(37.3% + -10px);
    margin-right: 36.8%;
    width: calc(25.9% - -10px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elCard > * { 
    width: 100%;
  height: 278px;
  }
  
  .flowRow_FinDeTournéeScreen_elCard_1340743110 { 
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elSite {
    overflow: hidden;
    position: absolute;
    top: 20px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elSite > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elSite {
    overflow: hidden;
    position: absolute;
    top: 20px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elSite > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elSite {
    overflow: hidden;
    position: absolute;
    top: 20px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elSite > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elSite {
    overflow: hidden;
    position: absolute;
    top: 20px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elSite > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elPicker_site {
    position: absolute;
    top: 40px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elPicker_site > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elPicker_site {
    position: absolute;
    top: 40px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elPicker_site > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elPicker_site {
    position: absolute;
    top: 40px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elPicker_site > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elPicker_site {
    position: absolute;
    top: 40px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elPicker_site > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elKm_txt {
    overflow: hidden;
    position: absolute;
    top: 80px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elKm_txt > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elKm_txt {
    overflow: hidden;
    position: absolute;
    top: 80px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elKm_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elKm_txt {
    overflow: hidden;
    position: absolute;
    top: 80px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elKm_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elKm_txt {
    overflow: hidden;
    position: absolute;
    top: 80px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elKm_txt > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elKm {
    position: absolute;
    top: 100px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elKm > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elKm {
    position: absolute;
    top: 100px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elKm > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elKm {
    position: absolute;
    top: 100px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elKm > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elKm {
    position: absolute;
    top: 100px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elKm > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elFuel_txt {
    overflow: hidden;
    position: absolute;
    top: 150px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elFuel_txt > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elFuel_txt {
    overflow: hidden;
    position: absolute;
    top: 150px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elFuel_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elFuel_txt {
    overflow: hidden;
    position: absolute;
    top: 149px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elFuel_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elFuel_txt {
    overflow: hidden;
    position: absolute;
    top: 149px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elFuel_txt > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elFuel {
    position: absolute;
    top: 170px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elFuel > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elFuel {
    position: absolute;
    top: 170px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elFuel > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elFuel {
    position: absolute;
    top: 169px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elFuel > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elFuel {
    position: absolute;
    top: 169px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elFuel > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elNote_txt {
    overflow: hidden;
    position: absolute;
    top: 220px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elNote_txt > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elNote_txt {
    overflow: hidden;
    position: absolute;
    top: 220px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elNote_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elNote_txt {
    overflow: hidden;
    position: absolute;
    top: 218px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elNote_txt > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elNote_txt {
    overflow: hidden;
    position: absolute;
    top: 218px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elNote_txt > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elNote {
    position: absolute;
    top: 240px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elNote > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elNote {
    position: absolute;
    top: 240px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elNote > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elNote {
    position: absolute;
    top: 238px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elNote > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elNote {
    position: absolute;
    top: 238px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .FinDeTournéeScreen > .layoutFlow > .flowRow > .elNote > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .FinDeTournéeScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 290px;
  }
  
@media (min-width: 568px) {
  .FinDeTournéeScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .FinDeTournéeScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 290px;
  }
  
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .FinDeTournéeScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 290px;
  }
  
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .FinDeTournéeScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 290px;
  }
  
}

.FinDeTournéeScreen .elStart button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.FinDeTournéeScreen .elStart button:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .layoutFlow > .elStart {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .FinDeTournéeScreen > .layoutFlow > .elStart > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .FinDeTournéeScreen > .layoutFlow > .elStart {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% - 72px);
  }
  .FinDeTournéeScreen > .layoutFlow > .elStart > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .layoutFlow > .elStart {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .FinDeTournéeScreen > .layoutFlow > .elStart > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .layoutFlow > .elStart {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% - 72px);
  }
  .FinDeTournéeScreen > .layoutFlow > .elStart > * { 
  width: 144px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .FinDeTournéeScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 20px;
  }
  
@media (min-width: 568px) {
  .FinDeTournéeScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .FinDeTournéeScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 20px;
  }
  
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .FinDeTournéeScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 20px;
  }
  
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .FinDeTournéeScreen > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 20px;
  }
  
}

.FinDeTournéeScreen .elDone_fin {
  overflow: hidden;
}

.FinDeTournéeScreen .elDone_fin.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elDone_fin {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elDone_fin > .background > .containerMinHeight {
    min-height: 182px;
  }

@media (min-width: 568px) {
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elDone_fin {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -50.0px);
  }
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elDone_fin > .background > .containerMinHeight {
    min-height: 182px;
  }

}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elDone_fin {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elDone_fin > .background > .containerMinHeight {
    min-height: 181px;
  }

}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elDone_fin {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: calc(100% + 0.0% + -49.7px);
  }
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elDone_fin > .background > .containerMinHeight {
    min-height: 181px;
  }

}

.FinDeTournéeScreen .elShow_apercu {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.FinDeTournéeScreen .elShow_apercu:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elShow_apercu {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}

.FinDeTournéeScreen .elApercu {
  overflow: hidden;
  pointer-events: auto;
}

.FinDeTournéeScreen .elApercu.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elApercu {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

@media (min-width: 568px) {
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

}
@media (min-width: 768px) {
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}
@media (min-width: 1024px) {
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .FinDeTournéeScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}


/* Narrowest size for this element's responsive layout */
  .DepotV4Screen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .DepotV4Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .DepotV4Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .DepotV4Screen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}

.DepotV4Screen .elList {
  overflow: auto;
  pointer-events: auto;
}

.DepotV4Screen > .screenFgContainer > .foreground > .elList > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 5px;
  grid-column-gap: 5px;  /* old name still required by some browsers */
}

.DepotV4Screen > .screenFgContainer > .foreground > .elList > div > * {
  display: inline-block;
  position: relative;
  min-width: 159px;
}

  @media (min-width: 1024px) {
    .DepotV4Screen > .screenFgContainer > .foreground > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .DepotV4Screen > .screenFgContainer > .foreground > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .DepotV4Screen > .screenFgContainer > .foreground > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .DepotV4Screen > .screenFgContainer > .foreground > .elList > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .DepotV4Screen > .screenFgContainer > .foreground > .elList {
    position: absolute;
    left: calc(50.0% + 0px - 165px);
    top: 210px;
    width: 330px;
    height: calc(100% + 0.0% + -265.0px);
  }
@media (min-width: 568px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elList {
    left: calc(50.0% + 0px - 165px);
    top: 210px;
    width: 330px;
    height: calc(100% + 0.0% + -265.0px);
  }
}
@media (min-width: 768px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elList {
    left: calc(50.0% + 0px - 164px);
    top: 209px;
    width: 328px;
    height: calc(100% + 0.0% + -263.4px);
  }
}
@media (min-width: 1024px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elList {
    left: calc(50.0% + 0px - 164px);
    top: 209px;
    width: 328px;
    height: calc(100% + 0.0% + -263.4px);
  }
}

.DepotV4Screen .elValider {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.DepotV4Screen .elValider:focus {
  box-shadow: 0 0 5px rgba(0, 223, 208, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DepotV4Screen > .screenFgContainer > .foreground > .elValider {
    line-height: 35px;
    position: absolute;
    left: calc(50.0% + 0px - 72px);
    top: 210px;
    width: 145px;
    height: 35px;
  }
@media (min-width: 568px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elValider {
    line-height: 35px;
    left: calc(50.0% + 0px - 72px);
    top: 210px;
    width: 145px;
    height: 35px;
  }
}
@media (min-width: 768px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elValider {
    line-height: 35px;
    left: calc(50.0% + 0px - 72px);
    top: 209px;
    width: 144px;
    height: 35px;
  }
}
@media (min-width: 1024px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elValider {
    line-height: 35px;
    left: calc(50.0% + 0px - 72px);
    top: 209px;
    width: 144px;
    height: 35px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV4Screen > .screenFgContainer > .foreground > .elCheckbox_quai {
    position: absolute;
    right: calc(50.0% + 0px);
    top: 170px;
    width: 80px;
    height: 27px;
  }
@media (min-width: 568px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elCheckbox_quai {
    right: calc(50.0% + 0px);
    top: 170px;
    width: 80px;
    height: 27px;
  }
}
@media (min-width: 768px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elCheckbox_quai {
    right: calc(50.0% + 0px);
    top: 169px;
    width: 80px;
    height: 27px;
  }
}
@media (min-width: 1024px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elCheckbox_quai {
    right: calc(50.0% + 0px);
    top: 169px;
    width: 80px;
    height: 27px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV4Screen > .screenFgContainer > .foreground > .elCheckbox_hors_quai {
    position: absolute;
    left: calc(50.0% + 0px);
    top: 175px;
    width: 100px;
    height: 27px;
  }
@media (min-width: 568px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elCheckbox_hors_quai {
    left: calc(50.0% + 0px);
    top: 175px;
    width: 100px;
    height: 27px;
  }
}
@media (min-width: 768px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elCheckbox_hors_quai {
    left: calc(50.0% + 0px);
    top: 174px;
    width: 99px;
    height: 27px;
  }
}
@media (min-width: 1024px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elCheckbox_hors_quai {
    left: calc(50.0% + 0px);
    top: 174px;
    width: 99px;
    height: 27px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV4Screen > .screenFgContainer > .foreground > .elPicker_producer {
    position: absolute;
    left: calc(9.4% + 0px);
    right: calc(9.4% + 0px);
    top: 130px;
    width: 81.2%;
    height: 30px;
  }
@media (min-width: 568px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elPicker_producer {
    left: calc(27.1% + 0px);
    right: calc(27.1% + 0px);
    top: 130px;
    width: 45.8%;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elPicker_producer {
    left: calc(33.1% + 0px);
    right: calc(33.1% + 0px);
    top: 129px;
    width: 33.9%;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elPicker_producer {
    left: calc(37.3% + 0px);
    right: calc(37.3% + 0px);
    top: 129px;
    width: 25.4%;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV4Screen > .screenFgContainer > .foreground > .elPicker_dech {
    position: absolute;
    left: calc(9.4% + 0px);
    right: calc(9.4% + 0px);
    top: 130px;
    width: 81.2%;
    height: 30px;
  }
@media (min-width: 568px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elPicker_dech {
    left: calc(27.1% + 0px);
    right: calc(27.1% + 0px);
    top: 130px;
    width: 45.8%;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elPicker_dech {
    left: calc(33.1% + 0px);
    right: calc(33.1% + 0px);
    top: 129px;
    width: 33.9%;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elPicker_dech {
    left: calc(37.3% + 0px);
    right: calc(37.3% + 0px);
    top: 129px;
    width: 25.4%;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV4Screen > .screenFgContainer > .foreground > .elCheckbox_dech {
    position: absolute;
    right: calc(50.0% + 0px);
    top: 100px;
    width: 120px;
    height: 27px;
  }
@media (min-width: 568px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elCheckbox_dech {
    right: calc(50.0% + 0px);
    top: 100px;
    width: 120px;
    height: 27px;
  }
}
@media (min-width: 768px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elCheckbox_dech {
    right: calc(50.0% + 0px);
    top: 99px;
    width: 119px;
    height: 27px;
  }
}
@media (min-width: 1024px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elCheckbox_dech {
    right: calc(50.0% + 0px);
    top: 99px;
    width: 119px;
    height: 27px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV4Screen > .screenFgContainer > .foreground > .elCheckbox_autres {
    position: absolute;
    left: calc(50.0% + 0px);
    top: 105px;
    width: 120px;
    height: 27px;
  }
@media (min-width: 568px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elCheckbox_autres {
    left: calc(50.0% + 0px);
    top: 105px;
    width: 120px;
    height: 27px;
  }
}
@media (min-width: 768px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elCheckbox_autres {
    left: calc(50.0% + 0px);
    top: 104px;
    width: 119px;
    height: 27px;
  }
}
@media (min-width: 1024px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elCheckbox_autres {
    left: calc(50.0% + 0px);
    top: 104px;
    width: 119px;
    height: 27px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV4Screen > .screenFgContainer > .foreground > .elText {
    position: absolute;
    left: calc(9.4% + 0px);
    right: calc(9.4% + 0px);
    top: 60px;
    width: 81.2%;
    height: 30px;
  }
@media (min-width: 568px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elText {
    left: calc(27.1% + 0px);
    right: calc(27.1% + 0px);
    top: 60px;
    width: 45.8%;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elText {
    left: calc(33.1% + 0px);
    right: calc(33.1% + 0px);
    top: 60px;
    width: 33.9%;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elText {
    left: calc(37.3% + 0px);
    right: calc(37.3% + 0px);
    top: 60px;
    width: 25.4%;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .DepotV4Screen > .screenFgContainer > .foreground > .elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 92px;
    width: 100.0%;
    height: 1px;
  }
@media (min-width: 568px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 92px;
    width: 100.0%;
    height: 1px;
  }
}
@media (min-width: 768px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 91px;
    width: 100.0%;
    height: 1px;
  }
}
@media (min-width: 1024px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 91px;
    width: 100.0%;
    height: 1px;
  }
}

.DepotV4Screen .elShow_apercu {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.DepotV4Screen .elShow_apercu:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .DepotV4Screen > .screenFgContainer > .foreground > .elShow_apercu {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}

.DepotV4Screen .elApercu {
  overflow: hidden;
  pointer-events: auto;
}

.DepotV4Screen .elApercu.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .DepotV4Screen > .screenFgContainer > .foreground > .elApercu {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DepotV4Screen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

@media (min-width: 568px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DepotV4Screen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

}
@media (min-width: 768px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DepotV4Screen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}
@media (min-width: 1024px) {
  .DepotV4Screen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .DepotV4Screen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}

/* This component has a fixed-size layout */
  .Picker_depot3 {
    min-height: 159px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 159px */
    height: 100%;
  }
  .Picker_depot3 > .background > .containerMinHeight {
    min-height: 159px;
  }


  .Picker_depot3 > .background > .elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Picker_depot3 > .layoutFlow > .flowRow > .elVide {
    position: absolute;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Picker_depot3 > .layoutFlow > .flowRow > .elVide > * { 
    width: 100%;
  height: 0px;
  }
  
  .flowRow_Picker_depot3_elVide_1000640606 { 
  }
  


  .Picker_depot3 > .foreground > .elFlux {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 134px;
    width: 100.0%;
    height: 20px;
  }


  .Picker_depot3 > .foreground > .elState {
    position: absolute;
    left: calc(50.0% + 0px - 30px);
    top: 110px;
    width: 60px;
    height: 20px;
  }

.Picker_depot3 .elImage_fill {
  overflow: hidden;
}

.Picker_depot3 .elImage_fill.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Picker_depot3 > .foreground > .elImage_fill {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 110px;
  }


  .Picker_depot3 > .foreground > .elId_scan {
    position: absolute;
    left: calc(50.0% + 5px);
    right: 5px;
    top: 5px;
    width: calc(50.0% - 5px - 5px);
    height: 20px;
  }


  .Picker_depot3 > .foreground > .elQuai {
    position: absolute;
    left: 5px;
    right: calc(50.0% + 5px);
    top: 5px;
    width: calc(50.0% - 5px - 5px);
    height: 30px;
  }

/* This component has a fixed-size layout */
  .Anomalies_explorer2 {
    min-height: 250px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 200px */
    height: 100%;
  }
  .Anomalies_explorer2 > .background > .containerMinHeight {
    min-height: 250px;
  }

.Anomalies_explorer2 .elAdd {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Anomalies_explorer2 .elAdd:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



  .Anomalies_explorer2 > .foreground > .elAdd {
    line-height: 35px;
    position: absolute;
    left: calc(50.0% + 0px - 72px);
    bottom: 20px;
    width: 145px;
    height: 35px;
  }

.Anomalies_explorer2 .elDeclared_anomalie {
  overflow: hidden;
}

.Anomalies_explorer2 .elDeclared_anomalie.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Anomalies_explorer2 > .foreground > .elDeclared_anomalie {
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 70px;
    width: calc(100.0% - 10px - 10px);
    height: 45px;
  }

.Anomalies_explorer2 .elList {
  overflow: auto;
  pointer-events: auto;
}



  .Anomalies_explorer2 > .foreground > .elList {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 50px;
    width: calc(100.0% - 10px - 10px);
    height: calc(100% + 0.0% + -180.0px);
  }


  .Anomalies_explorer2 > .foreground > .elText {
    overflow: hidden;
    position: absolute;
    left: 35px;
    right: 35px;
    top: 0px;
    width: calc(100.0% - 35px - 35px);
    height: 48px;
  }

.Anomalies_explorer2 .elBack {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Anomalies_explorer2 .elBack:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .Anomalies_explorer2 > .foreground > .elBack {
    position: absolute;
    left: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }

.Anomalies_explorer2 .elRm {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Anomalies_explorer2 .elRm:focus {
  box-shadow: 0 0 5px rgba(255, 129, 120, 0.5000);
  outline: none;
}



  .Anomalies_explorer2 > .foreground > .elRm {
    position: absolute;
    right: 10px;
    top: 15px;
    width: 20px;
    height: 20px;
  }

/* This component has a fixed-size layout */
  .Anomalie_cat {
    width: 100%; /* This component was designed using a width of 182px */
    height: 100%;
  }


  .Anomalie_cat > .background > .elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 1px;
  }


  .Anomalie_cat > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Anomalie_cat > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  


  .Anomalie_cat > .layoutFlow > .elLabel {
    position: relative;
    margin-top: 1px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Anomalie_cat > .layoutFlow > .elLabel > * { 
    width: 100%;
  }
  


  .Anomalie_cat > .layoutFlow > .elCheckbox {
    position: relative;
    margin-top: 1px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Anomalie_cat > .layoutFlow > .elCheckbox > * { 
    width: 100%;
  }
  


  .Anomalie_cat > .layoutFlow > .elSpacerCopy {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Anomalie_cat > .layoutFlow > .elSpacerCopy > * { 
  width: 10px;
  height: 10px;
  }
  

/* This component has a fixed-size layout */
  .Declared_anomalie {
    width: 100%; /* This component was designed using a width of 182px */
    height: 100%;
  }


  .Declared_anomalie > .background > .state0_elRectangle_back519569028 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Declared_anomalie > .layoutFlow > .state0_elSpacer137789074 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Declared_anomalie > .layoutFlow > .state0_elSpacer137789074 > * { 
  width: 10px;
  height: 5px;
  }
  


  .Declared_anomalie > .layoutFlow > .state0_elLabel {
    position: relative;
    margin-top: 0px;
    margin-left: 35px;
    margin-right: 35px;
    width: calc(100.0% - 70px);
  }
  .Declared_anomalie > .layoutFlow > .state0_elLabel > * { 
    width: 100%;
  }
  


  .Declared_anomalie > .layoutFlow > .state0_elSpacerCopy914152825 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Declared_anomalie > .layoutFlow > .state0_elSpacerCopy914152825 > * { 
  width: 10px;
  height: 5px;
  }
  

.Declared_anomalie .state0_elWarning835963567 {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Declared_anomalie .state0_elWarning835963567:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .Declared_anomalie > .foreground > .state0_elWarning835963567 {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
  }

.Declared_anomalie .state0_elRm {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Declared_anomalie .state0_elRm:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .Declared_anomalie > .foreground > .state0_elRm {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
  }

/* This component has a fixed-size layout */
  .Declared_anomalie {
    width: 100%; /* This component was designed using a width of 182px */
    height: 100%;
  }


  .Declared_anomalie > .background > .state1_elRectangle_back519569028 {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }


  .Declared_anomalie > .layoutFlow > .state1_elSpacer137789074 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Declared_anomalie > .layoutFlow > .state1_elSpacer137789074 > * { 
  width: 10px;
  height: 5px;
  }
  


  .Declared_anomalie > .layoutFlow > .state1_elLabel {
    position: relative;
    margin-top: 0px;
    margin-left: 35px;
    margin-right: 5px;
    width: calc(100.0% - 40px);
  }
  .Declared_anomalie > .layoutFlow > .state1_elLabel > * { 
    width: 100%;
  }
  


  .Declared_anomalie > .layoutFlow > .state1_elSpacerCopy914152825 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Declared_anomalie > .layoutFlow > .state1_elSpacerCopy914152825 > * { 
  width: 10px;
  height: 5px;
  }
  

.Declared_anomalie .state1_elWarning835963567 {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Declared_anomalie .state1_elWarning835963567:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .Declared_anomalie > .foreground > .state1_elWarning835963567 {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
  }

/* This component has a fixed-size layout */
  .Comp_list_anomalies {
    width: 100%; /* This component was designed using a width of 261px */
    height: 100%;
  }

.Comp_list_anomalies .elList li {
  margin-bottom: 5px;
}



  .Comp_list_anomalies > .layoutFlow > .elList {
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .Comp_list_anomalies > .layoutFlow > .elList > * { 
    width: 100%;
  }
  

.Comp_list_anomalies .elAdd_anomalie button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Comp_list_anomalies .elAdd_anomalie button:focus {
  box-shadow: 0 0 6px rgba(0, 226, 217, 0.2500);
  outline: none;
}



  .Comp_list_anomalies > .layoutFlow > .elAdd_anomalie {
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .Comp_list_anomalies > .layoutFlow > .elAdd_anomalie > * { 
    width: 100%;
  height: 40px;
  }
  


  .Comp_list_anomalies > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Comp_list_anomalies > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  


/* Narrowest size for this element's responsive layout */
  .Vider_apres_rotationScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .Vider_apres_rotationScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .Vider_apres_rotationScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .Vider_apres_rotationScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .Vider_apres_rotationScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Vider_apres_rotationScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elText > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Vider_apres_rotationScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
@media (min-width: 568px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 768px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}
@media (min-width: 1024px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elRectangle {
    position: relative;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elRectangle > * { 
    width: 100%;
  height: 1px;
  }
  
}

.Vider_apres_rotationScreen .elImage_fill_full {
  overflow: hidden;
}

.Vider_apres_rotationScreen .elImage_fill_full.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .Vider_apres_rotationScreen > .layoutFlow > .elImage_fill_full {
    position: relative;
    margin-top: 15px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elImage_fill_full > * { 
  width: 120px;
    padding-top: calc(100.0% - 30px);
  }
  
  .Vider_apres_rotationScreen > .layoutFlow > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

@media (min-width: 568px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elImage_fill_full {
    position: relative;
    margin-top: 15px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elImage_fill_full > * { 
  width: 120px;
    padding-top: calc(100.0% - 30px);
  }
  
  .Vider_apres_rotationScreen > .layoutFlow > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 768px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elImage_fill_full {
    position: relative;
    margin-top: 15px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elImage_fill_full > * { 
  width: 120px;
    padding-top: calc(100.0% - 30px);
  }
  
  .Vider_apres_rotationScreen > .layoutFlow > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}
@media (min-width: 1024px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elImage_fill_full {
    position: relative;
    margin-top: 15px;
    width: 120px;
    margin-left: calc(50.0% - 60px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elImage_fill_full > * { 
  width: 120px;
    padding-top: calc(100.0% - 30px);
  }
  
  .Vider_apres_rotationScreen > .layoutFlow > .elImage_fill_full > .background > .containerMinHeight {
    min-height: 30px;
  }

}

.Vider_apres_rotationScreen .elYes_here button {
  cursor: pointer;
  border: 0.9px solid rgba(0, 0, 0, 0.5000);
  border-radius: 3.0px;
}

.Vider_apres_rotationScreen .elYes_here button:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Vider_apres_rotationScreen > .layoutFlow > .elYes_here {
    position: relative;
    margin-top: 15px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elYes_here > * { 
    width: 100%;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elYes_here {
    position: relative;
    margin-top: 15px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elYes_here > * { 
    width: 100%;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elYes_here {
    position: relative;
    margin-top: 15px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elYes_here > * { 
    width: 100%;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elYes_here {
    position: relative;
    margin-top: 15px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elYes_here > * { 
    width: 100%;
  height: 35px;
  }
  
}

.Vider_apres_rotationScreen .elNo button {
  cursor: pointer;
  border: 0.9px solid rgba(0, 0, 0, 0.5000);
  border-radius: 3.0px;
}

.Vider_apres_rotationScreen .elNo button:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Vider_apres_rotationScreen > .layoutFlow > .elNo {
    position: relative;
    margin-top: 15px;
    margin-left: 9.4%;
    margin-right: 9.4%;
    width: calc(81.2% - 0px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elNo > * { 
    width: 100%;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elNo {
    position: relative;
    margin-top: 15px;
    margin-left: 27.1%;
    margin-right: 27.1%;
    width: calc(45.8% - 0px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elNo > * { 
    width: 100%;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elNo {
    position: relative;
    margin-top: 15px;
    margin-left: 33.1%;
    margin-right: 33.1%;
    width: calc(33.9% - 0px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elNo > * { 
    width: 100%;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .Vider_apres_rotationScreen > .layoutFlow > .elNo {
    position: relative;
    margin-top: 15px;
    margin-left: 37.3%;
    margin-right: 37.3%;
    width: calc(25.4% - 0px);
  }
  .Vider_apres_rotationScreen > .layoutFlow > .elNo > * { 
    width: 100%;
  height: 35px;
  }
  
}

/* This component has a fixed-size layout */
  .Comp_list_anomalies_simple {
    width: 100%; /* This component was designed using a width of 261px */
    height: 100%;
  }

.Comp_list_anomalies_simple .elList li {
  margin-bottom: 5px;
}



  .Comp_list_anomalies_simple > .layoutFlow > .elList {
    position: relative;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .Comp_list_anomalies_simple > .layoutFlow > .elList > * { 
    width: 100%;
  }
  

.Comp_list_anomalies_simple .elAdd_anomalie button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Comp_list_anomalies_simple .elAdd_anomalie button:focus {
  box-shadow: 0 0 6px rgba(0, 226, 217, 0.2500);
  outline: none;
}



  .Comp_list_anomalies_simple > .layoutFlow > .elAdd_anomalie {
    position: relative;
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .Comp_list_anomalies_simple > .layoutFlow > .elAdd_anomalie > * { 
    width: 100%;
  height: 40px;
  }
  


  .Comp_list_anomalies_simple > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Comp_list_anomalies_simple > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  


/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 130px;
  }
  
@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 130px;
  }
  
}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 130px;
  }
  
}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 130px;
  }
  
}

.Quai_view_dech_startScreen > .layoutFlow > .elList_quai > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 5px;
  grid-column-gap: 5px;  /* old name still required by some browsers */
}

.Quai_view_dech_startScreen > .layoutFlow > .elList_quai > div > * {
  display: inline-block;
  position: relative;
  min-width: 159px;
}

  @media (min-width: 1024px) {
    .Quai_view_dech_startScreen > .layoutFlow > .elList_quai > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .Quai_view_dech_startScreen > .layoutFlow > .elList_quai > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .Quai_view_dech_startScreen > .layoutFlow > .elList_quai > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .Quai_view_dech_startScreen > .layoutFlow > .elList_quai > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .layoutFlow > .elList_quai {
    position: relative;
    margin-top: 0px;
    width: 330px;
    margin-left: calc(50.0% - 165px);
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elList_quai > * { 
  width: 330px;
  }
  
@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elList_quai {
    position: relative;
    margin-top: 0px;
    width: 330px;
    margin-left: calc(50.0% - 165px);
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elList_quai > * { 
  width: 330px;
  }
  
}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elList_quai {
    position: relative;
    margin-top: 0px;
    width: 328px;
    margin-left: calc(50.0% - 164px);
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elList_quai > * { 
  width: 328px;
  }
  
}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elList_quai {
    position: relative;
    margin-top: 0px;
    width: 328px;
    margin-left: calc(50.0% - 164px);
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elList_quai > * { 
  width: 328px;
  }
  
}

.Quai_view_dech_startScreen > .layoutFlow > .elList_hors_quai > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 5px;
  grid-row-gap: 5px;  /* old name still required by some browsers */
  column-gap: 5px;
  grid-column-gap: 5px;  /* old name still required by some browsers */
}

.Quai_view_dech_startScreen > .layoutFlow > .elList_hors_quai > div > * {
  display: inline-block;
  position: relative;
  min-width: 159px;
}

  @media (min-width: 1024px) {
    .Quai_view_dech_startScreen > .layoutFlow > .elList_hors_quai > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .Quai_view_dech_startScreen > .layoutFlow > .elList_hors_quai > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (min-width: 568px) and (max-width: 767px) {
    .Quai_view_dech_startScreen > .layoutFlow > .elList_hors_quai > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  
  @media (max-width: 567px) {
    .Quai_view_dech_startScreen > .layoutFlow > .elList_hors_quai > div {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 5px;
      grid-row-gap: 5px;  /* old name still required by some browsers */
      column-gap: 5px;
      grid-column-gap: 5px;  /* old name still required by some browsers */
    }
  
  }
  


/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .layoutFlow > .elList_hors_quai {
    position: relative;
    margin-top: 0px;
    width: 330px;
    margin-left: calc(50.0% - 165px);
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elList_hors_quai > * { 
  width: 330px;
  }
  
@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elList_hors_quai {
    position: relative;
    margin-top: 0px;
    width: 330px;
    margin-left: calc(50.0% - 165px);
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elList_hors_quai > * { 
  width: 330px;
  }
  
}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elList_hors_quai {
    position: relative;
    margin-top: 0px;
    width: 328px;
    margin-left: calc(50.0% - 164px);
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elList_hors_quai > * { 
  width: 328px;
  }
  
}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elList_hors_quai {
    position: relative;
    margin-top: 0px;
    width: 328px;
    margin-left: calc(50.0% - 164px);
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elList_hors_quai > * { 
  width: 328px;
  }
  
}

.Quai_view_dech_startScreen .elNon button {
  cursor: pointer;
  border: 0.9px solid #00e5d9;
  border-radius: 3.0px;
}

.Quai_view_dech_startScreen .elNon button:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .layoutFlow > .elNon {
    position: relative;
    margin-top: 20px;
    width: 200px;
    margin-left: calc(50.0% - 100px);
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elNon > * { 
  width: 200px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elNon {
    position: relative;
    margin-top: 20px;
    width: 200px;
    margin-left: calc(50.0% - 100px);
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elNon > * { 
  width: 200px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elNon {
    position: relative;
    margin-top: 20px;
    width: 199px;
    margin-left: calc(50.0% - 99px);
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elNon > * { 
  width: 199px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elNon {
    position: relative;
    margin-top: 20px;
    width: 199px;
    margin-left: calc(50.0% - 99px);
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elNon > * { 
  width: 199px;
  height: 35px;
  }
  
}

.Quai_view_dech_startScreen .elShow_apercu_1 button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.0px;
}

.Quai_view_dech_startScreen .elShow_apercu_1 button:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .layoutFlow > .elShow_apercu_1 {
    position: relative;
    margin-top: 20px;
    width: 200px;
    margin-left: calc(50.0% - 100px);
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elShow_apercu_1 > * { 
  width: 200px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elShow_apercu_1 {
    position: relative;
    margin-top: 20px;
    width: 200px;
    margin-left: calc(50.0% - 100px);
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elShow_apercu_1 > * { 
  width: 200px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elShow_apercu_1 {
    position: relative;
    margin-top: 20px;
    width: 199px;
    margin-left: calc(50.0% - 99px);
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elShow_apercu_1 > * { 
  width: 199px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elShow_apercu_1 {
    position: relative;
    margin-top: 20px;
    width: 199px;
    margin-left: calc(50.0% - 99px);
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elShow_apercu_1 > * { 
  width: 199px;
  height: 35px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 20px;
  }
  
@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 20px;
  }
  
}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 20px;
  }
  
}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .layoutFlow > .elSpacer2 {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: 0px;
  }
  .Quai_view_dech_startScreen > .layoutFlow > .elSpacer2 > * { 
  width: 10px;
  height: 20px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elBackgroundCopy {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: 80px;
  }
@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elBackgroundCopy {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: 80px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elBackgroundCopy {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: 80px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elBackgroundCopy {
    left: 0px;
    right: 0px;
    top: 50px;
    width: 100.0%;
    height: 80px;
  }
}

.Quai_view_dech_startScreen .elHors_quai {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Quai_view_dech_startScreen .elHors_quai:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2125);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elHors_quai {
    position: absolute;
    left: calc(50.0% + 0px);
    top: 97px;
    width: 30px;
    height: 25px;
  }
@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elHors_quai {
    left: calc(50.0% + 0px);
    top: 97px;
    width: 30px;
    height: 25px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elHors_quai {
    left: calc(50.0% + 0px);
    top: 96px;
    width: 30px;
    height: 25px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elHors_quai {
    left: calc(50.0% + 0px);
    top: 96px;
    width: 30px;
    height: 25px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elCheckbox_hors_quai {
    position: absolute;
    left: calc(50.0% + -80px);
    top: 100px;
    width: 100px;
    height: 27px;
  }
@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elCheckbox_hors_quai {
    left: calc(50.0% + -80px);
    top: 100px;
    width: 100px;
    height: 27px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elCheckbox_hors_quai {
    left: calc(50.0% + -80px);
    top: 99px;
    width: 99px;
    height: 27px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elCheckbox_hors_quai {
    left: calc(50.0% + -80px);
    top: 99px;
    width: 99px;
    height: 27px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elCheckbox_quai {
    position: absolute;
    left: calc(50.0% + -165px);
    top: 105px;
    width: 80px;
    height: 27px;
  }
@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elCheckbox_quai {
    left: calc(50.0% + -165px);
    top: 105px;
    width: 80px;
    height: 27px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elCheckbox_quai {
    left: calc(50.0% + -164px);
    top: 104px;
    width: 80px;
    height: 27px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elCheckbox_quai {
    left: calc(50.0% + -164px);
    top: 104px;
    width: 80px;
    height: 27px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elRectangle {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 92px;
    width: 100.0%;
    height: 1px;
  }
@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 92px;
    width: 100.0%;
    height: 1px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 91px;
    width: 100.0%;
    height: 1px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elRectangle {
    left: 0px;
    right: 0px;
    top: 91px;
    width: 100.0%;
    height: 1px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elMode {
    overflow: hidden;
    position: absolute;
    left: calc(9.4% + 0px);
    right: calc(9.4% + 0px);
    top: 60px;
    width: 81.2%;
    height: 28px;
  }
@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elMode {
    overflow: hidden;
    left: calc(27.1% + 0px);
    right: calc(27.1% + 0px);
    top: 60px;
    width: 45.8%;
    height: 28px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elMode {
    overflow: hidden;
    left: calc(33.1% + 0px);
    right: calc(33.1% + 0px);
    top: 60px;
    width: 33.9%;
    height: 28px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elMode {
    overflow: hidden;
    left: calc(37.3% + 0px);
    right: calc(37.3% + 0px);
    top: 60px;
    width: 25.4%;
    height: 27px;
  }
}

.Quai_view_dech_startScreen .elShow_apercu {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Quai_view_dech_startScreen .elShow_apercu:focus {
  box-shadow: 0 0 6px rgba(254, 255, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elShow_apercu {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elShow_apercu {
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    position: fixed;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elNotif {
    overflow: hidden;
    right: 5px;
    top: 5px;
    width: 18px;
    height: 21px;
  }
}

.Quai_view_dech_startScreen .elApercu {
  overflow: hidden;
  pointer-events: auto;
}

.Quai_view_dech_startScreen .elApercu.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elApercu {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 300px;
  }

}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elApercu {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elApercu > .background > .containerMinHeight {
    min-height: 299px;
  }

}


/* Narrowest size for this element's responsive layout */
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elDate {
    overflow: hidden;
    position: absolute;
    left: calc(50.0% + 0px);
    top: 100px;
    width: 165px;
    height: 30px;
  }
@media (min-width: 568px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elDate {
    overflow: hidden;
    left: calc(50.0% + 0px);
    top: 100px;
    width: 165px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elDate {
    overflow: hidden;
    left: calc(50.0% + 0px);
    top: 99px;
    width: 164px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .Quai_view_dech_startScreen > .screenFgContainer > .foreground > .elDate {
    overflow: hidden;
    left: calc(50.0% + 0px);
    top: 99px;
    width: 164px;
    height: 30px;
  }
}

/* This component has a fixed-size layout */
  .Done_fin {
    min-height: 182px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 182px */
    height: 100%;
  }
  .Done_fin > .background > .containerMinHeight {
    min-height: 182px;
  }


  .Done_fin > .foreground > .elTs {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 120px;
    width: 100.0%;
    height: 33px;
  }

.Done_fin .elIconButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Done_fin .elIconButton:focus {
  box-shadow: 0 0 5px rgba(254, 255, 255, 0.5000);
  outline: none;
}



  .Done_fin > .foreground > .elIconButton {
    position: absolute;
    left: calc(50.0% + 0px - 20px);
    top: 70px;
    width: 41px;
    height: 36px;
  }


  .Done_fin > .foreground > .elTextCopy {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 30px;
    width: 100.0%;
    height: 43px;
  }

/* This component has a fixed-size layout */
  .Tail_picker_echange_start {
    min-height: 159px;  /* This component has a fixed size */
    overflow: hidden;
    min-width: 159px; /* This component has a fixed size */
  }
  .Tail_picker_echange_start > .background > .containerMinHeight {
    min-height: 159px;
  }

.Tail_picker_echange_start .elButtonCopy {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Tail_picker_echange_start .elButtonCopy:focus {
  box-shadow: 0 0 5px rgba(0, 226, 217, 0.5000);
  outline: none;
}



  .Tail_picker_echange_start > .foreground > .elButtonCopy {
    line-height: 35px;
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 5px;
    width: calc(100.0% - 5px - 5px);
    height: 35px;
  }


  .Tail_picker_echange_start > .foreground > .elMatricule {
    position: absolute;
    left: 85px;
    right: 5px;
    top: 85px;
    width: calc(100.0% - 85px - 5px);
    height: 20px;
  }

.Tail_picker_echange_start .elScanner {
  cursor: pointer;
  border: 0.9px solid #00e5d9;
}

.Tail_picker_echange_start .elScanner:focus {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2500);
  outline: none;
}



  .Tail_picker_echange_start > .foreground > .elScanner {
    position: absolute;
    left: 5px;
    top: 85px;
    width: 80px;
    height: 20px;
  }


  .Tail_picker_echange_start > .foreground > .elFlux {
    position: absolute;
    left: 5px;
    right: 5px;
    top: 7px;
    width: calc(100.0% - 5px - 5px);
    height: 20px;
  }

.Tail_picker_echange_start .elClose {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Tail_picker_echange_start .elClose:focus {
  box-shadow: 0 0 5px rgba(255, 129, 120, 0.5000);
  outline: none;
}



  .Tail_picker_echange_start > .foreground > .elClose {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 41px;
    height: 36px;
  }


  .Tail_picker_echange_start > .foreground > .elPicker_vide {
    position: absolute;
    left: 5px;
    right: 5px;
    top: 40px;
    width: calc(100.0% - 5px - 5px);
    height: 30px;
  }

.Tail_picker_echange_start .elAdd {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.Tail_picker_echange_start .elAdd:focus {
  box-shadow: 0 0 5px rgba(213, 213, 213, 0.5000);
  outline: none;
}



  .Tail_picker_echange_start > .foreground > .elAdd {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }

